import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import ItemList from 'app/components/itemList/itemList';
import CommonHeader from 'app/components/commonHeader/commonHeader';
import { ProjectSelectors, WorkspaceSelectors, WorkspaceActions, UsersActions, MainSelectors, DatastoreActions, DatastoreSelector, ItemDetailsSelectors, ItemsActions, ProjectActions, UsersSelectors, ItemsSelectors } from 'app/services/store';
import WorkspaceAndProjectPicker from '../workspaceAndProjectPicker/workspaceAndProjectPicker';
import styles from './home.module.css'
import SearchForm from '../search/search'
import SurveyForm from '../surveyForm/surveyForm'
import { Layout, Row, Col, Tabs, Icon,Menu, Dropdown , Button, Modal, Spin} from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import {Route, Redirect} from 'react-router-dom'
import itemActions from '../itemActions/itemActions';
import Members from '../settings/membersSettings/membersSettings'
import FileSaver from 'file-saver';
import Logger from 'app/utils/logger';

export enum PickerType {
    POPUP, // Show project / workspace selector inline
    HEADER // Show project / workspace selector in a popup
}

const manual = require("../../../assets/files/NGファインダー簡易操作マニュアル（Ver.007-T20）.pdf");

interface HomeProps extends WithTranslation {
    currentProjectId: string;
    currentWorkspaceId: string;
    currentPage: number;
    isLoading: boolean;
    currentDatastore: any;
    itemID: any;
    currentProject: any;
    itemDetailsLoading: any;
    userinfo: any;
    getWorkspacesRequest: Function;
    getCurrentUser: Function;
    setDatastore: Function;
    setCurrentItem: Function;
    setProject: Function;
    setWorkspace: Function;
    getItemsListRequest: Function;
    conditions: any;
}

const { Content } = Layout;
const { Header } = Layout;
const { TabPane } = Tabs;

class Home extends React.Component<HomeProps> {
    pickerType = PickerType.POPUP; //POPUP
    searchRef = React.createRef<typeof SearchForm>();
    state = {
        displaySelectorModal: false,
        formType: 'INDIVIDUAL',
        searchFormData: null,
        formData: null,
        activeKey: '1',
        category: 'list',
        currentPage: 1
    }

    handleChange = (formData) => {
        this.setState({
            formData: {
                ...formData
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('---------------RECEIVE PROPS---------------')
        // console.log(nextProps)
        // console.log(nextProps['match'].params)
        // console.log(this.props['match'].params)

        // let fromURL = sessionStorage.getItem('url')
        // if(fromURL && this.state.displaySelectorModal !== true){
        //    this.fromURL(fromURL)
        //    let spliArr = fromURL.split('/')
        //    spliArr.shift()

        //    this.setSettingsFromURL(spliArr)
        //    console.log('PROPS', this.props, spliArr)
        // }

    }

    componentWillUpdate(nextProps) {
        if(nextProps.currentProjectId && sessionStorage.getItem('url')){
            this.someFun()
        }
    }

    someFun() {
        // console.log('SOME FUN')
        const url = sessionStorage.getItem('url')
        const arr = url!.split('/')
        arr.shift()
        if(this.state.displaySelectorModal === true && this.props.currentProject){
            // console.log('SOME FUN HIDE')
            this.displaySelectorModalFunction()
        }
        if(this.props.currentProject){
            // console.log('SOME FUN SET DATESTOER')
            this.setDatastore()
        }
        if(this.props.currentDatastore && arr[3]){
            this.props.setCurrentItem({itemId: arr[3]})
        }
        if(this.props.currentDatastore){
            sessionStorage.clear()
        }

    }

    setSettingsFromURL(arr) {
        // console.log('-----arr', arr)
        // if(arr[0] && !this.props.currentWorkspaceId){this.props.setWorkspace({workspaceID: arr[0]})}
        if(arr[1] && !this.props.currentProjectId && this.props.currentWorkspaceId){this.props.setProject({projectId: arr[1]})}
        this.displaySelectorModalFunction()
        // if(arr[1]){this.props.setProject(arr[1])}
        // if(arr[2])this.props.setDatastore(arr[2])
        // if(arr)
        if(this.props.currentDatastore){
            sessionStorage.clear()
        }
    }

    setURLstate = (url) => {
        return <Redirect to={url} />
    }


    setURL(){

        // if(sessionStorage.getItem('url')){return}
        if(!this.props.currentDatastore){return}
        if(sessionStorage.getItem('url')){
            const url = sessionStorage.getItem('url')

            // sessionStorage.clear()
            // console.log("GOT AN ITEM URL JJJJJJJJJJ")
            // return <Redirect to={`${url}`} />
        }
        // console.log('SETURL', 'ITEMS LOADING',this.props.itemDetailsLoading)
        if(this.props.itemDetailsLoading && this.props.itemID){
            return <Redirect to={`/${this.props.currentWorkspaceId}/${this.props.currentProjectId}/${this.props.currentDatastore}/${this.props.itemID}`} />
        }
        if(this.props.itemID) {
            return <Redirect to={`/${this.props.currentWorkspaceId}/${this.props.currentProjectId}/${this.props.currentDatastore}/${this.props.itemID}`} />
        } else {
            return <Redirect to={`/${this.props.currentWorkspaceId}/${this.props.currentProjectId}/${this.props.currentDatastore}`} />
        }
    }

    componentDidMount(): void {
        // console.log('-------------DID MOUNT-----------------')
        // console.log(this.props)
        this.props.getWorkspacesRequest();
        this.props.getCurrentUser();
        this.setState({
            displaySelectorModal: (!this.props.currentWorkspaceId || !this.props.currentProjectId)
        })
        if(this.props['match'].params.item){
            this.props.setCurrentItem({itemId: this.props['match'].params.item})
            // console.log(this.props.itemID)
        }


    }

    displaySelectorModalFunction = (): void => {
        this.setState({displaySelectorModal: false})
    }

    setActiveTab = (key)=>{
        this.setState({
            activeKey: key
        })
    }

    fromURL(fromURL) {
        // console.log('test    from url', fromURL)
        return <Redirect to={fromURL} />
    }

    setDatastore(){
        try
        {
            let targetDID = this.props.currentProject.datastores.find(d => d.name == '案件一覧');

            // this.props.setDatastore({datastoreId: this.props.currentProject.datastores[1].d_id}) //current ? selectedPJ.datastores[0].d_id : ''
            this.props.setDatastore({datastoreId: targetDID.d_id, conditions: this.props.conditions});
        }catch(err)
        {
            Logger.console.error(err)
        }
    }

    getDropdown(): JSX.Element {
        return (
            <Dropdown
                overlayClassName={styles['formtype-dropdown']}
                overlay={
                    <Menu>
                        <Menu.Item>
                            <Button
                                type='link'
                                className={styles['formtype-dropdown-btn']}
                                onClick={(): void => { this.setState({ category: 'form', formType: 'COMPANY' }) }}>
                                {this.props.t('COMPANY.FORM.COMPANY_FORM')}
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                type='link'
                                className={styles['formtype-dropdown-btn']}
                                onClick={(): void => { this.setState({ category: 'form', formType: 'INDIVIDUAL' }) }}>
                                {this.props.t('COMPANY.FORM.INDIVIDUAL_FORM')}
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                type='link'
                                className={styles['formtype-dropdown-btn']}
                                onClick={(): void => { this.setState({ category: 'form', formType: '重要契約' }) }}>
                                {this.props.t('COMPANY.FORM.重要契約_FORM')}
                            </Button>
                        </Menu.Item>
                    </Menu>
                }>
                <Button
                    type='link'
                    icon='edit'
                    className={this.state.category === 'form' ? styles['item-btn-active'] : styles['item-btn']}
                    onClick={(): void => { this.setState({ category: 'form', formType: 'COMPANY'  }) }}
                >
                    調査依頼
                </Button>
            </Dropdown>
        )
    }

    downloadCsv() {
        FileSaver.saveAs(manual, 'NGファインダー簡易操作マニュアル（Ver.007-T20）.pdf')
    }

    render(): JSX.Element {
        return (
            <div>
                {this.setURL()}
                {this.props.currentProject && this.props.currentProject.datastores !== null && this.setDatastore()}
                <CommonHeader pickerType={this.pickerType} />
                {/* <LeftMenu /> */}
                {
                // !sessionStorage.getItem('url')
                    (this.pickerType === PickerType.POPUP && this.state.displaySelectorModal) ?
                        <Modal
                            style={{top: '32vh'}}
                            title={this.props.t('POPUP.WORKSPACE_PROJECT_SELECTION.TITLE')}
                            visible={!this.props.isLoading}
                            closable={false}
                            maskStyle={{backgroundColor: 'lightgray'}}
                            footer={
                                <div>
                                     {/* {this.props.currentDatastore && this.props.currentWorkspaceId && this.props.currentProjectId && this.displaySelectorModalFunction()} */}
                                    {
                                        (this.props.currentWorkspaceId && this.props.currentProjectId)
                                        && <Button type="primary" onClick={(): void => this.setState({displaySelectorModal: false})}>{this.props.t('BUTTON.OK')}</Button>
                                    }
                                </div>
                            }
                        >
                            <WorkspaceAndProjectPicker
                                displaySelectorModalFunction={this.displaySelectorModalFunction}
                                currentWorkspaceId={this.props.currentWorkspaceId}
                                pickerType={this.pickerType}>
                            </WorkspaceAndProjectPicker>
                        </Modal> :
                        <div>
                            <div>
                                {/* {this.fromURL()} */}
                                {!this.props.isLoading && !this.props.itemDetailsLoading ?
                                    <Layout>
                                        <Header className={styles['big-header']}>
                                            <Row type='flex' justify='space-between' align='middle'>
                                                <Col lg={8} md={8}>
                                                    <img src={require("../../../assets/images/logo.png")} style={{ height: 52, width: 'auto' }} />
                                                </Col>
                                                <Col lg={8} md={8}>
                                                    <Row type='flex' align='middle'>
                                                        <Col>
                                                            <Button
                                                                type='link'
                                                                icon='profile'
                                                                className={this.state.category === 'list' ? styles['item-btn-active'] : styles['item-btn']}
                                                                onClick={(): void => { this.setState({ category: 'list' }) }}
                                                                >
                                                                案件一覧
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            {this.getDropdown()}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={5} md={1} style={{ textAlign: 'right' }}>
                                                    {(this.props.userinfo && this.props.userinfo.is_ws_admin) &&
                                                        <Button
                                                            type='link'
                                                            className={styles['manage-btn']}
                                                            onClick={(): void => { this.setState({ category: 'setting' }) }}
                                                            >
                                                            管理
                                                        </Button>
                                                    }
                                                </Col>
                                                <Col lg={3} style={{textAlign: 'left'}}>
                                                    <Button 
                                                        onClick={this.downloadCsv}>操作マニュアルはこちらから↓</Button>
                                                </Col>
                                            </Row>
                                        </Header>
                                        <Content className={styles['main-container']}>
                                            {(this.state.category !== 'list' && this.state.category !== 'form' && this.state.category !== 'form' && this.state.category !== 'setting') && <Spin />}

                                            {this.state.category === 'list' &&
                                                <span>
                                                    <SearchForm handleChange={this.handleChange} currentProject={this.props.currentProject} />
                                                    <Layout style={{ padding: '0' }}>
                                                        <Content
                                                            style={{
                                                                background: '#fff',
                                                                margin: 0,
                                                            }}>
                                                            <br />
                                                            {this.props.currentProject && <ItemList setURLstate={this.setURLstate} formData={this.state.formData} />}
                                                        </Content>
                                                    </Layout>
                                                </span>
                                            }

                                            {this.state.category === 'form' &&
                                                <SurveyForm
                                                    setActiveTab={this.setActiveTab}
                                                    formType={this.state.formType} />
                                            }

                                            {this.state.category === 'setting' && <Members />}

                                        </Content>
                                    </Layout>
                                    : <Spin />
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    currentProjectId: ProjectSelectors.getCurrentProjectId(state),
    currentWorkspaceId: WorkspaceSelectors.getCurrentWorkspaceId(state),
    isLoading: MainSelectors.getMainLoading(state),
    currentProject: ProjectSelectors.getCurrentProject(state),
    currentDatastore: DatastoreSelector.getCurrentDatastoreId(state),
    currentPage: ItemsSelectors.getCurrentPage(state),
    itemID: ItemDetailsSelectors.getEntryId(state),
    itemDetailsLoading: ItemDetailsSelectors.getIsLoading(state),
    userinfo: UsersSelectors.getUserInfo(state),
    conditions: ItemsSelectors.getCurrentConditions(state)
})

const mapDispatchToProps = {
    getWorkspacesRequest: WorkspaceActions.getWorkspacesRequest,
    getCurrentUser: UsersActions.getCurrentUserRequest,
    setDatastore: DatastoreActions.setCurrentDatastoreId,
    setCurrentItem: ItemsActions.setCurrentItemId,
    setWorkspace: WorkspaceActions.setCurrentWorkspaceIdRequest,
    setProject: ProjectActions.setCurrentProjectId,
    getItemsListRequest: ItemsActions.getItemsListRequest
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home));