import React from 'react'
import { Form, Row, Card, Alert, Button, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import HttpService from 'app/services/httpService/httpService';
import { AlertType } from 'app/utils/antdHelper';
import styles from './login.module.css'
import { RootState } from 'app/services/store/rootReducer';
import { UsersSelectors } from 'app/services/store';
import { connect } from 'react-redux';
import PasswordChanger from 'app/components/password/passwordChanger';
import { PasswordPolicy } from 'app/utils/default.t';
import { Spinner } from '@blueprintjs/core';

interface ResetPasswordProps extends FormComponentProps, RouteComponentProps {
    userToken: string;
}

interface ResetPasswordState {
    form: {
        newPassword: string;
        newPasswordConfirmation: string;
    };
    confirmationId: string;
    alert: {
        type: AlertType;
        message: string;
        display: boolean;
    };
    policy: PasswordPolicy;
    locked: boolean;
    loading: boolean;
}

class ResetPassword extends React.Component<ResetPasswordProps> {
    state: ResetPasswordState = {
        form: {
            newPassword: '',
            newPasswordConfirmation: '',
        },
        confirmationId: '',
        alert: {
            type: AlertType.INFO,
            message: '',
            display: false
        },
        policy: {
            expired_day: 0,
            lockout_count: 0,
            lockout_time: 0,
            min_length: 0,
            pattern_check_type: 0,
            same_limit: 0,
            use_expired_day: false,
            use_lockout_count: false,
            use_lockout_time: false,
            use_min_length: false,
            use_pattern_check: false,
            use_same_limit: false,
            use_language_en: false,
            use_language_ja: false
        },
        locked: false,
        loading: false
    };

    componentDidMount() {
        this.confirmRequestId();
    }

    confirmRequestId = (): void => {
        const confirmationId = this.props.location.pathname.replace('/reset_password/', '');
        this.setState({loading: true});
        HttpService.GetAsync<any, any>("users/password/validate", {id: confirmationId}, HttpService.LinkerAPIBasePath).subscribe(
            res => {
                if(!res.data) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: 'リクエストを検証できませんでした',
                            display: true,
                        },
                        locked: true,
                        loading: false
                    });
                    return;
                }
                if(res.data.accessed) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: 'すでにアクセスしたパスワードの変更ページ、再適用してください',
                            display: true,
                        },
                        locked: true,
                        loading: false
                    });
                    return;
                }
                if(res.data.isElapsed) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: 'リクエストの有効期限が切れています。新しいパスワードを再度リクエストしてください',
                            display: true,
                        },
                        locked: true,
                        loading: false
                    });
                    return;
                }
                this.setState({
                    confirmationId: confirmationId,
                    policy: res.data.pwd_policy,
                    loading: false,
                })
            },
            err => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: '確認が無効です',
                        display: true,
                    },
                    loading: false,
                    locked: true
                });
            }
        )
    }

    submitNewPasswordRequest = (): void => {
        this.setState({loading: true});
        HttpService.PutAsync<any, any>("users/password/forgot", { id: this.state.confirmationId, new_password: this.state.form.newPassword, confirm_password: this.state.form.newPasswordConfirmation}, HttpService.LinkerAPIBasePath).subscribe(
            res => {
                this.props.history.push('/login')
            },
            err => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: 'エラーです',
                        display: true
                    },
                    loading: false
                });
            }
        )
    }

    checkConfirmationPassword = (rule, value, callback) => {
        try {
            if (value !== this.state.form.newPassword) {
                throw new Error('Field do not match');
            } else {
                callback();
            }
        } catch (err) {
            callback(err);
        }
    }

    changeFormField = (name: string, value: string): void => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    submitResetPasswordForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.submitNewPasswordRequest();
            }
            return false;
        });
    }


    render(): JSX.Element {
        return (
            <div style={{background: 'lightgray'}}>
                { (this.props.userToken) && <Redirect to='/' /> }
                {this.state.loading && <Spinner />}
                <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
                    <Row type="flex" justify="center">
                        <Col span={50}>
                            <Card className={styles['loginForm']}>
                            <Row type="flex" justify="center" className={styles['company-logo']}>
                                    <img alt="logo" src={require("../../../assets/images/logo_NGF.png")} />
                                </Row>
                                <Row type="flex" justify="center" className={styles['login-title']}>
                                    パスワードを変更する
                                </Row>
                                <Row>
                                    {this.state.alert.display && <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                                </Row>
                                {this.state.locked &&
                                    <Row justify='center'>
                                        <div className={styles['login-text']}>
                                            <Button
                                                type='primary'
                                                className={styles['btn']}
                                                onClick={(): void => {this.props.history.push('/login')}}>ログインページに移動
                                            </Button>
                                        </div>
                                    </Row>
                                }
                                {!this.state.locked &&
                                    <div>
                                        <Form onSubmit={this.submitResetPasswordForm} colon={false}>
                                            <PasswordChanger
                                                policy={this.state.policy}
                                                askCurrentPassword={false}
                                                form={this.props.form}
                                                onChange={this.changeFormField} />
                                            <Form.Item label=' ' labelCol={{span: 1}} wrapperCol={{span: 23}} hasFeedback>
                                                <Row justify='center'>
                                                    <Button
                                                        className={styles['btn']}
                                                        htmlType="submit"
                                                        disabled={
                                                            (!this.props.form.isFieldTouched("newPassword") || this.props.form.getFieldError("newPassword") !== undefined) ||
                                                            (!this.props.form.isFieldTouched("newPasswordConfirmation") || this.props.form.getFieldError("newPasswordConfirmation") !== undefined)
                                                        }
                                                        type='primary'>検証</Button>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                </div>}
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </div>)
    }
}

const mapStateToProps = (state: RootState) => ({
    userToken: UsersSelectors.getUserToken(state)
});

const mapDispatchToProps = {
}

const createdForm = Form.create()(withRouter(ResetPassword));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createdForm);