import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import Axios from "axios-observable";
import { AxiosRequestConfig } from "axios";

// HttpService singleton object for making http requests
class HttpService {
    // values here can changed from environment variables
    // use of these constants depends on which router/api-server you want to access.

    // apicore direct request via hexacloud proxy
    static readonly HexacloudBasePath: string = "hexacloud";
    // hexacloud aggregated api access and future api processing inside hexacloud
    static readonly HexaLinkBasePath: string = "hexalink2";
    // linker-api base path for linker-api proxy
    static readonly LinkerAPIBasePath: string = "linker-api";

    // getBaseUrl defines on which apiserver/router to call from the proxy
    public static getBaseUrl = (targetApi: string, basePath: string = HttpService.HexacloudBasePath): string => {
        return `/${basePath}/${targetApi}`
    }

    // Get common http get function
    public static GetAsync<Req,Res>(targetApi: string, params?: Req, basePath?: string, config?: AxiosRequestConfig): AxiosObservable<Res> {
        return Axios.get(this.getBaseUrl(targetApi, basePath), {params, ...config})
    }

    public static PostAsync<Req,Res>(targetApi: string, data: Req, basePath?: string, config?: AxiosRequestConfig): AxiosObservable<Res> {
        return Axios.post(this.getBaseUrl(targetApi, basePath), data, config);
    }

    public static DeleteAsync<Req,Res>(targetApi: string, data: Req, basePath?: string): AxiosObservable<Res> {
        return Axios.delete(this.getBaseUrl(targetApi, basePath), { data: data });
    }

    public static PutAsync<Req, Res>(targetApi: string, data: Req, basePath?: string, config?: AxiosRequestConfig): AxiosObservable<Res> {
        return Axios.put(this.getBaseUrl(targetApi, basePath), data, config);
    }


}

export default HttpService;