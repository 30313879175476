import React from 'react'
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { Row, Col, Select, Form, Input, Button, Checkbox, Icon, DatePicker, List, Spin } from 'antd';
import styles from './notice.module.css'
import { ItemsSelectors, ItemsActions, ProjectSelectors, DatastoreSelector } from 'app/services/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import HttpService from 'app/services/httpService/httpService'
import itemActions from '../itemActions/itemActions';

interface NoticeStateProps {
    fields: any;
    datastoreId: any;
    projectId: any;
    currentProject: any;
    currentProjectID: any;
    reload: boolean;
}

interface NoticeProps extends NoticeStateProps, WithTranslation {
}

const { Option } = Select;
const { Search } = Input;

class Notice extends React.Component<NoticeProps> {
    state = {
        notices: [] as any
    }

    componentDidMount() {
        this.setNotices()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.reload != this.props.reload){
            this.setNotices()
        }
    }

    setNotices() {
        return new Promise(resolve => {
            let notice_id = this.props.currentProject.datastores.find(prj => prj.display_id === 'NOTICE')
            if(!notice_id) return
            let url = `applications/${this.props.currentProjectID}/datastores/${notice_id.d_id}/items/search`
            const payload = {
                use_display_id: true,
                conditions : [],
                page: 1,
                per_page: 100
            }
            HttpService.PostAsync(url, payload, 'linker-api').subscribe( response => {
                if(response) {
                    this.setState({
                        notices: response.data
                    })
                }
            },
            err => {
                console.log(err)
            },
            () => {
            })
        })
    }

    render(): JSX.Element {
        return (
            <div className={styles['notice']}>
                <div className={styles['notice-title']}>お知らせ</div>
                {this.state.notices.items ? 
                    this.state.notices.items.length > 0 ?
                    <List
                        dataSource={this.state.notices.items}
                        className={styles['notice-body']}
                        split={false}
                        renderItem={(item: any)  => (
                        <List.Item className={[styles['notice-item'], styles[item.color]].join(' ')}>
                            {item.TEXTAREA}
                        {/* <span className={styles['notice-date']}>（{moment(item.created_at).format('YYYY/MM/DD')}）</span> */}
                        </List.Item>
                        )}>
                    </List>
                    : <div className={styles['notice-body']}>現在お知らせはございません。</div>
                : <Spin />}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    fields: ItemsSelectors.getFields(state),
    projectId: ProjectSelectors.getCurrentProjectId(state),
    datastoreId: DatastoreSelector.getCurrentDatastoreId(state),
    currentProjectID: ProjectSelectors.getCurrentProjectId(state)
})

const mapDispatchToProps = {
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Notice));
