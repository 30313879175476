import * as UsersSelectors from './user/selectors'
import { userSlice } from './user/slice'

import * as MainSelectors from './main/selectors'
import { mainSlice } from './main/slice'

import * as WorkspaceSelectors from './workspace/selectors'
import { workspacesSlice } from './workspace/slice'

import * as ProjectSelectors from './project/selectors'
import { projectsSlice } from './project/slice'

import * as DatastoreSelector from './datastore/selectors'
import { datastoresSlice } from './datastore/slice'

import * as ItemsSelectors from './items/selectors'
import { itemsSlice } from './items/slice'

import * as ItemDetailsSelectors from './itemDetails/selectors'
import { itemDetailsSlice } from './itemDetails/slice'

import * as GroupSelectors from './group/selectors'
import { groupsSlice } from './group/slice'

const UsersActions = {
    ...userSlice.actions,
}

const MainActions = {
    ...mainSlice.actions,
}

const WorkspaceActions = {
    ...workspacesSlice.actions,
}

const ProjectActions = {
    ...projectsSlice.actions,
}

const DatastoreActions = {
    ...datastoresSlice.actions,
}

const ItemsActions = {
    ...itemsSlice.actions,
}

const ItemsDetailsActions = {
    ...itemDetailsSlice.actions,
}

const GroupActions = {
    ...groupsSlice.actions,
}

export {
    UsersSelectors,
    UsersActions,
    MainSelectors,
    MainActions,
    WorkspaceSelectors,
    WorkspaceActions,
    ProjectSelectors,
    ProjectActions,
    DatastoreActions,
    DatastoreSelector,
    ItemsActions,
    ItemsSelectors,
    ItemsDetailsActions,
    ItemDetailsSelectors,
    GroupSelectors,
    GroupActions
}