import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const userSlice = createSlice({
  slice: "user",
  initialState: initialState,
  reducers: {
    setUserAuth: reducers.setUserAuth,
    setUserLoading: reducers.setUserLoading,
    loginRequest: reducers.loginRequest,
    loginSuccess: reducers.loginSuccess,
    loginFailed: reducers.loginFailed,
    getCurrentUserRequest: reducers.getCurrentUserRequest,
    getCurrentUserSuccess: reducers.getCurrentUserSuccess,
    getCurrentUserFailed: reducers.getCurrentUserFailed,
    logout: reducers.logout,
    reset: reducers.reset,
  }
});

export const {
  setUserAuth,
  setUserLoading,
  loginRequest,
  loginSuccess,
  loginFailed
} = userSlice.actions;