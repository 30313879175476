import React from 'react'
import {  Collapse } from 'antd';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import StatusActions from './actions/actions'
import styles from './itemDetailsSide.module.css'
import { Layout, Menu, Button, Icon,Col, Row, Input } from 'antd';
import DeleteConfirm from '../deleteConfirm/deleteConfirm'
import { ItemDetailsSelectors, ItemsActions, DatastoreSelector } from 'app/services/store';
import { thisExpression } from '@babel/types';
import HttpService from 'app/services/httpService/httpService';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const { TextArea } = Input;

interface ItemDetailsSideProps extends WithTranslation, ItemDetailsSideState {
    deleteItem: Function;
    onCollapse: Function;
    openDeleteModal: Function;
    changeEditMode: Function;
    actionCall: Function;
    onInputChange: Function;
    downloadFile: Function;
    file2Info: any;
    isEdit: boolean;
    comment: string;
}

interface ItemDetailsSideState {
    statuses: any;
    statusActions: any;
    currentItem: any;
    fields: any;
    history: any;
    datastore: any;
}

const { Panel } = Collapse;

class ItemDetailsSide extends React.Component<ItemDetailsSideProps> {
    state = {
        collapsed: true,
        visible: false,
    };


    onCollapse = collapsed => {
        this.props.onCollapse(collapsed)
        this.setState({ collapsed });
    };

    getStatusActions(): JSX.Element {
        return this.props.statusActions.map((data, index) => {
            let iconType = '';
            switch (data.operation) {
                case '2':
                    iconType = 'edit';
                    break;

                case '3':
                    iconType = 'delete';
                    break;

                default:
                    iconType = 'circle';
            }
            return (
                <Row className={styles['operation-button-group']} key={`${index}-${data.name}`}>
                    <Button
                        block
                        type={data.operation  === '2' ? 'primary' : 'default'}
                        className={styles['operation-button']}
                        disabled={this.props.isEdit}
                        onClick={()=>{this.props.actionCall(data)}}
                        icon={iconType}
                        >
                        {data.name}
                    </Button>
                </Row>
            )
        })
    }

    getTruncatedDate(date){
        let newDate = date.slice(0,10)
        return newDate
    }

    getHistory() {
        return this.props.history.histories.map((data, index) => {
            return (
                <Row style={{textAlign: 'center', width: '100%', marginTop: '15px', maxHeight: '500px'}} key={`${index}-${data.item_id}`}>
                    <Row>
                        <Col span={6}>
                            <span style={{color: 'grey', fontStyle: 'bold', fontSize: '14px'}}>
                                {data.history.username}
                            </span>
                        </Col>
                        <Col span={6}>
                            <span style={{color: 'grey', fontStyle: 'italic', fontSize: '10px'}}>
                                Commented
                            </span>
                        </Col>
                        <Col span={6}>
                        </Col>
                        <Col span={6}>
                            <span style={{color: 'grey', fontStyle: 'italic', fontSize: '10px'}}>
                                {this.getTruncatedDate(data.history.created_at)}
                            </span>
                        </Col>
                    </Row>
                    <Row style={{margin: '10px', textAlign: 'left', fontSize: '10px', color: 'black'}}>
                        <span>
                            {data.history.comment}
                        </span>
                    </Row>
                </Row>

            )
        })
    }

    showFinalDownloadButton(){
        if(!this.props.file2Info[0]){return}
        let SID = this.props.currentItem.status_id
        for(let status of this.props.statuses.statuses){
            if(status.data.id === SID && status.data.name === '納品済'){
                return ( <Row  style={{textAlign: 'center', width: '100%'}}>
                            <Button  style={{display: 'flex'}} className={styles['button-download']} onClick={()=>{this.props.downloadFile(this.props.file2Info[0].uid, this.props.file2Info[0].type)}}>
                                報告書ダウンロードはこちらから
                            </Button>
                        </Row>)
            }
        }
        return <div></div>
    }

    render(): JSX.Element {
        return (
                <Row className={styles['operation']}>
                    <Row className={styles['operation-title']}>
                        操作
                    </Row>
                    <Row>
                        {this.getStatusActions()}
                        {this.showFinalDownloadButton()}
                    </Row>
                {/* <Row>
                    <Col span={4} style={{background: '#D3F1EF'}}>
                        <Row style={{height : '500px'}} className={styles['side-text']}>
                            対応履歴
                        </Row>
                    </Col>
                    <Col span={20} style={{background: '#EDF8F7'}}>
                        <Row style={{height : '500px', overflow: 'scroll'}}>
                            {this.getHistory()}
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={4} style={{background: '#D4F1D9'}}>
                        <Row style={{height : '500px'}} className={styles['side-text']}>
                            コメント
                        </Row>
                    </Col>
                    <Col span={20} style={{background: '#EDF8EF'}}>
                        <Row style={{height : '500px', padding: '10px'}}>
                            <span>
                                コメント入力
                            </span>
                            <TextArea disabled={!this.props.isEdit} style={{marginTop: '10px'}} autosize={{ minRows: 10, maxRows: 18 }} value={this.props.comment} onChange={(e)=>this.props.onInputChange(e,'comment')} />
                            <span style={{fontStyle: 'italic', color: 'lightred'}}>
                                コメントは保存時に追加されます
                            </span>
                        </Row>
                    </Col>
                </Row> */}
            </Row>

        )
    }
}

const mapStateToProps = (state: RootState): ItemDetailsSideState => ({
    statuses: ItemDetailsSelectors.getStatuses(state),
    statusActions: ItemDetailsSelectors.getStateActions(state),
    currentItem: ItemDetailsSelectors.getEntry(state),
    fields: ItemDetailsSelectors.getFields(state),
    history: ItemDetailsSelectors.getHistory(state),
    datastore: DatastoreSelector.getCurrentDatastoreId(state)

});

const mapDispatchToProps = {
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemDetailsSide));