import StringUtils from "./stringUtils";

enum LogType {
    LOG,
    INFO,
    WARN,
    ERROR
}

interface Log {
    date: number;
    id: string;
    type: LogType;
    message: any;
}

class Logger {
    static logList = new Array<Log>();
    static production = (!process.env.NODE_ENV || process.env.NODE_ENV === 'production');
    static console = ((wConsole): {log: Function; info: Function; warn: Function; error: Function} => {
        return {
            log: (...args): void => {
                Logger.add({
                    date: Date.now(),
                    id: StringUtils.GenUUID(),
                    type: LogType.LOG,
                    message: args
                })
                if (!Logger.production) {
                    wConsole.log(args);
                }
            },
            info: (...args): void => {
                Logger.add({
                    date: Date.now(),
                    id: StringUtils.GenUUID(),
                    type: LogType.INFO,
                    message: args
                })
                if (!Logger.production) {
                    wConsole.info(args);
                }
            },
            warn: (...args): void => {
                Logger.add({
                    date: Date.now(),
                    id: StringUtils.GenUUID(),
                    type: LogType.WARN,
                    message: args
                })
                if (!Logger.production) {
                    wConsole.warn(args);
                }
            },
            error: (...args): void => {
                Logger.add({
                    date: Date.now(),
                    id: StringUtils.GenUUID(),
                    type: LogType.ERROR,
                    message: args
                })
                if (!Logger.production) {
                    wConsole.error(args);
                }
            }
        };
    })(window.console);

    static add(log: Log): void{
        this.logList.push(log);
    }

    static remove(id: string): void{
        const index = this.logList.findIndex(log => log.id === id);
        if(index > -1){
            this.logList.splice(index, 1);
        }
    }
}

export default Logger;