import HttpService from "app/services/httpService/httpService";
import { Observable } from "rxjs";
import { FileFields } from "app/services/store/itemDetails/types";

export enum FileType {
    PDF,
    IMAGE,
    OTHER
}

interface FileUploadParams {
    filename: string;
    filepath: string;
    file: any;
    field_id: string;
    item_id: string;
    d_id: string;
    p_id: string;
    display_order: number;
}

export class FileHelper {
    static deleteStoredFile(params: any): Observable<any> {
        return HttpService.PostAsync<any, any>(`delete_storage_object`, {params, undefined, headers :{"Content-Type": "application/xml"}});
    }

    static downloadFile(id: string): Observable<any> {
        return HttpService.GetAsync<any, any>(`files/${id}`, undefined, HttpService.LinkerAPIBasePath, {responseType: 'arraybuffer'});
    }

    static uploadFile(params: FileUploadParams): Observable<any> {
        return HttpService.PostAsync<any, any>(`new_item_file_attachment`, params, undefined, {headers: {"Content-Type": "multipart/form-data"}});
    }

    static getFileType = (file: FileFields): FileType => {
        let fileType = FileType.OTHER;
        if(file.contentType.includes("image")) { fileType = FileType.IMAGE; }
        if(file.contentType.includes("pdf")) { fileType = FileType.PDF; }
        return fileType
    }
}