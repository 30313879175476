import { ofType } from "redux-observable";
import { map, catchError, switchMap } from 'rxjs/operators';
import { GroupActions } from "..";
import { of } from "rxjs";
import { Observable } from "redux";
import HttpService from "app/services/httpService/httpService";
import * as apiModels from "./epic.types";
import * as storeModels from "./types";

const getGrouptreeEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(GroupActions.getGroupsRequest),
    switchMap(action =>
        HttpService.GetAsync<null, apiModels.api_get_grouptree_response>(`grouptree`, null, HttpService.LinkerAPIBasePath).pipe(
            map(response => {
                const grouptree = response.data ? response.data.result : new Array<storeModels.Group>();
                return GroupActions.getGroupsSuccess({ grouptree });
            }),
            catchError((error: string) => {
                return of(GroupActions.getGroupsFailed({ error }));
            })
        )
    )
);

export default [
    getGrouptreeEpic
];