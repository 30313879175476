import React from 'react';
import { Menu, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { WorkspaceSelectors, WorkspaceActions} from 'app/services/store';
import { RootState } from 'app/services/store/rootReducer';
import { Workspace } from 'app/services/store/workspace/types';
import { PickerType } from '../home/home';

const {Option} = Select;

interface WorkspacePickerProps {
    pickerType: PickerType;
    onChange?: Function;
}

const WorkspacePicker = (props: WorkspacePickerProps): JSX.Element => {
    const workspaces = useSelector((state: RootState) => WorkspaceSelectors.getWorkspaceList(state));
    const currentWorkspaceId = useSelector((state: RootState) => WorkspaceSelectors.getCurrentWorkspaceId(state));
    const dispatch = useDispatch();

    const onChange = (workspaceId: string): void => {
        if(props.onChange) props.onChange(workspaceId);
        dispatch(WorkspaceActions.setCurrentWorkspaceIdRequest({workspaceId}));
    }

    const headerPicker = (): JSX.Element => {
        return (
          <div>
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['2']}
                style={{ lineHeight: '64px' }}>
                <Select
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(workspaceId: string): any => onChange(workspaceId)}
                    value={ currentWorkspaceId }>
                    {
                        workspaces.map((value: Workspace) =>
                        {
                            return <Option value={value.w_id} key={value.w_id}> {value.name}</Option>
                        })
                    }
                </Select>
            </Menu>
          </div>
        )
    }

    const popupPicker = (): JSX.Element => {
        return (
            <div>
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    style={{ lineHeight: '64px' }}>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        onChange={(workspaceId: string): any => onChange(workspaceId)}
                        value={ currentWorkspaceId }>
                        {
                            workspaces.map((value: Workspace) =>
                            {
                                return <Option value={value.w_id} key={value.w_id}> {value.name}</Option>
                            })
                        }
                    </Select>
                </Menu>
            </div>
        )
    }

    return (
        <div>
            {props.pickerType === PickerType.HEADER ? headerPicker() : popupPicker()}
        </div>
    )
}

export default WorkspacePicker;