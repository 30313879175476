import React from 'react'
import {  Collapse } from 'antd';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Layout, Menu, Button, Modal } from 'antd';


interface DeleteConfirmProps extends WithTranslation {
    deleteItem: Function;
    showModal: Function;
    visible: boolean;

}

interface DeleteConfirmState {
}


class DeleteConfirm extends React.Component<DeleteConfirmProps> {
    
      handleOk = e => {
          this.props.deleteItem()
          this.props.showModal()
      };
    
      handleCancel = e => {
          
        this.props.showModal()
      };

      

    render(): JSX.Element {
        return (
                    <Modal
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelText='キャンセル'
                    okText='削除する'
                    >
                        <span>このデータを削除します。よろしいですか？</span>
                    </Modal>
            
        )
    }
}

const mapStateToProps = (state: RootState):DeleteConfirmState => ({
});

const mapDispatchToProps = {
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteConfirm));