import { ProjectState, initialState } from "./state";
import { PayloadAction } from "redux-starter-kit";
import { Project } from "./types";

export const setCurrentProjectId = (state: ProjectState, action: PayloadAction<{projectId: string}>): ProjectState => {
  return {
    ...state,
    current_project_id: action.payload.projectId
  }
}

export const getProjectsRequest = (state: ProjectState, action: PayloadAction<{workspaceId: string}>): ProjectState => {
  return {
    ...state,
    loading: true
  }
}

export const getProjectsSuccess = (state: ProjectState, action: PayloadAction<{projects: Array<Project>}>): ProjectState => {
  return {
    ...state,
    project_list: action.payload.projects,
    loading: false,
    error: ''
  }
}

export const getProjectsFailed = (state: ProjectState, action: PayloadAction<{error: string}>): ProjectState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error
  }
}

export const reset = (state: ProjectState): ProjectState => {
  return {
    ...initialState
  }
}