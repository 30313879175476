import { ItemEntry } from "../items/types";


export interface ItemDetails {
    labels: Array<ItemDetailsLabel>;
    post: ItemDetailsPost;
    entry: ItemEntry;
    fields: {[k: string]: ItemDetailsField};
    layout: {[k: string]: ItemDetailsLayout};
    relations: Array<ItemDetailsRelation>;
    actions: Array<ItemDetailsAction>;
    statusActions: Array<ItemDetailsAction>;
    statuses: ItemDetailsStatus;
    titles: Array<ItemDetailsTitle>;
    mode: ItemDetailMode;
    currentAction? : ItemDetailsCurrentAction;
}

export enum ItemDetailMode {
    CLOSE,
    COPY,
    NEW,
    DISPLAY,
    LAYOUT,
    UPDATE
}

export interface ItemDetailsCurrentAction {
    a_id: string;
    created_at: string;
    d_id: string;
    description: string;
    display_order: number;
    isNotifyEmailToGroups: boolean;
    isNotifyEmailToRoles: boolean;
    isNotifyToGroups: boolean;
    isNotifyToRoles: boolean;
    isNotifyToUsers: boolean;
    isOwnedBySystem: boolean;
    isPublishToMyGroups: boolean;
    operation: string;
    updated_at: string;
    action_field_settings: {[k: string]: {
        a_id: string;
        column_id: string;
        sf_id: string;
        manadatory?: boolean;
        show?: boolean;
        update?: boolean;
    };};
}

export interface ItemDetailsTitle {
    columnID: string;
    title_order_number: number;
}

export interface ItemDetailsPost {
    histories: any;
    unread: number;
}

export interface ItemDetailsLabel {
    label_id: string;
    name: string;
    color: string;
    display_order: number;
    field_ids: Array<string>;
}

export interface ItemDetailsField {
    access_keys: Array<string>;
    as_title: boolean;
    d_id: string;
    dataType: string;
    display_id: string;
    f_id: string;
    fieldIndex: number;
    field_csv_name: string;
    file_info?: { show_img: boolean };
    full_text: boolean;
    hideOnInput: boolean;
    id: string;
    max_value: string;
    min_value: string;
    name: string;
    names: {[k: string]: string};
    options?: Array<ItemDetailsOption>;
    p_id: string;
    search: boolean;
    show_list: boolean;
    status: boolean;
    title_order: number;
    unique: boolean;
    w_id: string;
}

export interface ItemDetailsOption {
    color: string;
    enabled: boolean;
    o_id: string;
    sort_id: number;
    value: string;
}

export interface ItemDetailsLayout {
    col: number;
    created_at: string;
    display_id: string;
    id: string;
    row: number;
    sizeX: number;
    sizeY: number;
    updated_at: string;
}

export interface ItemDetailsRelation {
    d_id: string;
    p_id: string;
    w_id: string;
    name: string;
    uploading: boolean;
    imported: boolean;
    no_status: boolean;
    show_in_menu: boolean;
    deleted: boolean;
    display_order: number;
    display_id: string;
    show_only_dev_mode: boolean;
    use_qr_download: boolean;
    use_csv_update: boolean;
    use_external_sync: boolean;
    use_replace_upload: boolean;
    unread: number;
    invisible: boolean;
    use_grid_view: boolean;
    use_board_view: boolean;
    data_source: string;
    is_external_service: boolean;
    pj_name: string;
    relations_info: {
        related_d_id: string;
        relation_order: number;
        label_links: Array<{
            local_label_id: string;
            foreign_label_id: string;
        }>;
    };
}

export interface ItemDetailsAction {
    _id: string;
    a_id: string;
    access_keys: Array<string>;
    created_at: string;
    description: string;
    d_id: string;
    display_order: number;
    isOwnedBySystem: boolean;
    is_status_action: boolean;
    name: string;
    operation: string;
    pin_by_default: boolean;
    runActionScriptDuringImport: boolean;
    search_keys: string;
    send_mail: false;
    slack_channels: Array<any>;
    user_slack_channels: {};
    p_id: string;
    set_status: string;
    status_id: string;
    updated_at: string;
    w_id: string;
}

export interface ItemDetailsStatus {
    statuses: any;
    statusOrderSettings: any;
}

export interface FileFields {
    contentType: string;
    created_at: string;
    d_id: string;
    datastore_id: string;
    deleted: boolean;
    display_order: number;
    field_id: string;
    file_id: string;
    filename: string;
    filepath: string;
    i_id: string;
    item_id: string;
    mediaLink: string;
    name: string;
    p_id: string;
    selfLink: string;
    size: number;
    temporary: boolean;
    timeCreated: string;
    updated: string;
    user_id: string;
    w_id: string;
    _id: string;
}