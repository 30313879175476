import { createSelector } from "reselect";
import { Main } from "./types";
import { RootState } from "../rootReducer";
import { MainState } from "./state";

const mainState = (state: RootState): MainState => state.main

export const getMainLoading = createSelector<RootState, MainState, boolean>(
    mainState,
    (main: Main) => main.loading
);