import React from 'react'
import { Router, Route, Switch } from 'react-router-dom';

import 'antd/dist/antd.css';
import 'app/utils/logger';
import { RootState } from './app/services/store/rootReducer';
import { PersistGate } from 'redux-persist/integration/react';

import rootStore from './app/services/store/rootStore';

import Login from './app/components/login/login';
import Preloader from './app/components/utils/preloader/preloader';
import ErrorBoundary from './app/components/utils/errorBoundary/errorBoundary';
import PrivateRoute from './app/components/privateRoute/privateRoute';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import Home from 'app/components/home/home';
import Invitation from './app/components/login/invitation';
import ResetPassword from './app/components/login/resetPassword';

interface AppProps {
}

class App extends React.Component<AppProps> {
	render(): JSX.Element {
		const history = createBrowserHistory();
		return (
		<div className="App">
			<PersistGate persistor={rootStore.persistor}>
				<Preloader/>
				<Router history={history}>
					<ErrorBoundary>
						<Switch>
							<Route path="/login"
								render={({ match: { url } }) => (
									<>
										<Switch>
											<Route path={`${url}/pwreset`} component={Login} />
											{/* <Route path={`${url}/register`} component={Login} /> */}
											<Route exact path={`${url}/`} component={Login} />
										</Switch>
									</>
								)}
								/>
							<Route path="/confirm_invite" component={Invitation} />
							<Route path="/reset_password" component={ResetPassword} />
							<PrivateRoute exact path="/" component={Home} />
							<PrivateRoute path="/:ws?/:pj?/:ds?/:item?" component={Home} />
						</Switch>
					</ErrorBoundary>
				</Router>
			</PersistGate>
		</div>
		);
	}
}


const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
