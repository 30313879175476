import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";

export const groupsSlice = createSlice({
  slice: "group",
  initialState: initialState,
  reducers: {
    setCurrentGroupId: reducers.setCurrentGroupId,
    getGroupsRequest: reducers.getGroupsRequest,
    getGroupsSuccess: reducers.getGroupsSuccess,
    getGroupsFailed: reducers.getGroupsFailed,
  }
});

export const {
  setCurrentGroupId,
  getGroupsRequest,
  getGroupsSuccess,
  getGroupsFailed
} = groupsSlice.actions;