import React from 'react'
import { Row, Select, Form, Input, Button, Card, Alert, Col, Icon } from 'antd';
import { Redirect } from 'react-router-dom';
import styles from './login.module.css'
import { AlertType } from 'app/utils/antdHelper';
import { FormComponentProps } from 'antd/lib/form';
import HttpService from 'app/services/httpService/httpService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { UsersSelectors, UsersActions } from 'app/services/store';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { PasswordPolicy } from 'app/utils/default.t';
import PasswordChanger from 'app/components/password/passwordChanger';

interface InvitationProps extends RouteComponentProps, FormComponentProps {
    userToken: string;
    loginRequest: Function;
}

interface InvitationState {
    form: {
        email: string;
        confirmationId: string;
        username: string;
        newPassword: string;
        newPasswordConfirmation: string;
    };
    alert: {
        type: AlertType;
        message: string;
        display: boolean;
    };
    policy: PasswordPolicy;
    loading: boolean;
    locked: boolean;
}

const { Option } = Select;

class Invitation extends React.Component<InvitationProps> {
    state: InvitationState = {
        form: {
            email: '',
            confirmationId: '',
            username: '',
            newPassword: '',
            newPasswordConfirmation: ''
        },
        alert: {
            type: AlertType.INFO,
            message: '',
            display: false
        },
        policy: {
            expired_day: 0,
            lockout_count: 0,
            lockout_time: 0,
            min_length: 0,
            pattern_check_type: 0,
            same_limit: 0,
            use_expired_day: false,
            use_lockout_count: false,
            use_lockout_time: false,
            use_min_length: false,
            use_pattern_check: false,
            use_same_limit: false,
            use_language_en: false,
            use_language_ja: false
        },
        loading: false,
        locked: false
    };

    componentDidMount(): void {
        const confirmationId = this.props.location.pathname.replace('/confirm_invite/', '');
        this.setState({loading: true});
        HttpService.GetAsync<any, any>('users/registration/confirm', {id: confirmationId}, HttpService.LinkerAPIBasePath).subscribe(
            (res) => {
                if(!res.data.user) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: '有効なリクエストを確認できませんでした',
                            display: true,
                        },
                        locked: true,
                        loading: false
                    });
                    return;
                }
                if(res.data.user.isElapsed) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: 'URLの有効期限が切れています',
                            display: true
                        },
                        loading: false,
                        locked: true
                    });
                    return;
                }
                if(res.data.user.confirmed) {
                    this.setState({
                        alert: {
                            type: AlertType.ERROR,
                            message: 'ユーザーは既に登録されています',
                            display: true
                        },
                        loading: false,
                        locked: true
                    })
                    return;
                }
                this.setState({
                    form: {
                        ...this.state.form,
                        confirmationId: res.data.user.confirmation_id,
                        email: res.data.user.email
                    },
                    loading: false,
                })
            },
            (err) => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: 'URLのリンクが無効です',
                        display: true,
                    },
                    loading: false,
                    locked: true
                });
            }
        )
    }

    componentDidUpdate(a, b) {
        if(this.state.form !== b.form) {
            // console.log('this.state.form', this.state.form)
        }
    }

    changeFormField = (name: string, value: any): void => {
        // console.log('this.state.form', name, value)
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    confirm = (): void => {

        const params = {
            confirmation_id: this.state.form.confirmationId,
            email: this.state.form.email,
            username: this.state.form.username,
            workspace: 'My Workspace',
            password: this.state.form.newPassword,
            additional_info: {}
        }
        HttpService.PostAsync('users/registration/confirm', params, HttpService.LinkerAPIBasePath).subscribe(
            (res) => {
                this.setState({
                    alert: {
                        type: AlertType.SUCCESS,
                        message: 'ワークスペースへ移動します',
                        display: true
                    }
                });
                setTimeout(() => { this.props.loginRequest({ email: this.state.form.email, password: this.state.form.newPassword})}, 2000);
            },
            (err) => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: 'エラーが発生しました',
                        display: true
                    },
                    locked: false
                });
            }
        )
    }

    allFieldValid(): boolean {
        const fieldNames = ['newPassword', 'newPasswordConfirmation', 'username'];
        return (Object.values(this.props.form.getFieldsError(fieldNames)).every(el => el === undefined) && fieldNames.map((field) => this.props.form.isFieldTouched(field)).every(el => el === true));
    }

    submitInvitationForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.confirm();
            }
            return false;
        });
    }

    render(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{background: 'lightgray'}}>
            { (this.props.userToken) && <Redirect to='/' /> }
            <Row type='flex' justify='center' align='middle' style={{ height: '100vh' }}>
                <Row type='flex' justify='center'>
                    <Col span={50}>
                        <Card className={styles['loginForm']}>
                            <Row type='flex' justify='center' className={styles['company-logo']}>
                                <img alt='logo' src={require('../../../assets/images/logo_NGF.png')} />
                            </Row>
                            <Row>
                                {this.state.alert.display && <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                            </Row>
                            {this.state.locked &&
                                <Row justify='center'>
                                    <div>
                                        <Button
                                        type='primary'
                                        className={styles['btn']}
                                        onClick={(): void => {this.props.history.push('/login')}}>ログインページに移動</Button>
                                    </div>
                                </Row>
                            }
                            {!this.state.locked &&
                                <div className={styles['pwreset-form']}>
                                    <p className={styles['pwreset-form-label']}>
                                        <Icon type="user" />あなたの氏名を正確に入力してください
                                    </p>
                                    <Form colon={false} onSubmit={this.submitInvitationForm}>
                                        <Form.Item label='' hasFeedback>
                                            {getFieldDecorator('username', {
                                                rules: [{ required: false, message: '氏名を入力してください' }]
                                            })(<Input
                                                    className={styles['login-input']}
                                                onChange={(e): void => this.changeFormField('username', e.target.value)}
                                                    type='text'
                                                    placeholder='山田　太郎'
                                                />
                                            )}
                                        </Form.Item>
                                        <PasswordChanger
                                            policy={this.state.policy}
                                            askCurrentPassword={false}
                                            form={this.props.form}
                                            onChange={this.changeFormField}></PasswordChanger>
                                        <Form.Item label=' ' labelCol={{span: 1}} wrapperCol={{span: 23}} hasFeedback>
                                            <Row justify='center' type='flex'>
                                                <Button
                                                    type='primary'
                                                    htmlType='submit'
                                                    className={styles['btn']}
                                                    disabled={!this.allFieldValid()}
                                                >
                                                    ユーザー登録
                                                </Button>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>
            </Row>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    userToken: UsersSelectors.getUserToken(state)
});

const mapDispatchToProps = {
    loginRequest: UsersActions.loginRequest,
}

const createdForm = Form.create()(withRouter(Invitation));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createdForm);