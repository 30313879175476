import { ofType } from "redux-observable";
import { map, catchError, switchMap, concatMap } from 'rxjs/operators';
import { ProjectSelectors, DatastoreSelector, ItemsDetailsActions, ItemsSelectors, ItemDetailsSelectors, ItemsActions, UsersSelectors, WorkspaceSelectors } from "..";
import HttpService from "app/services/httpService/httpService";
import { of, Observable, EMPTY } from "rxjs";
import * as apiModels from "./epic.types";
import ApiHelper from "app/utils/apiHelper";
import { ItemDetailMode } from "./types";
import ActionHelper from "app/utils/actionHelper";

const getItemDetailsEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.getItemDetailsRequest),
    switchMap((action: {payload: {itemId: string}}) => {
        const datastore_id = DatastoreSelector.getCurrentDatastoreId(state$.value);
        const project_id = ProjectSelectors.getCurrentProjectId(state$.value);
        return HttpService.PostAsync<apiModels.api_aggregated_api_itemdetails_request, apiModels.api_aggregated_api_itemdetails_response>(`aggregated_api_itemdetails`, {
            get_datastore_item_details: {
                datastore_id,
                item_id: action.payload.itemId,
            },
            get_item_posts: {
                datastore_id,
                project_id,
                item_id: action.payload.itemId,
                from_item_index: 0,
                to_item_index: 20
            }
        }, HttpService.HexaLinkBasePath).pipe(
            map((result) => {
                const items = [result.data.get_datastore_item_details.itemDetails];
                const fields = Object.values(result.data.get_datastore_item_details.fields);
                const entry = ApiHelper.buildEntries(items, fields)[0];
                return ItemsDetailsActions.getItemDetailsSuccess({
                    entry: entry,
                    labels: result.data.get_datastore_item_details.labels,
                    post: result.data.get_item_posts,
                    fields: result.data.get_datastore_item_details.fields,
                    layout: result.data.get_datastore_item_details.field_layout,
                    statusActions : result.data.get_datastore_item_details.actions,
                    relations: result.data.get_datastore_item_details.related_datastores,
                    actions : result.data.get_datastore_item_details.stateflowActions,
                    statuses : {
                        statuses: result.data.get_datastore_item_details.statuses,
                        statusOrderSettings: result.data.get_datastore_item_details.statusOrderSettings
                    },
                    titles: result.data.get_datastore_item_details.titles,
                    mode: ItemDetailMode.DISPLAY
                })
            }),
            catchError((error: string) => {
                return of(ItemsDetailsActions.getItemDetailsFailed({error}));
            })
        )
    })
);

const selectActionEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.selectAction),
    concatMap((action: {payload: {actionId: string}}) => {
        const sAction = ItemDetailsSelectors.getActionById(state$.value)(action.payload.actionId);
        switch(sAction.operation){
            case ActionHelper.actionTypes.ADD:
                return [
                    ItemsDetailsActions.newItemModeRequest({keepFieldsValue: false}),
                    ItemsDetailsActions.getActionSettingsRequest({actionId: action.payload.actionId}),
                    ItemsDetailsActions.setMode({mode : ItemDetailMode.NEW})
                ]
            case ActionHelper.actionTypes.EDIT:
                return [
                    ItemsDetailsActions.getActionSettingsRequest({actionId: action.payload.actionId}),
                    ItemsDetailsActions.setMode({mode : ItemDetailMode.UPDATE})
                ]
            case ActionHelper.actionTypes.DELETE:
                return [
                    ItemsActions.deleteItemRequest({itemId: ItemsSelectors.getCurrentItemId(state$.value), actionId: action.payload.actionId})
                ]
            case ActionHelper.actionTypes.COPY:
                return [
                    ItemsDetailsActions.newItemModeRequest({keepFieldsValue: true}),
                    ItemsDetailsActions.getActionSettingsRequest({actionId: action.payload.actionId}),
                    ItemsDetailsActions.setMode({mode : ItemDetailMode.COPY})
                ]
            default:
                return EMPTY;
        }
    })
);

const newItemModeRequestEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.newItemModeRequest),
    switchMap((action: {payload: {keepFieldsValue: boolean}}) => {
        const newFieldsValues: {[k: string]: any} = {}
        const fields = ItemDetailsSelectors.getFields(state$.value);
        const entry = ItemDetailsSelectors.getEntry(state$.value);
        const keepFields = action.payload.keepFieldsValue;
        for (const key in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, key)) {
                newFieldsValues[key] = keepFields ? entry.fields[key] : ''
            }
        }
        return HttpService.GetAsync<null, {item_id: string}>(`get_new_item_id`).pipe(
            map((result) => {
                return ItemsDetailsActions.newItemModeSuccess({
                    newItemId: result.data.item_id,
                    fields: newFieldsValues
                })
            }),
            catchError((error: string) => {
                return of(ItemsDetailsActions.newItemModeFailed({error}));
            })
        )
    })
);

const getActionSettingsRequestEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.getActionSettingsRequest),
    switchMap((action: {payload: {actionId: string}}) => {
        return HttpService.GetAsync<apiModels.get_action_and_field_settings_request, apiModels.get_action_and_field_settings_response>(`get_action_and_field_settings`, {
            action_id: action.payload.actionId
        }, HttpService.HexacloudBasePath).pipe(
            map((result) => {
                return ItemsDetailsActions.getActionSettingsSuccess({
                    action : {
                        ...result.data.action,
                        action_field_settings : result.data.action_field_settings
                    }
                })
            }),
            catchError((error: string) => {
                return of(ItemsDetailsActions.getActionSettingsFailed({error}));
            })
        )
    })
);

const newItemRequestEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.newItemRequest),
    switchMap((action: {payload: {actionId: string; fields: {[k: string]: any}}}) => {
        const datastoreId = DatastoreSelector.getCurrentDatastoreId(state$.value);
        const projectId =  ProjectSelectors.getCurrentProjectId(state$.value);
        const workspaceId = WorkspaceSelectors.getCurrentWorkspaceId(state$.value);
        const itemId = ItemDetailsSelectors.getEntryId(state$.value);
        const userId = UsersSelectors.getUserId(state$.value);
        const username = UsersSelectors.getUsername(state$.value);
        return HttpService.PostAsync<apiModels.new_item_record_request, apiModels.new_item_record_response>(`new_item_record`, {
            a_id: action.payload.actionId,
            d_id: datastoreId,
            p_id: projectId,
            item: {
                d_id: datastoreId,
                p_id: projectId,
                ...action.payload.fields
            },
            item_id: itemId,
            user_id: userId,
            w_id: workspaceId
        }, HttpService.HexacloudBasePath).pipe(
            switchMap((result) => {
                const buildChanges = ApiHelper.getFieldsAsArray(action.payload.fields);
                const entry = ApiHelper.changeEntryField(buildChanges, ItemDetailsSelectors.getEntry(state$.value), ItemDetailsSelectors.getFields(state$.value));
                return [
                    ItemsActions.addEntry({
                        entry: {
                            access_keys: '',
                            created_at: Date.now().toString(),
                            created_by: username,
                            fields: entry,
                            d_id: datastoreId,
                            i_id: result.data.id,
                            p_id: projectId,
                            rev_no: 0,
                            title: '',
                            unread: "0",
                            updated_at: Date.now().toString(),
                            updated_by: username,
                            _id: result.data.id
                        }
                    }),
                    ItemsDetailsActions.setMode({mode: ItemDetailMode.CLOSE})
                ]
            }),
            catchError((error: string) => {
                return of(ItemsDetailsActions.getActionSettingsFailed({error}));
            })
        )
    })
);

const updateItemRequestEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsDetailsActions.updateItemRequest),
    switchMap((action: {payload: {actionId: string; changes: {[k: string]: any}}}) => {
        const buildChanges = ApiHelper.getFieldsAsArray(action.payload.changes);
        return HttpService.PostAsync<apiModels.update_post_item_history_request, apiModels.update_post_item_history_response>(`update_post_item_history`, {
            action_id: action.payload.actionId,
            history: {
                datastore_id: DatastoreSelector.getCurrentDatastoreId(state$.value),
                item_id: ItemsSelectors.getCurrentItemId(state$.value),
                comment: ""
            },
            changes : buildChanges,
            rev_no: ItemDetailsSelectors.getRevisionNumber(state$.value)
        }, HttpService.HexacloudBasePath).pipe(
            switchMap((result) => {
                const entry = ApiHelper.changeEntryField(buildChanges, ItemDetailsSelectors.getEntry(state$.value), ItemDetailsSelectors.getFields(state$.value));
                return [
                    ItemsDetailsActions.updateItemSuccess({changes: buildChanges}),
                    ItemsActions.updateEntry({fieldId: ItemsSelectors.getCurrentItemId(state$.value), changes: {fields: entry}}),
                    ItemsDetailsActions.setRevisionNumber({ revisionNumber: result.data.error.rev_no }),
                    ItemsDetailsActions.setMode({ mode: ItemDetailMode.DISPLAY })
                ]
            }),
            catchError((error: string) => {
                return of(ItemsDetailsActions.updateItemFailed({error}));
            })
        )
    })
);

export default [
    getItemDetailsEpic,
    selectActionEpic,
    newItemModeRequestEpic,
    newItemRequestEpic,
    getActionSettingsRequestEpic,
    updateItemRequestEpic
];