import React from 'react'
import { Row, Form, Input, Button, Card, Checkbox, Col, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { UsersActions, UsersSelectors } from 'app/services/store';
import { Redirect } from 'react-router-dom';
import styles from './login.module.css'
import HttpService from 'app/services/httpService/httpService';
import { AlertType } from 'app/utils/antdHelper';
import { RouteComponentProps, withRouter } from 'react-router';

enum LoginPage {
    LOGIN = "login",
    RETRIEVE_PASSWORD = "pwreset",
}

interface LoginStateProps extends FormComponentProps {
    userToken: string;
    loginError: string;
}

interface LoginProps extends LoginStateProps, RouteComponentProps {
    loginRequest: (e: {email: string; password: string}) => void;
}

interface LoginState {
    loginForm: {
        email: string;
        password: string;
    };
    retrieveForm: {
        email: string;
    };
    signupForm: {
        email: string;
        agreeCondition: boolean;
    };
    alert: {
        type: AlertType;
        message: string;
        display: boolean;
    };
    formSucceed: boolean;
    page: LoginPage;
}

const initState = {
    loginForm: {
        email: '',
        password: ''
    },
    retrieveForm: {
        email: ''
    },
    signupForm: {
        email: '',
        agreeCondition: false
    },
    alert: {
        type: AlertType.INFO,
        message: '',
        display: false
    },
    formSucceed: false,
    page: LoginPage.LOGIN
}

class Login extends React.Component<LoginProps> {
    state: LoginState = {...initState};
    unlisten;

    componentDidMount() {
        this.checkPage();
        this.unlisten = this.props.history.listen((location, action) => {
            this.checkPage();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkPage() {
        let defaultPage = LoginPage.LOGIN;
        if (this.props.match.path.includes(LoginPage.RETRIEVE_PASSWORD)) {
            defaultPage = LoginPage.RETRIEVE_PASSWORD;
        }
        this.setState({
            page: defaultPage,
            formSucceed: false
        });
    }

    changeFormField = (value: any, formName: string, valueName: string): void => {
        this.setState({
            [formName]: {
                ...this.state[formName],
                [valueName]: value
            }
        });
    }

    changePage(page: LoginPage) {
        this.props.history.push(page === LoginPage.LOGIN ? "/login" : "/login/"+page);
        this.setState({
            ...initState,
            page
        });
    }

    retrievePassword() {
        const params = {
            email: this.state.retrieveForm.email,
            host: window.location.origin
        }
        HttpService.PostAsync<any, any>('users/password/forgot', params, HttpService.LinkerAPIBasePath).subscribe(
            (resp) => {
                if(resp.data.valid_email){
                    this.setState({
                        alert : {
                            type: AlertType.SUCCESS,
                            message: 'パスワードリセットの確認メールが送信されました',
                            display: true
                        }
                    });
                    setTimeout(() => this.changePage(LoginPage.LOGIN), 3000);
                } else {
                    this.setState({
                        alert : {
                            type: AlertType.ERROR,
                            message: '入力したメールアドレスがアカウントと一致しません',
                            display: true
                        }
                    });
                }
            },
            (err) => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: '入力されたデータは無効です',
                        display: true
                    }
                })
            }
        )
    }

    signup(): void {
        const params = {
            email: this.state.signupForm.email,
            registration_domain: window.location.host,
            registration_path: "confirm_email",
            hostname: window.location.host,
            protocol: window.location.protocol,
            additional_info: {}
        }
        HttpService.PostAsync('users/registration', params, HttpService.LinkerAPIBasePath).subscribe(
            (resp) => {
                this.setState({
                    alert: {
                        type: AlertType.SUCCESS,
                        message: '環境作成完了後（2、3分前後）招待メールを送付します。メール受信後はメールの指示に従い、設定を進めてください。 5秒後にログインにリダイレクトします。',
                        display: true
                    },
                    formSucceed: true
                });
                setTimeout(() => this.changePage(LoginPage.LOGIN), 5000);
            },
            () => {
                this.setState({
                    alert: {
                        type: AlertType.ERROR,
                        message: 'ユーザー登録済のメールアドレスです。サインインのリンクからサインインしてください。',
                        display: true
                    }
                })
            }
        )
    }

    submitLoginForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.loginRequest(this.state.loginForm);
            }
            return false;
        });
    }

    loginRender(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row type='flex' justify='center' className={styles['login-title']}>
                    ログイン
                </Row>
                <Form onSubmit={this.submitLoginForm}>
                    <Form.Item style={{ marginTop: 30 }}>
                        {getFieldDecorator('loginEmail', {
                            rules: [{ required: true, message: 'メールアドレスを入力してください' },
                                    { type: 'email', message: 'メールアドレスのフォーマットが正しくありません。'}]
                        })(<Input className={styles['login-input']}
                            onChange={(e): void => this.changeFormField(e.target.value, 'loginForm', 'email')}
                            type='mail'
                            placeholder='メールアドレス'
                        />)}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('loginPassword', {
                            rules: [{ required: true, message: 'パスワードを入力してください' }]
                        })(<Input className={styles['login-input']}
                            onChange={(e): void => this.changeFormField(e.target.value, 'loginForm', 'password')}
                            autoComplete="password"
                            type='password'
                            placeholder='パスワード'
                        />)}
                    </Form.Item>
                    <Form.Item>
                        <Row justify='center' type='flex'>
                            <Button
                                type='primary'
                                htmlType='submit'
                                disabled={(
                                    (!this.props.form.isFieldTouched("loginEmail") || this.props.form.getFieldError("loginEmail") !== undefined) ||
                                    (!this.props.form.isFieldTouched("loginPassword") || this.props.form.getFieldError("loginPassword") !== undefined)
                                )}
                                className={styles['primary-btn']}>
                                ログイン
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
                <Row justify='center' type="flex" className={styles['login-footer']}>
                    <div className={styles['login-text']}>
                        <Button type='link' htmlType='submit' className={styles['link-btn']} onClick={(): void => {this.changePage(LoginPage.RETRIEVE_PASSWORD)}}> パスワードを忘れた方</Button>
                    </div>
                </Row>
            </div>
        )
    }

    submitRetrievePasswordForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.retrievePassword();
            }
            return false;
        });
    }

    retrievePasswordRender(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row type='flex' justify='center' className={styles['login-title']}>
                    パスワードのリセット
                </Row>
                <Row className={styles['forgot-title']}>
                    リセットするアカウントのメールアドレスを入力してください
                </Row>
                <Form onSubmit={this.submitRetrievePasswordForm}>
                    <Form.Item label=' ' labelCol={{span: 1}} wrapperCol={{span: 23}} style={{ marginTop: 30 }}>
                        {getFieldDecorator('retrieveEmail', {
                            rules: [{ required: true, message: 'メールアドレスを入力してください' },
                                    { type: 'email', message: 'メールアドレスのフォーマットが正しくありません'}]
                        })(<Input className={styles['login-input']}
                            onChange={(e): void => this.changeFormField(e.target.value, 'retrieveForm', 'email')}
                            type='email'
                            placeholder='メールアドレス'
                        />)}
                    </Form.Item>
                    <Form.Item>
                        <Row justify='center' type='flex'>
                            <Col>
                                <Button type='link' className={styles['link-btn']} onClick={(): void => {this.changePage(LoginPage.LOGIN)}}>
                                    戻る
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type='primary'
                                    className={styles['primary-btn']}
                                    htmlType='submit'
                                    disabled={(!this.props.form.isFieldTouched("retrieveEmail") || this.props.form.getFieldError("retrieveEmail") !== undefined)}>
                                    パスワードのリセット
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <div style={{background: 'lightgray'}}>
                { (this.props.userToken) && <Redirect to='/' /> }
                <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
                    <Row type="flex" justify="center">
                        <Col span={50}>
                            <Card className={styles['loginForm']}>
                                <Row type="flex" justify="center">
                                    <img alt='logo' src={require("../../../assets/images/logo_NGF.png")} />
                                </Row>
                                <Row type='flex' justify='center' style={{margin: '10px'}}>
                                    {(this.state.alert.display || this.props.loginError) &&
                                    <div>
                                        {this.state.alert.display && <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                                        {this.props.loginError && this.state.page === LoginPage.LOGIN && <Alert message={this.props.loginError} type={AlertType.ERROR} showIcon />}
                                    </div> }
                                </Row>
                                {this.state.page === LoginPage.LOGIN && this.loginRender()}
                                {this.state.page === LoginPage.RETRIEVE_PASSWORD && this.retrievePasswordRender()}
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    userToken: UsersSelectors.getUserToken(state),
    loginError: UsersSelectors.getUserError(state),
});

const mapDispatchToProps = {
    loginRequest: UsersActions.loginRequest,
}
const createdForm = Form.create()(withRouter(Login));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createdForm);