export default class FieldsHelper {
    static fields = {
        text : {
            id: 3,
            value: 'text',
            icon: 'textbox',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: true,
            useAsSearch: true,
            useFullTextSearch: true,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: true,
            canUseAsReportField: true,
        },
        textarea : {
            id: 12,
            value: 'textarea',
            icon: 'format-align-left',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: true,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        select : {
            id: 9,
            value: 'select',
            icon: 'menu-down',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        radio: {
            id: 7,
            value: 'radio',
            icon: 'radiobox-marked',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        checkbox : {
            id: 8,
            value: 'checkbox',
            icon: 'checkbox-marked-outline',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        autonum: {
            id: 19,
            value: 'autonum',
            icon: 'numeric-1-box-multiple-outline',
            importaddable: true,
            addable: true,
            unique: true,
            useAsTitle: true,
            useAsSearch: true,
            useFullTextSearch: true,
            showInList: true,
            enableUpdate: false,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        number : {
            id: 2,
            value: 'number',
            icon: 'numeric',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        file : {
            id: 13,
            value: 'file',
            icon: 'attachment',
            importaddable: false,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: false,
            useFullTextSearch: true,
            showInList: true,
            enableUpdate: true,
            disableViewModeOnUpdate: true,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: false,
        },
        calc: {
            id: 18,
            value: 'calc',
            icon: 'calculator',
            importaddable: false,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: false,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        user : {
            id: 10,
            value: 'users',
            icon: 'account-outline',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            disableViewModeOnUpdate: true,
            enableMandatory: true,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        dslookup : {
            id: 14,
            value: 'dslookup',
            icon: 'database',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            disableViewModeOnUpdate: true,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        status : {
            id: 1,
            value: 'status',
            icon: 'label-outline',
            importaddable: false,
            addable: false,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: false,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        },
        date: {
            id: 4,
            value: 'date',
            icon: 'calendar',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: true,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            enableMandatory: true,
            canUseAsReportField: true,
        },
        time:{
            id: 18,
            value: 'time',
            icon: 'clock',
            importaddable: true,
            addable: true,
            unique: false,
            useAsTitle: false,
            useAsSearch: false,
            useFullTextSearch: false,
            showInList: true,
            enableUpdate: true,
            disableViewModeOnUpdate: true,
            enableMandatory: false,
            canUseAsUpdateKey: false,
            canUseAsReportField: true,
        }
    }
}