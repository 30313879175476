import { ofType } from "redux-observable";
import { switchMap, catchError, mergeMap, map } from 'rxjs/operators';
import { UsersActions, WorkspaceActions } from "..";
import HttpService from "app/services/httpService/httpService";
import { of, Observable } from "rxjs";
import rootStore from "../rootStore";
import * as apiModels from "./epic.types";
import { User } from "./types";

/**
 * @action HTTP
 * @in [User] loginRequest
 *
 * @param {string} email the mail we would like to use to login
 * @param {string} password the password we would like to use to login
 *
 * @description Request authentification of a user, on success, set the
 * token in store and request loading of the user workspaces
 *
 * @outSuccess [User] setUserAuth
 * @outSuccess [User] loginSuccess
 * @outSuccess [Workspace] getWorkspacesRequest
 *
 * @outFailed [User] loginFailed
 */
const loginEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(UsersActions.loginRequest),
    switchMap((action: { payload: { email: string; password: string } }) =>
        HttpService.PostAsync<apiModels.api_login_request, apiModels.api_login_response>('login', action.payload, HttpService.LinkerAPIBasePath).pipe(
            mergeMap(response => {
                const token = response && response.data && response.data.token ? response.data.token : '';
                return of(
                    UsersActions.setUserAuth({ authKey: token }),
                    UsersActions.loginSuccess(),
                    UsersActions.getCurrentUserRequest(),
                );
            }),
            catchError((error: any) => {
                let message = "メールアドレスまたはパスワードが違います";
                if (error.response.status === 423) {
                    message = "ユーザーはロックされています。5分後ロックアウトが解除されます";
                }
                return of(UsersActions.loginFailed({ error: message }));
            })
        )
    )
);
const getCurrentUserRequestEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(UsersActions.getCurrentUserRequest),
    switchMap(action =>
        HttpService.GetAsync<null, { user: Partial<User> }>('userinfo', null, HttpService.LinkerAPIBasePath).pipe(
            map(response => {
                return UsersActions.getCurrentUserSuccess(response.data);
            }),
            catchError((error) => {
                let errorMsg = error.response.status == 401 ? 'ログインセッションが時間切れになりました。再ログインが必要です。' : `${error.response.status}:${error.response.status}`
                return of(UsersActions.getCurrentUserFailed({errorMsg}));
            })
        )
    )
);

const logoutEpic = (action$, state$) => action$.pipe(
    ofType(UsersActions.logout),
    switchMap(action => {
        rootStore.persistor.purge();
        window.location.href = "/login";
        return of(UsersActions.setUserAuth({authKey: ''}))
    })
);





export default [
    getCurrentUserRequestEpic,
    loginEpic,
    logoutEpic
];