import { GroupState } from "./state";
import { PayloadAction } from "redux-starter-kit";
import { Group } from "./types";

export const setCurrentGroupId = (state: GroupState, action: PayloadAction<{id: string}>): GroupState => {
	return {
		...state,
		current_group_id: action.payload.id
	}
}

export const getGroupsRequest = (state: GroupState): GroupState => {
	return {
		...state,
		loading: true
	}
}

export const getGroupsSuccess = (state: GroupState, action: PayloadAction<{ grouptree: Array<Group> }>): GroupState => {
	return {
		...state,
		grouptree: action.payload.grouptree,
		current_group_id: action.payload.grouptree[0].id,
		loading: false,
		error: ''
	}
}

export const getGroupsFailed = (state: GroupState, action: PayloadAction<{ error: string }>): GroupState => {
	return {
		...state,
		loading: false,
		error: action.payload.error
	}
}