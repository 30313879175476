import { ItemEntry } from "app/services/store/items/types";
import FieldsHelper from "./fieldsHelper";
import { ItemDetailsField } from "app/services/store/itemDetails/types";
import moment from "moment";

export default class ApiHelper {
    // apiFields type ItemDetailsField | DatastoreField
    static buildEntries = (apiItems: Array<any>, apiFields: Array<any>): Array<ItemEntry> => {
        const items = [...apiItems];
        const fields = [...apiFields];
        // the field if is under / fields for items / f_id for itemDetails
        // api is unconsistent...
        const fieldIds = fields.map(a => (a.field || a.f_id));
        const entries = new Array<ItemEntry>();
        items.forEach(item => {
            const itemFields: object = {};
            fieldIds.forEach(key => {
                itemFields[key] = item[key] || '';
                delete item[key];
            })
            entries.push({
                ...item,
                ...{fields: itemFields}
            })
        });
        return entries;
    };

    static getFieldsAsArray = (fields: {[k: string]: any}): Array<{id: string; value: any}> => {
        const fieldsArray = new Array<{id: string; value: any}>();
        for (const key in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, key)) {
                fieldsArray.push({
                    id: key,
                    value: fields[key]
                })
            }
        }
        return fieldsArray;
    }

    // apiFields type ItemDetailsField
    static changeEntryField = (changes: Array<{id: string; value: any}>, entry: ItemEntry, fields: { [k: string]: ItemDetailsField }): {[k: string]: any} => {
        const entryFields: {[k: string]: any} = {};
        for (const key in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, key)) {
                const field = fields[key];
                const change = changes.find(change => change.id === key);
                let value = change ? change.value : entry.fields[key];

                if(field.dataType === FieldsHelper.fields.select.value ||
                    field.dataType === FieldsHelper.fields.radio.value) {
                    if(field.options) {
                        const option = field.options.find(o => o.o_id === value);
                        value = (option ? option.value : value);
                    }
                }

                if(field.dataType === FieldsHelper.fields.date.value) {
                    value = moment(value).format('YYYY/MM/DD');
                }
                entryFields[key] = value;
            }
        }
        return entryFields;
    };
}