import { createSelector } from "reselect";

import { RootState } from "../rootReducer";
import { Datastore } from "./types";
import { DatastoresState } from "./state";

const datastoresState = (state: RootState): DatastoresState => state.datastores;
const getDatastoreList = (state: RootState): Datastore[] => state.datastores.datastores_list;

export const getDatastores = createSelector<RootState, Array<Datastore>, Array<Datastore>>(
  getDatastoreList,
  (datastores: Array<Datastore>) => datastores
);

export const getCurrentDatastoreId = createSelector<RootState, DatastoresState, string>(
  datastoresState,
  (datastoresState: DatastoresState) => datastoresState.current_datastore_id
);