import Axios from  'axios-observable';
import AuthHelper from 'app/services/authHelper/authHelper';
import { ErrorHandlerService } from 'app/services/errorHandler/errorHandler';
import Logger from 'app/utils/logger';

Axios.interceptors.request.use(request => {
    const authKey = AuthHelper.getAuthKey();
    if(authKey)
    {
        Logger.console.log(`[${request.method}] --> ${request.url}`)
        request.headers.common = {...request.headers.common, ...{
            Authorization: `Bearer ${authKey}`,
        }}
    }
    return request;
}, error => {
    Logger.console.log(error)
    return Promise.reject(error)
});

Axios.interceptors.response.use(response => {
    try{
        Logger.console.log(`[response] --> ${response.status}`)
    }
    catch(e){
        Logger.console.log(e)
    }
    return response;
}, error => {
    Logger.console.log(`[error] --> ${error}`)
    if(error && error.response) {
        switch(error.response.status)
        {
            case 201:
            case 200:
                break;
            case 401:
                // getUserInfoのAPIが401→ログアウトでエラー表示が出てしまう
                if(AuthHelper.getAuthKey() !== "") {
                    Logger.console.warn(`[warn] --> ${error}`)
                    AuthHelper.unauthorized();
                }
                break;
            case 409:
                Logger.console.warn(`[warn] --> ${error}`)
                ErrorHandlerService.addError({
                    message: typeof error === 'string' ? error : '他のユーザーによりデータが変更されています。画面を閉じてやり直してください。',
                    time: Date.now(),
                }, true)
                return error.response;
            default:
                ErrorHandlerService.addError({
                    message: typeof error === 'string' ? error : 'ERROR.HTTP.GENERIC',
                    time: Date.now(),
                }, true)
                break;
        }
    }
    throw error;
})