import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const itemDetailsSlice = createSlice({
  slice: "itemDetails",
  initialState: initialState,
  reducers: {
    getItemDetailsRequest: reducers.getItemDetailsRequest,
    getItemDetailsSuccess: reducers.getItemDetailsSuccess,
    getItemDetailsFailed: reducers.getItemDetailsFailed,
    selectAction: reducers.selectAction,
    getActionSettingsRequest: reducers.getActionSettingsRequest,
    getActionSettingsSuccess: reducers.getActionSettingsSuccess,
    getActionSettingsFailed: reducers.getActionSettingsFailed,
    updateItemRequest: reducers.updateItemRequest,
    updateItemSuccess: reducers.updateItemSuccess,
    updateItemFailed: reducers.updateItemFailed,
    newItemRequest: reducers.newItemRequest,
    newItemSuccess: reducers.newItemSuccess,
    newItemFailed: reducers.newItemFailed,
    setRevisionNumber: reducers.setRevisionNumber,
    newItemModeRequest: reducers.newItemModeRequest,
    newItemModeSuccess: reducers.newItemModeSuccess,
    newItemModeFailed: reducers.newItemModeFailed,
    setMode: reducers.setMode,
    reset: reducers.reset
  }
});

export const {
  getItemDetailsRequest,
  getItemDetailsSuccess,
  getItemDetailsFailed,
  selectAction,
  getActionSettingsRequest,
  getActionSettingsSuccess,
  getActionSettingsFailed,
  updateItemRequest,
  updateItemSuccess,
  updateItemFailed,
  newItemRequest,
  newItemSuccess,
  newItemFailed,
  setRevisionNumber,
  newItemModeRequest,
  newItemModeSuccess,
  newItemModeFailed,
  setMode,
  reset
} = itemDetailsSlice.actions;
