import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { Layout, Button, Table, Form, Input, Icon, Tag, Popconfirm, message } from 'antd';
import LeftMenu from './leftMenu';
import { FormComponentProps } from 'antd/lib/form/Form';
import HttpService  from 'app/services/httpService/httpService'
import Logger from 'app/utils/logger';
import { WorkspaceSelectors, ProjectSelectors, GroupSelectors, GroupActions, UsersSelectors } from 'app/services/store';
import styles from '../settings.module.css';
import { Group } from 'app/services/store/group/types';
import { withTranslation, WithTranslation } from 'react-i18next';

interface MembersProps extends FormComponentProps, WithTranslation {
    currentWorkspaceId: string;
    currentProjectId: string;
    grouptree: Array<Group>;
    currentGroupId: string;
    currentGroup: any;
    currentUserId: string;
    getGroupsRequest: Function;
}

interface MembersState {
    loading: boolean;
    members: Array<any>;
    newUser: string;
    selectedMembers: Array<any>;
    selectedRowKeys: Array<any>;
}

class Members extends React.Component<MembersProps> {

    state: MembersState = {
        loading: true,
        members: [],
        newUser: '',
        selectedMembers: [],
        selectedRowKeys: [],
    };

    columns = [{
            title: 'ユーザー名',
            dataIndex: 'username',
            key: 'username',
        }, {
            title: 'メールアドレス',
            dataIndex: 'email',
            key: 'email',
            render: (email): JSX.Element => (
                <span style={{ color: '#999999' }}>
                    {email}
                </span>)
        }, {
            title: 'ステータス',
            dataIndex: 'status',
            key: 'status',
            render: (text, record): JSX.Element =>
                <span>
                    {(!record.confirmed && !record.email_sent) && <Tag color='red' className={styles['table-tag']}>招待メール未送信</Tag>}
                    {(!record.confirmed && record.email_sent) && <Tag color='gold' className={styles['table-tag']}>招待中・参加待ち</Tag>}
                    {(record.confirmed) && <Tag color='cyan' className={styles['table-tag']}>参加中</Tag>}
                </span>
        }, {
            title: '削除',
            dataIndex: 'operation',
            render: (text, record) =>
                <Popconfirm
                    title='ユーザーを削除してもよろしいですか？'
                    okText='削除する'
                    cancelText='キャンセル'
                    onConfirm={() => this.removeUser(record)}
                    >
                    <Icon type='close-circle' />
                </Popconfirm>
        },
    ];

    componentDidMount(): void {
        this.props.getGroupsRequest();
        if (this.props.currentGroupId){
            this.getMembers(this.props.currentGroupId);
        }
    }

    componentDidUpdate(prevProps): void {
        if (this.props.currentGroupId !== prevProps.currentGroupId) {
            this.getMembers(this.props.currentGroupId);
        }
    }

    // get all members
    getMembers(groupId: string): void {
        let members = [];
        HttpService.GetAsync<any, any>(`groups/${groupId}/users`, null,
            HttpService.LinkerAPIBasePath).subscribe((res) => {
                if (res.data && Array.isArray(res.data.members)) {
                    members = res.data.members.filter((thing, index, self) =>
                        index === self.findIndex((t) => (
                            t.u_id === thing.u_id
                        ))
                    )
                }
                this.setState({
                    members,
                    loading: false
                });
            },
            err => {
                Logger.console.log(`ERROR : groups / ${ groupId } / users`, err);
                this.setState({
                    loading: false
                });
            })
    }

    addUser(): void {
        HttpService.PostAsync<any, any>(`users`, {
            'email': this.state.newUser, //必須
            'g_id': this.props.currentGroupId, //必須
            'w_id': this.props.currentWorkspaceId,
            'username': this.state.newUser.slice(0, this.state.newUser.indexOf('@'))
        }, HttpService.LinkerAPIBasePath).subscribe((res) => {
            const members = [...this.state.members];
            if (res.data && !res.data.exists) {
                members.unshift(res.data.user_profile);

                // 最上部の組織に追加した場合'rinTrust'ロールを付与
                if (this.props.grouptree[0].id === this.props.currentGroup.id) {
                    HttpService.PostAsync<any, any>(`applications/${this.props.currentProjectId}/userroles`, {
                        'user_id': res.data.user_profile.u_id,
                        'role_id': 'rinTrust'
                    }, HttpService.LinkerAPIBasePath).subscribe((res) => {}, err => {
                        Logger.console.log(`ERROR : applications/${this.props.currentProjectId}/userroles`, err);
                    })
                }
            }
            this.setState({
                members,
                newUser: '',
                loading: false
            });

            this.props.form.resetFields();

        }, err => {
            Logger.console.log(`ERROR : add_user`, err);
            this.setState({ loading: false });
        })
    }

    inviteUser(): void {
        const inviteUsers = (
            this.state.selectedMembers.map(user => {
                return { email: user['email'] };
            })
        )
        HttpService.PostAsync<any, any>(`userinvite`, {
            users: inviteUsers,
            domain: window.location.host,
            invitation_path: 'confirm_invite',
        }, HttpService.LinkerAPIBasePath).subscribe((res) => {
            this.state.members.forEach(member => {
                res.data.forEach(data => {
                    if (member.email === data.email) {
                        member.email_sent = true;
                    }
                })
            })

            this.setState({
                selectedMembers: [],
                selectedRowKeys: [],
                loading: false
            });
            message.success('招待メールが送信されました。')
        }, err => {
            Logger.console.log(`ERROR : invite_user`, err);
            this.setState({ loading: false });
        })
    }

    removeUser(record): void {
        HttpService.DeleteAsync<any, any>(`users`, {
            g_id: this.props.currentGroupId,
            u_id: record.u_id,
            w_id: this.props.currentWorkspaceId,
        }, HttpService.LinkerAPIBasePath).subscribe(() => {
            const members = [...this.state.members];
            this.setState({
                members: members.filter(member => member['u_id'] !== record.u_id),
                loading: false,
            })
        }, err => {
            Logger.console.log('ERROR : delete_user', err);
            this.setState({
                loading: false
            })
        })
    }

    render(): JSX.Element {
        const { Header, Content, Sider, Footer } = Layout;
        const { getFieldDecorator } = this.props.form;
        const { selectedRowKeys } = this.state;

        return (
            <div>
                <Layout className={styles['members-container']}>
                    <Header>ユーザー一覧</Header>
                    <Layout>
                        <Sider width={240} style={{ background: '#fff' }}>
                            <LeftMenu />
                        </Sider>
                        <Layout className={styles['members-content']} style={{ padding: '0 24px 24px' }}>
                        {this.props.currentGroup &&
                            <div className={styles['members-content-header']}>
                                {this.props.currentGroup.name}のユーザー</div>}
                            <div className={styles['members-content-add']}>
                                <p>ユーザーを追加</p>
                                <Form layout='inline' hideRequiredMark={true}>
                                    <Form.Item>
                                        {getFieldDecorator('email', {
                                            rules: [{ type: 'email', message: '有効なメールアドレスではありません' }]
                                        })(<Input
                                            prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder='招待するユーザーのメールアドレスを入力'
                                            type='email'
                                            onChange={(e): void => this.setState({ newUser: e.target.value })}
                                            className={styles['input-box']}
                                        />)}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className={styles['members-content-button']}
                                            onClick={(): void => this.addUser()}
                                            disabled={
                                                !this.props.form.isFieldTouched('email') ||
                                                this.props.form.getFieldError('email') !== undefined ||
                                                this.state.newUser.length <= 0 ||
                                                !this.props.currentGroupId
                                            }
                                        >追加</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <Content className={styles['members-content-table']}>
                                <Table
                                    dataSource={this.state.members}
                                    columns={this.columns}
                                    rowSelection={{
                                        columnTitle: '選択',
                                        selectedRowKeys,
                                        getCheckboxProps: record => ({
                                            disabled: record.confirmed,
                                        }),
                                        onChange: (selectedRowKeys, selectedMembers) => (this.setState({ selectedRowKeys, selectedMembers })),
                                    }}
                                    rowKey={'u_id'}
                                    pagination={{ pageSize: 10 }}
                                    size='small'
                                    loading={this.state.loading}
                                />
                                <Footer className={styles['members-content-footer']}>
                                    <Button
                                        style={{ margin: '0 auto' }}
                                        className={styles['members-content-button']}
                                        onClick={(): void => this.inviteUser()}
                                        disabled={this.state.selectedMembers.length <= 0}
                                    >
                                        <span style={{ fontSize: 11 }}>選択したユーザーに</span>
                                        招待メールを送信
                                    </Button>
                                </Footer>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    currentWorkspaceId: WorkspaceSelectors.getCurrentWorkspaceId(state),
    currentProjectId: ProjectSelectors.getCurrentProjectId(state),
    grouptree: GroupSelectors.getGrouptree(state),
    currentGroupId: GroupSelectors.getCurrentGroupId(state),
    currentGroup: GroupSelectors.getCurrentGroup(state),
    currentUserId: UsersSelectors.getUserId(state)
});

const mapDispatchToProps = {
    getGroupsRequest: GroupActions.getGroupsRequest
}

const createdForm = Form.create()(Members);


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createdForm);