import { createSelector } from "reselect";
import { RootState } from "../rootReducer";
import { WorkspaceState } from "./state";
import { Workspace } from "./types";

const workspacesState = (state: RootState): WorkspaceState => state.workspaces

export const getCurrentWorkspace = createSelector<RootState, WorkspaceState, Workspace | undefined>(
    workspacesState,
    (state: WorkspaceState) => {
        if(state.current_workspace_id) {
            return state.workspaces_list.find(ws =>  ws.w_id === state.current_workspace_id)
        } else {
            return undefined;
        }
    }
);

export const getWorkspaceIsLoading = createSelector<RootState, WorkspaceState, boolean>(
    workspacesState,
    (state: WorkspaceState) => {
        return state.loading;
    }
);

export const getCurrentWorkspaceId = createSelector<RootState, WorkspaceState, string>(
    workspacesState,
    (state: WorkspaceState) => {
        return state.current_workspace_id;
    }
);

export const getWorkspaceList = createSelector<RootState, WorkspaceState, Array<Workspace>>(
    workspacesState,
    (state: WorkspaceState) => {
        return state.workspaces_list || []
    }
);