import { createSelector } from "reselect";

import { RootState } from "../rootReducer";
import { Project } from "./types";
import { ProjectState } from "./state";
import { Datastore } from "../datastore/types";

const projectState = (state: RootState): ProjectState => state.projects;
const projectList = (state: RootState): Array<Project> => state.projects.project_list;
const projectCurrentId = (state: RootState): string => state.projects.current_project_id;

export const getProjectList = createSelector<RootState, Array<Project>, Array<Project>>(
    projectList,
    (projectList: Array<Project>) => projectList
);

export const getProjectIsLoading = createSelector<RootState, ProjectState, boolean>(
    projectState,
    (projectId: ProjectState) => projectId.loading
);

export const getCurrentProjectId = createSelector<RootState, string, string>(
    projectCurrentId,
    (projectId: string) => projectId
);

export const getCurrentProject = createSelector<RootState, Array<Project>, string, Project | undefined>(
    projectList,
    projectCurrentId,
    (projects: Array<Project>, projectId: string) => projects.find(pj => pj.application_id === projectId)
);

export const getCurrentProjectDatastores = createSelector<RootState, Project | undefined, Array<Datastore>>(
    getCurrentProject,
    (project: Project | undefined) => {
        return project ? project.datastores : []
    }
);


