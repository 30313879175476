import { createSelector } from "reselect";
import { RootState } from "../rootReducer";
import { ItemDetailsState } from "./state";
import memoize from 'lodash.memoize';
import {
    ItemDetailsField,
    ItemDetailsTitle,
    ItemDetailsAction,
    ItemDetailsCurrentAction,
    ItemDetailMode
} from "./types";
import { ItemEntry } from "../items/types";

const itemDetailsState = (state: RootState): ItemDetailsState => state.itemDetails;
const itemDetailsEntry = (state: RootState): ItemEntry => state.itemDetails.entry || {};
const itemDetailsTitles = (state: RootState): Array<ItemDetailsTitle> => state.itemDetails.titles || [];

export const getIsLoading = createSelector<RootState, ItemDetailsState, boolean>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.loading
);

export const getStatuses = createSelector<RootState, ItemDetailsState, any>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.statuses
);

export const getEntry = createSelector<RootState, ItemDetailsState, ItemEntry>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry
);

export const getHistory = createSelector<RootState, ItemDetailsState, any>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.post
);

export const getRevisionNumber = createSelector<RootState, ItemDetailsState, number>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.rev_no
);

export const getFields = createSelector<RootState, ItemDetailsState, {[k: string]: ItemDetailsField}> (
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.fields
);

export const getActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.actions
);

export const getStateActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.statusActions || []
);

export const getCurrentAction = createSelector<RootState, ItemDetailsState, ItemDetailsCurrentAction | undefined>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.currentAction
);

export const getEntryId = createSelector<RootState, ItemDetailsState, string>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.i_id
);

export const getFieldById = createSelector(
    itemDetailsState,
    itemDetailsState => memoize(
        f_id => itemDetailsState.fields[f_id] as ItemDetailsField
    )
)

export const getEntryValueById = createSelector(
    itemDetailsState,
    itemDetailsState => memoize<any>(
        f_id => itemDetailsState.entry.fields[f_id]
    )
)

export const getActionById = createSelector(
    itemDetailsState,
    itemDetailsState => memoize<ItemDetailsAction>(
        a_id => itemDetailsState.actions.find(a => a.a_id === a_id)
    )
)

export const getDisplayMode = createSelector<RootState, ItemDetailsState, ItemDetailMode>(
    itemDetailsState,
    (itemsDetailsState: ItemDetailsState) => itemsDetailsState.mode
)

export const getItemsTitlesIds = createSelector<RootState, ItemDetailsState, Array<ItemDetailsTitle>, ItemEntry, Array<string>>(
    itemDetailsState,
    itemDetailsTitles,
    itemDetailsEntry,
    (itemDetailsState: ItemDetailsState, itemDetailsTitle: Array<ItemDetailsTitle>, itemEntry: ItemEntry) => {
        const titles = Array<string>();
        itemDetailsTitle.forEach(
            (title) => {
                titles.push(itemEntry.fields[title.columnID])
            }
        );
        return titles;
    }
);

export default [
    getIsLoading,
    getEntry,
    getFields,
    getActions,
    getStateActions,
    getFieldById,
    getCurrentAction,
    getEntryValueById,
    getDisplayMode,
    getItemsTitlesIds
]