import React from 'react'
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { FileFields } from 'app/services/store/itemDetails/types';
import Field, { FieldProps, FieldState, FieldMode } from './field';
import { Upload, Button, Icon, Spin  } from 'antd';
import { UploadFile, RcFile, UploadChangeParam  } from 'antd/lib/upload/interface';
import { FileHelper, FileType  } from 'app/utils/fileHelper';
import StringUtils from 'app/utils/stringUtils';
import { DatastoreSelector, ProjectSelectors, ItemsSelectors } from 'app/services/store';
import Logger from 'app/utils/logger';
import { Observable, concat } from 'rxjs';
import { map } from 'rxjs/operators';
import HttpService from 'app/services/httpService/httpService';
import AuthHelper from 'app/services/authHelper/authHelper';

// TODO IN BACK END
// UPLOADING A FILE TO A DATASTORE SHOULDN'T REQUIRE
// DATASTORE ID / PROJECT ID / FILE UPLOAD PATH...
// IT SHOULD JUST REQUIRE THE ITEM ID AND THE FILE.

export interface FileFieldProps extends FieldProps {
    value: Array<FileFields>;
    datastoreId?: string;
    projectId?: string;
    itemId?: string;
}

export interface FileFieldState extends FieldState {
    value: Array<string>;
    files: Array<UploadFile>;
    loading: boolean;
    showImage: boolean;
}

class FileField extends Field<FileFieldProps> {
    state: FileFieldState  = {
        ...this.state,
        renders : {
            ...this.state.renders,
            [FieldMode.DISPLAY] : this.displayMode,
            [FieldMode.EDIT] : this.editMode,
            [FieldMode.DISABLED] : this.disabledMode,
            [FieldMode.MANDATORY] : this.editMode,
        },
        value: new Array<string>(),
        files: new Array<UploadFile>(),
        loading: false,
        showImage: false
    };

    componentDidMount(): void {
        super.componentDidMount();
        const showImage = this.props.field.file_info ? this.props.field.file_info.show_img : false;
        const propValues = this.props.value || [];
        if(showImage) {
            this.setState({loading: true})
            this.getFilesAsync(propValues).subscribe(files => {
                this.setState({
                    showImage,
                    value: propValues.map(a => a.file_id),
                    files: files,
                    loading: false
                });
            });
        } else {
            this.setState({
                showImage,
                value: propValues.map(a => a.file_id),
                files: this.getFiles(propValues)
            });
        }
    }

    downloadFile(fileId: string, type: string): void {
        FileHelper.downloadFile(fileId).subscribe((respData) => {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(new Blob([respData.data], {type}));
            const filename = decodeURI(respData.headers.filename);
            a.download = filename || StringUtils.GenUUID();
            a.click();
        })
    }

    removeFile(fileId: string): void {
        FileHelper.deleteStoredFile({file_id: fileId}).subscribe(() => {
            const files = this.state.files.filter(file => file.uid !== fileId);
            const values = this.state.value.filter(e => e !== fileId);
            this.setState({
                files: files,
                value: values
            });
        })
    }

    uploadingStateChange(e: UploadChangeParam<UploadFile>): void {
        let values = this.state.value;
        if(e.file.status === 'done') {
            values = [...values, ...[e.file.response.file_id]];
        }
        this.setState({
            files: e.fileList,
            value : values
        });
    }

    getFilesAsync(fieldFiles: Array<FileFields>): Observable<Array<UploadFile>> {
        return new Observable<Array<UploadFile>>((obs): void => {
            const files = Array<UploadFile>();
            const downloadsObs = Array<Observable<{fileId: string; response: any}>>();
            fieldFiles.forEach(file => {
                downloadsObs.push(FileHelper.downloadFile(file.file_id).pipe(
                        map((resp) => ({ fileId: file.file_id, response: resp}))
                    )
                );
            });

            concat(...downloadsObs).subscribe({
                complete: () => {
                    obs.next(files);
                    obs.complete();
                },
                next: file => {
                    const field = fieldFiles.find(field => field.file_id === file.fileId);
                    if(!field){ return; }
                    const fileType = FileHelper.getFileType(field);

                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    let preview = require('../../../assets/images/file.png');

                    if(fileType === FileType.IMAGE){
                        if(file.response && file.response.data){
                            preview = window.URL.createObjectURL(new Blob([file.response.data], {type: field.contentType}));
                        }
                    }

                    files.push({
                        uid: field.file_id,
                        name: field.filename,
                        url: preview,
                        size: field.size,
                        type: field.contentType,
                    })
                },
                error: (e) => {
                    console.log('error',e)
                },
            })
        });
    }

    getFiles(fieldFiles: Array<FileFields>): Array<UploadFile> {
        const files = Array<UploadFile>();
        fieldFiles.forEach(file => {
            files.push({
                uid: file.file_id,
                name: file.filename,
                url: require('../../../assets/images/file.png'),
                size: file.size,
                type: file.contentType,
            })
        });
        return files;
    }

    

    


    getParams(file: RcFile, nbFile: number): object {
        return {
            filename: file.name,
            filepath: `${this.props.datastoreId}/${this.props.itemId}/${this.props.fieldId}/${file.name}`,
            field_id: this.props.fieldId,
            item_id: this.props.itemId || '',
            d_id: this.props.datastoreId || '',
            p_id: this.props.projectId || '',
            display_order: nbFile
        }

    }

    editMode(): JSX.Element {
        return (
            <Upload
                listType={this.state.showImage ? "picture" : "text"}
                fileList={this.state.files}
                action={HttpService.getBaseUrl(`new_item_file_attachment`)}
                onRemove={(file: UploadFile): void => this.removeFile(file.uid)}
                headers={{"Authorization": `Bearer ${AuthHelper.getAuthKey()}`}}
                onChange={(info: UploadChangeParam<UploadFile>): void => {this.uploadingStateChange(info)}}
                data={(file: RcFile): object => this.getParams(file, this.state.files.length)}>
                <Button>
                    <Icon type="upload" />
                </Button>
            </Upload>
        )
    }

    displayMode(): JSX.Element {
        return (
            <div>
                {this.state.loading && <Spin />}
                <Upload
                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: false }}
                    listType={this.state.showImage ? "picture" : "text"}
                    fileList={this.state.files}
                    onPreview={(f): void => {this.downloadFile(f.uid, f.type)}}>
                </Upload>
            </div>
        )
    }

    disabledMode(): JSX.Element {
        return (
            <Upload
                listType={this.state.showImage ? "picture" : "text"}
                disabled={true}>
                <Button>
                    <Icon type="upload" />
                </Button>
            </Upload>
        )
    }

    render(): JSX.Element {
        return super.render();
    }
}

const mapStateToProps = (state: RootState): {} => ({
    datastoreId: DatastoreSelector.getCurrentDatastoreId(state),
    projectId: ProjectSelectors.getCurrentProjectId(state),
    itemId: ItemsSelectors.getCurrentItemId(state)
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileField);