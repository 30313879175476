import { Workspace } from "./types";

export interface WorkspaceState {
    workspaces_list: Array<Workspace>;
    current_workspace_id: string;
    loading: boolean;
    error: string;
}

export const initialState: WorkspaceState = {
    workspaces_list: new Array<Workspace>(),
    current_workspace_id: '',
    loading: false,
    error: ''
};