import { Group } from "./types";

export interface GroupState {
    grouptree: Array<Group>;
    current_group_id: string;
    loading: boolean;
    error: string;
}

export const initialState: GroupState = {
    grouptree: new Array<Group>(),
    current_group_id: '',
    loading: false,
    error: ''
}