import { createSelector } from "reselect";
import { RootState } from "../rootReducer";
import { UserState } from "./state";

const userState = (state: RootState): UserState => state.user

export const getUserToken = createSelector<RootState, UserState, string>(
    userState,
    (state: UserState) => state.auth_key
);

export const getUserId = createSelector<RootState, UserState, string>(
    userState,
    (state: UserState) => state.u_id
);

export const getUsername = createSelector<RootState, UserState, string>(
    userState,
    (state: UserState) => state.username
);

export const getUserInfo = createSelector<RootState, UserState, UserState>(
    userState,
    (state: UserState) => state
);

export const getUserLoading = createSelector<RootState, UserState, boolean>(
    userState,
    (state: UserState) => state.loading
);

export const getUserEmail = createSelector<RootState, UserState, string>(
    userState,
    (state: UserState) => state.email
);

export const getUserError = createSelector<RootState, UserState, string>(
    userState,
    (state: UserState) => state.error
);
