import React from 'react';
import { Menu, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { ProjectSelectors, ProjectActions } from 'app/services/store';
import { RootState } from 'app/services/store/rootReducer';
import { useTranslation } from 'react-i18next';
import { PickerType } from '../home/home';
import { Project } from 'app/services/store/project/types';
import { Spin } from 'antd';

const {Option} = Select;

interface ProjectMenuProps {
    pickerType: PickerType;
    onChange?: Function;
}

const ProjectMenu = (props: ProjectMenuProps): JSX.Element => {
    const projects = useSelector((state: RootState) => ProjectSelectors.getProjectList(state));
    const currentProjectId = useSelector((state: RootState) => ProjectSelectors.getCurrentProjectId(state));
    const loading = useSelector((state: RootState) => ProjectSelectors.getProjectIsLoading(state));
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onChange = (projectId: string): void => {
        if(props.onChange) props.onChange(projectId);
        dispatch(ProjectActions.setCurrentProjectId({projectId}));
    }

    const headerPicker = (): JSX.Element => {
        return (
            <div>
                {loading && <Spin />}
                <Menu mode="horizontal"
                    forceSubMenuRender={true}
                    defaultSelectedKeys={[currentProjectId]}>
                    {
                        projects.map(app =>
                        {
                            return  <Menu.Item
                                        key={app.application_id}
                                        disabled={loading}
                                        onClick={(): any => onChange(app.application_id)}>
                                        { t(`PROJECT.${app.name}`, app.name) }
                                    </Menu.Item>
                        })
                    }
                </Menu>
            </div>
        )
    }

    const popupPicker = (): JSX.Element => {
        return (
            <div>
                {loading ? <Spin /> :
                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px' }}>
                        <Select
                            size="large"
                            style={{ width: '100%' }}
                            onChange={(application_id: string): any => onChange(application_id)}
                            value={ currentProjectId }>
                            {
                                projects.map((value: Project) =>
                                {
                                    return <Option value={value.application_id} key={value.application_id}> {value.name}</Option>
                                })
                            }
                        </Select>
                    </Menu>
                }
            </div>
        )
    }

    return (
        <div>
            {props.pickerType === PickerType.HEADER ? headerPicker() : popupPicker()}
        </div>
    )
}

export default ProjectMenu;