import React from 'react'
import { Layout, Row, Col, Drawer, Spin, Input, Button, DatePicker, Select, Upload, Icon, Radio, Checkbox, message } from 'antd';
import AuthHelper from 'app/services/authHelper/authHelper';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { ItemDetailsSelectors, ItemsSelectors, ItemsDetailsActions, ProjectSelectors, DatastoreSelector, ItemsActions, WorkspaceSelectors } from 'app/services/store';
import { ItemEntry, DatastoreColumns } from 'app/services/store/items/types';
import { ItemDetailMode, ItemDetailsCurrentAction } from 'app/services/store/itemDetails/types';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './itemDetails.module.css';
import { FieldMode } from '../fields/field';
import ActionHelper from 'app/utils/actionHelper';
import ItemDetailsSide from './itemDetailsSide/itemDetailsSide'
import moment from 'moment';
import HttpService from 'app/services/httpService/httpService'
import DeleteConfirm from './deleteConfirm/deleteConfirm'
import { UploadFile, RcFile, UploadChangeParam  } from 'antd/lib/upload/interface';
import  FieldsHelper  from 'app/utils/fieldsHelper';
import FileField from 'app/components/fields/fileField'
import {Redirect} from 'react-router-dom'
import StringUtils from 'app/utils/stringUtils';
import { FileHelper, FileType } from 'app/utils/fileHelper';
import { uploadLimit } from 'app/constants';

const { Option } = Select;
const { TextArea } = Input;

interface ItemDetailsStateProps {
    mode: ItemDetailMode;
    loading: boolean;
    titlesIds: Array<string>;
    columns: DatastoreColumns;
    entry: ItemEntry;
    currentAction?: ItemDetailsCurrentAction;
    currentItem: any;
    fields: any;
    currentItemID: any;
    currentProjectID: any;
    currentDatastoreID: any;
    itemStatuses: any;
    workspaceID: any;
}

interface ItemDetailsDispatchProps {
    setMode: Function;
    updateItemRequest: Function;
    newItemRequest: Function;
    deleteItem: Function;
    deleteItemRequest: Function;
    closeDetails: Function;
    updateEntry: Function;
    setCurrentID: Function;
}

interface ItemDetailsProps extends WithTranslation, ItemDetailsStateProps, ItemDetailsDispatchProps {
    setURLstate: Function;
    isAdmin: boolean;}

class ItemDetails extends React.Component<ItemDetailsProps> {
    state = {
        mode: ItemDetailMode.CLOSE,
        collapsed: true,
        edit: false,
        showDeleteModal: false,
        visible: false,
        deleting: false,
        renders : {
            [FieldsHelper.fields.file.value] : FileField,},
        value: new Array<string>(),
        value2: new Array<string>(),
        files: new Array<UploadFile>(),
        files2: new Array<UploadFile>(),
        currentAID: '',
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed: collapsed });
        };

    fieldSnapshot: {[k: string]: any} = {};

    isActionMode = (): boolean => {
        return this.props.mode === ItemDetailMode.UPDATE ||
                this.props.mode === ItemDetailMode.COPY ||
                this.props.mode === ItemDetailMode.NEW;
    }

    cancel = (): void => {
        switch(this.props.mode) {
            case ItemDetailMode.UPDATE:
                this.props.setMode({mode: ItemDetailMode.DISPLAY});
                break;
            case ItemDetailMode.COPY:
            case ItemDetailMode.NEW:
                this.props.setMode({mode: ItemDetailMode.CLOSE});
            break;
        }
    }

    save = (): void => {
        const currenctAction = this.props.currentAction;
        if(!currenctAction) { return this.props.setMode({mode: ItemDetailMode.CLOSE}); }

        switch(this.props.mode){
            case ItemDetailMode.UPDATE:
                this.props.updateItemRequest({
                    actionId: currenctAction.a_id,
                    changes: ActionHelper.findChanges(this.props.entry.fields, this.fieldSnapshot)
                })
                break;
            case ItemDetailMode.COPY:
            case ItemDetailMode.NEW:
                this.props.newItemRequest({
                    actionId: currenctAction.a_id,
                    fields: this.fieldSnapshot
                });
            break;
        }
    }

    getFieldMode(fieldId: string): FieldMode {
        const currenctAction = this.props.currentAction;
        let fieldMode = FieldMode.DISPLAY;
        if(this.isActionMode() && currenctAction){
            fieldMode = FieldMode.EDIT;
            if(!currenctAction.action_field_settings[fieldId].update) { fieldMode = FieldMode.DISABLED; }
            if(!currenctAction.action_field_settings[fieldId].show) { fieldMode = FieldMode.HIDDEN; }
            if(currenctAction.action_field_settings[fieldId].manadatory) { fieldMode = FieldMode.MANDATORY; }
        }
        return fieldMode
    }

    componentDidUpdate(): void {
        if(this.state.mode !== this.props.mode) {
            this.enterMode(this.props.mode);
        }
    }

    fieldUpdate(fieldId: string, value: string): void {
        this.fieldSnapshot = {
            ...this.fieldSnapshot,
            [fieldId] : value
        }
    }

    enterMode(mode: ItemDetailMode): void {
        switch(mode){
            case ItemDetailMode.CLOSE:
                this.reset();
                break;
            case ItemDetailMode.NEW:
                this.reset();
                break;
        }

        this.setState({
            mode
        });
    }

    onClose(e: any): void{
        this.cancelEdit()


        this.setState({
            action_fields_setting: null
        })
        this.props.setMode({mode: ItemDetailMode.CLOSE});

        // this.redirect()


        this.props.setCurrentID({itemId: ''})

        // let url = `/${this.props.workspaceID}/${this.props.currentProjectID}/${this.props.currentDatastoreID}`
        // this.props.setURLstate(url)


    }

    redirect(){
        let url = `/${this.props.workspaceID}/${this.props.currentProjectID}/${this.props.currentDatastoreID}`
        return <Redirect to={url} />
    }

    reset(): void {
        this.fieldSnapshot = {};
    }

    consoles(){
    }

    getEntryFieldValueByFieldID(keyToFind) {
        let keys = Object.keys(this.props.entry.fields)
        for(let key of keys){
            if(keyToFind === key){
                return this.props.entry.fields[key]
            }
        }
    }

    getFieldbyFieldDisplayID(FieldDisplayID){
        for(let field of this.props.fields){
            if(field.displayID === FieldDisplayID){
                return field
            }
        }
    }

    getFormType() {
        let foundField = this.getFieldbyFieldDisplayID('種別')
        if(!foundField){return}
        let formTypeID = foundField.field
        let optionKey = this.getEntryFieldValueByFieldID(formTypeID)
        for(let option of foundField.options){
            if(option.o_id === optionKey){
                return option.value
            }
        }
    }

    getSortedStatusOrder() {
        let sortedStatuses = [] as any;
        let statusKeys = Object.keys(this.props.itemStatuses.statusOrderSettings)
        for(let status of statusKeys){
            sortedStatuses.push(this.props.itemStatuses.statusOrderSettings[status])
        }
        sortedStatuses.sort((a, b)=>{return a.sort_id - b.sort_id})
       return sortedStatuses
    }

    getStatuses() {
        let arr = [] as any;
        let sortedStats = this.getSortedStatusOrder()
        let statuses = this.props.itemStatuses.statuses
        for(let sortedStat of sortedStats) {
            for(let stat of statuses){
                if(stat.data.id === sortedStat.id){
                    arr.push(stat)
                }
            }
        }
        return arr
    }

    getStatusClass(color){
        const statusArrow = {
            display: 'block',
            float: 'left',
            width: '100px',
            height: '50px',
            background: `${color}`,
            textAlign: 'center',
            position: 'relative',
            margin: '0 25px 0 2px',
            fontSize: '10px',
            textDecoration: 'none',
            color: '#fff'
            }
            return statusArrow
    }

    isCurrentStatus(currentSID, checkingSID) {
        return checkingSID === currentSID
    }

    renderStatuses(SID?){
        let currentSID = this.state['SID'] ? this.state['SID'] : this.props.currentItem.status_id
        let statuses = this.getStatuses()
        return statuses.map((data, index) => {
            var style = { "--border-color": (this.isCurrentStatus(currentSID,data.data.s_id) && data.data.color) ? data.data.color : 'lightgrey', opacity: this.isCurrentStatus(currentSID,data.data.s_id) ? 1 : .8} as React.CSSProperties;
            return (
                <div style={style} key={data.data.s_id} className={index === 0 ? styles['status-arrow-first'] : styles['status-arrow']}>{data.data.name}</div>
            )
          })
    }

    individualFields = ['調査対象', 'フリガナ', '生年月日', '住所', '屋号', 'その他', '電話番号' ] //'電話番号1', '電話番号2', '電話番号3'
    companyFields = [ '調査対象', '住所', '代表者名', '会社HP', 'その他', '電話番号'] //'電話番号1', '電話番号2', '電話番号3'
    重要契約Fields = [ '調査対象', '住所', '代表者名', '会社HP', 'その他', '電話番号']
    secondList = ['氏名', '発注者メールアドレス', '所属会社', '所属部門','発注者上長氏名','上長メールアドレス', '発注者電話番号']
    thirdList = ['取引内容', '請求先', '請求先部門コード','file', '審査対象関連資料', '事前承認事項'] //重要契約 has unique 承諾事項
    fourthList = ['担当者', '納品予定日', '反社会性審査結果', '与信審査結果', 'file2']

    getKeys(section){
        let formType = this.getFormType()
        switch (section) {
            case 1:
                if (formType === '個人') {return this.individualFields}
                if (formType === '法人') {return this.companyFields}
                if (formType === '重要契約') {return this.重要契約Fields}
                return []
            case 2:
                return this.secondList
            case 3:
                return this.thirdList
            case 4:
                return this.fourthList
        }
        return []
    }

    getForm(section){
        let keys = this.getKeys(section)

        return keys.map((key, index) =>{
                let field = this.getFieldbyFieldDisplayID(key)
                if(field === undefined){return}
                return (this.getInput(field, field.dataType))
            })

    }

    getFieldOptions(displayID){
        for(let field of this.props.fields){
            if(field.displayID === displayID){
                if(field.dataType === 'select'){
                    return field.options
                } else if (field.dataType === 'status'){
                    return field.statuses
                }
            }
        }
    }

    getOptionNameByKey(displayID, optionID){
        for(let field of this.props.fields){
            if(field.displayID === displayID){
                for(let option of field.options){
                    if(option.o_id === optionID){
                        return option.value
                    }
                }
            }
        }
    }

    async beforeUploading(file, fileList) {
        if(file.size < uploadLimit){
            if(!this.state['fileSelected']){
                this.setState({
                    itemID: this.props.currentItemID,
                    fileSelected: true
                })
            }
        } else {
            message.error('ファイルサイズが大きすぎます。10MB以下にしてください。')
        }
    }

    // handleUploadChange(info) {
    //     console.log("HANDLE CHANGE", info)
    //     let file = [...info.fileList];

    //     // 1. Limit the number of uploaded files
    //     // Only to show two recent uploaded files, and old ones will be replaced by the new
    //     file = file.slice(-1);

    //     // 2. Read from response and show file link
    //     file = file.map(file => {
    //       if (file.response) {
    //         // Component will show file.url as link
    //         file.url = file.response.url;
    //       }
    //       return file;
    //     });

    //     this.setState({ file });
    //   };

    removeFile(fileId: string, id): void {
        if(id === 'file'){
            this.setState({
                file: [],
                value: [],
                file_id: ''
            });
        } else {
            this.setState({
                file2: [],
                value2: [],
                file_id2: ''
            })
        }

    }

    getUploadParams(file: RcFile, nbFiles: number, id?) {
        let fileFieldID = this.getFileFieldID(id)
        return {
            filename: file.name,
            filepath: `${this.props.currentDatastoreID}/${this.props.currentItemID}/${fileFieldID}/${file.name}`,
            field_id: fileFieldID,
            item_id: this.props.currentItemID || '',
            d_id: this.props.currentDatastoreID || '',
            p_id: this.props.currentProjectID || '',
            display_order: nbFiles
        }
    }

    getFileFieldID(id) {
        for(let field of this.props.fields) {
            if(field.dataType === 'file' && field.displayID === id){
                return field.field
            }
        }
    }

    uploadingStateChange(e, type) {
        // console.log("UPLOADING STATE CHANGE", e, type)
        if('file' === type){
            let fileList = e.fileList
            fileList = fileList.slice(-1);


            let values = this.state.value;
            if(e.file.status === 'done') {
                values = [...values, ...[e.file.response.file_id]];
            }
            this.setState({
                file: fileList,
                value : values
            });
            if(e && e.fileList[0] && e.fileList[0].response){
                this.setState({
                    ['file_id']: e.fileList[0].response.file_id
                })
            }
        } else {
            let fileList = e.fileList
            fileList = fileList.slice(-1);
            let values = this.state.value2;
            if(e.file.status === 'done') {
                values = [...values, ...[e.file.response.file_id]];
            }
            this.setState({
                file2: fileList,
                value2 : values
            });
            /////
            if(e && e.fileList[0] && e.fileList[0].response){
                this.setState({
                    ['file_id2']: e.fileList[0].response.file_id
                })
            }
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setInitialState(nextProps.fields, nextProps.entry.fields)
    }

    getFiles(fieldFiles, displayID?) {
        let files = [] as any;
        fieldFiles.forEach(file => {
            files.push({
                uid: file.file_id ? file.file_id : file.uid ? file.uid : '',
                name: file.filename ? file.filename : file.name,
                size: file.size,
                type: file.contentType ? file.contentType : file.type,
            })
        });
        return files;
    }

    getFieldValue(field, entryFieldValue) {
        switch(field.dataType) {
            case 'text': return entryFieldValue
            case 'textarea': return entryFieldValue
            case 'select': return this.getOptionNameByKey(field.displayID, entryFieldValue)
            case 'radio': return this.getOptionNameByKey(field.displayID, entryFieldValue)
            case 'checkbox': return this.pairOptions(field.options, entryFieldValue)
            case 'file': return entryFieldValue ? this.getFiles(entryFieldValue, field.displayID) : []
            default: return entryFieldValue ? entryFieldValue : ''
        }
    }

    pairOptions(options, optionsValArr) {
        let arr:any = [];
        if(!optionsValArr){return arr}
        if(optionsValArr.length === 0 || optionsValArr === undefined || optionsValArr === null){return arr}
        let optionsKeys = Object.keys(options)
        for( let option of optionsKeys){
            for(let selectedOption of optionsValArr){
                if(selectedOption === options[option].value){
                    arr.push(options[option].value)
                }
            }
        }
        return arr
    }

    setInitialState(nextPropsFields, nextPropsEntryFields){
        // console.log("SETTING INITIAL STATE: ", nextPropsFields, nextPropsEntryFields)
        for(const field of nextPropsFields){

            const entryFieldValue = nextPropsEntryFields[field.field]
            const stateValue = this.getFieldValue(field, entryFieldValue)

            if(field.dataType !== 'file'){
                this.setState({
                    [field.displayID]: stateValue,
                })
            } else {
                // console.log('STATE VALUE', stateValue, field.displayID)
                this.setState({
                    [field.displayID]: stateValue,
                    // [field.displayID === 'file' ? 'file_id' : 'file_id2']: this.getFieldEditValue(field)
                })
            if(field.displayID === 'file'){
                    this.setState({
                        file_id: stateValue[0]? stateValue[0].uid : ''
                    })
            }
                if(field.displayID === 'file2'){
                    this.setState({
                        file_id2:  stateValue[0]? stateValue[0].uid : ''
                    })
                }
            }
        }
        if(this.state['file'] && this.state['file'][0]){
            this.setState({
                file_id: this.state['file'][0].uid
            })
        }
        if(this.state['file2'] && this.state['file2'][0]){
            this.setState({
                file_id2: this.state['file2'][0].uid
            })
        }

    }

    actionUpload(){
        return HttpService.getBaseUrl(`new_item_file_attachment`)
    }

    getLabelName(displayID) {
        if(displayID === 'file'){
            return '審査対象関連資料'
        } else if (displayID === 'file2'){
            return '審査結果'
        } else {
            return displayID
        }
    }

    getInput(field, inputType) {
        if(inputType === 'radio'){return}
        // if(field.displayID === '事前承認事項' && this.getFormType() !==  "法人") {return}
        // if(field.displayID === '承諾事項' && this.getFormType() !== '法人') {return} ///////
        if(this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'show')){return}
        if(this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update')){return}
        return (
            <Row type='flex' className={styles["input-container"]} key={field.field}>
                <Col span={5} style={{ textAlign: 'end' }}>
                    {field.displayID !== '審査対象関連資料' && <label className={styles["label"]}>{this.getLabelName(field.displayID)}</label>}
                </Col>
                <Col offset={1} span={18}>
                    {inputType === 'text' &&
                        <Input
                            disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                            value={this.state[field.displayID]}
                            onChange={(e)=>this.setState({[field.displayID]: e.target.value})}/>}
                    {(inputType === 'date' || inputType === 'datetime') &&
                        <DatePicker
                            disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                            value={this.state[field.displayID] ? moment(this.state[field.displayID]) : undefined}
                            onChange={(moment, momentString)=>this.onInputChange(momentString, field.displayID, moment)}/>}
                    {inputType === 'textarea' &&
                        <TextArea disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                        autosize={{ minRows: 8, maxRows: 8 }}
                        value={this.state[field.displayID]}
                        onChange={(e)=>this.onInputChange(e, field.displayID)} />}
                    {inputType === 'select' &&
                        <Select disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                        value={this.state[field.displayID]}
                        style={{minWidth: '300px'}}
                        onChange={(e)=>{this.setState({[field.displayID]: e})}}>
                        {this.getFieldOptions(field.displayID) && this.getFieldOptions(field.displayID).map(opt =>
                            <Option title={field.displayID} value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                        </Select>}
                    {inputType === 'file' && field.displayID === 'file' &&
                        <div className={styles['input-container-upload']}>
                            <Upload
                                style={{opacity: (this.state.files.length === 1 || !this.state.edit) ? .5 : 1}}
                                disabled={this.state.files.length === 1 || !this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                                // disabled={!this.state.edit}
                                multiple={false}
                                listType="text"
                                defaultFileList={this.state['file']}
                                fileList={this.state['file']}
                                action={this.actionUpload()}
                                onRemove={(file: UploadFile): void => this.removeFile(file.uid, 'file')}
                                headers={{"Authorization": `Bearer ${AuthHelper.getAuthKey()}`}}
                                beforeUpload={(file, fileList)=>this.beforeUploading(file, fileList)}
                                onChange={(info: UploadChangeParam<UploadFile>): void => {this.uploadingStateChange(info, 'file')}}
                                data={(file: RcFile) => this.getUploadParams(file, this.state.files.length, 'file')}>
                                <Button
                                    className={styles["file-input"]}
                                    disabled={this.state.files.length === 1 || !this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                                >
                                    ファイルを選択<Icon type="paper-clip" />
                                </Button>
                            </Upload>
                            {this.state['file'] && this.state['file'][0] &&
                                <Button
                                    type='link'
                                    icon='download'
                                    className={styles['download-btn']}
                                    disabled={this.state.files.length === 1 ||
                                        this.state.edit ||
                                        (this.state['action_fields_setting'] &&
                                        !this.getActionFieldSetting(field.field, 'update'))}
                                    onClick={()=>this.downloadFile(this.state['file'][0].uid,this.state['file'][0].type )}>
                                    ダウンロードはこちらから
                            </Button>}
                        </div>
                    }
                    {inputType === 'file' && field.displayID === 'file2' &&
                    <div className={styles['input-container-upload']}>
                        <Upload
                            style={{opacity: (this.state.files2.length === 1 || !this.state.edit) ? .5 : 1}}
                            disabled={this.state.files2.length === 1 || !this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                            // disabled={!this.state.edit}
                            multiple={false}
                            listType="text"
                            defaultFileList={this.state['file2']}
                            fileList={this.state['file2']}
                            action={this.actionUpload()}
                            onRemove={(file: UploadFile): void => this.removeFile(file.uid, 'file2')}
                            headers={{"Authorization": `Bearer ${AuthHelper.getAuthKey()}`}}
                            beforeUpload={(file, fileList)=>this.beforeUploading(file, fileList)}
                            onChange={(info: UploadChangeParam<UploadFile>): void => {this.uploadingStateChange(info, 'file2')}}
                            data={(file: RcFile) => this.getUploadParams(file, this.state.files2.length, 'file2')}>
                            <Button
                                className={styles["file-input"]}
                                disabled={this.state.files2.length === 1 || !this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                            >
                                ファイルを選択<Icon type="paper-clip" />
                            </Button>
                        </Upload>
                        {this.state['file2'] && this.state['file2'][0] && this.props.isAdmin &&
                            <Button
                                type='link'
                                icon='download'
                                className={styles['download-btn']}
                                disabled={this.state.files2.length === 1 || this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))}
                                onClick={()=>this.downloadFile(this.state['file2'][0].uid,this.state['file2'][0].type )} >ダウンロードはこちらから</Button>}
                    </div>
                    }
                    {inputType ==='checkbox' && field.displayID === '審査対象関連資料' &&
                    // <Checkbox.Group disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))} value={this.state[field.displayID]} onChange={(e)=>this.onCheckboxChange(e, '事前承認事項')}></Checkbox.Group>
                        <Checkbox.Group  
                            disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))} 
                            value={this.state[field.displayID]} onChange={(e)=>{this.onCheckboxChange(e, '審査対象関連資料')}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Checkbox  className={styles['checkbox']} value={this.props.t('会社登記簿')}>{this.props.t('COMPANY.FORM.REGISTRY')}</Checkbox>
                                <Checkbox  className={styles['checkbox']}   value={this.props.t('会社案内')}>{this.props.t('COMPANY.FORM.PROFILE')}</Checkbox>
                                <Checkbox  className={styles['checkbox']} value={this.props.t('名刺')}>{this.props.t('COMPANY.FORM.CARD')}</Checkbox>
                                <Checkbox  className={styles['checkbox']} value={this.props.t('その他')}>{this.props.t('COMPANY.FORM.OTHER')}</Checkbox>
                            </div>
                        </Checkbox.Group>}
                    {inputType === 'checkbox' && field.displayID === '事前承認事項' &&
                        <Checkbox.Group 
                            className={styles['verticalCheckbox']}
                            disabled={!this.state.edit || (this.state['action_fields_setting'] && !this.getActionFieldSetting(field.field, 'update'))} 
                            value={this.state[field.displayID]} onChange={(e)=>this.onCheckboxChange(e, '事前承認事項')}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '0px'}}>
                                    {this.getFormType() === '重要契約' &&
                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.KEIYAKU_SHIKI')}>{this.props.t('COMPANY.FORM.KEIYAKU_SHIKI')}</Checkbox>
                                    }                                    
                                    {this.getFormType() !== '重要契約' && <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.CHOSA_HIYO')}>{this.props.t('COMPANY.FORM.CHOSA_HIYO')}</Checkbox>}
                                    <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.HATCHU')}>{this.props.t('COMPANY.FORM.HATCHU')}</Checkbox>
                                    <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.JOKI')}>{this.props.t('COMPANY.FORM.JOKI')}</Checkbox>
                                    {this.getFormType() === '重要契約' && <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}>{this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')} </Checkbox>}
                                </div>
                            </div>
                        </Checkbox.Group>}
                </Col>
            </Row>
        )
    }

    onInputChange = (e,id, moment?) =>{
        if (moment){
            this.setState({
                [id]: moment
            })
        } else {
            this.setState({
                [id]: e.target ? e.target.value : null
            })
        }
    }

    onRadioChange(e) {
        this.setState({
            審査対象関連資料: e.target.value
        })
        if(e.target.value === '特になし'){
            this.setState({
                value: [],
                files: [],
            })
        }
    }

    onCheckboxChange(e, checkboxGroup) {
        if(checkboxGroup === '重要契約'){
            this.setState({
                承諾事項: e
            })
        } else if (checkboxGroup === '事前承認事項') {
            this.setState({
                事前承認事項: e
            })
        } else if (checkboxGroup === "審査対象関連資料") {
            this.setState({
                審査対象関連資料: e
            })
        }
    }

    getSectionRowSideText(rowNum){
        const formType = this.getFormType();
        return <div className={styles['side-box']}>
                    <div className={styles['side-box-num']}>
                        0{rowNum}
                    </div>
                    <div className={styles['side-box-text']}>
                        {rowNum === 1 && formType === '個人' && '審査対象者の情報'}
                        {rowNum === 1 && formType === '法人' && '審査対象企業の情報'}
                        {rowNum === 1 && formType === '重要契約' && '審査対象先の情報'}
                        {rowNum === 2 && '発注者の情報'}
                        {rowNum === 3 && '発注者追加情報'}
                        {rowNum === 4 && '結果情報'}
                    </div>
                </div>
    }

    getSectionRow(rowNum, colorLeft, colorRight): JSX.Element {
        return (
            <Row type="flex" justify="space-around">
                <Col span={2} style={{ backgroundColor: colorLeft }} className={styles[`left-input-${rowNum}`]}>
                    {this.getSectionRowSideText(rowNum)}
                </Col>
                <Col span={22} style={{ backgroundColor: colorRight }} className={styles[`right-input-${rowNum}`]}>
                    {this.getForm(rowNum)}
                </Col>
            </Row>)
    }


    openDeleteModal=()=> {
        this.setState({
            visible: !this.state.visible
        })
    }

    deleteItem = () => {
        this.setState({deleting: true})
        let url = `applications/${this.props.currentProjectID}/datastores/${this.props.currentDatastoreID}/items/delete/${this.props.currentItemID}`
        HttpService.DeleteAsync(url, {}, 'linker-api').subscribe(res=>{
            this.props.deleteItem({itemId: this.props.currentItemID})
            this.props.setMode({mode: ItemDetailMode.CLOSE})
            this.setState({deleting: false})
        })
    }

    changeEditMode = () => {
        this.setState({
            edit: !this.state.edit
        })
    }

    componentWillReceiveProps() {}

    cancelEdit() {
        this.setInitialState(this.props.fields, this.props.currentItem.fields)

        this.setState({
            edit: false,
            ['SID']: null,
            action_fields_setting: null
        })
    }

    getOptionsKeyByName(displayID, valueArr, type){
        let arr = [] as any;
        if(!valueArr){return ""}
        for(let field of this.props.fields){
            if(field.displayID === displayID){
                for(let option of field.options){
                    if(valueArr === option.value){
                        if(type === 'SELECT' || type === 'RADIO'){
                            return option.o_id
                        }
                        arr.push(option.o_id)
                    }
                }
            }
        }
        return arr
    }

    getFieldEditValue(field) {
        let arr = [] as any;
        if(field.dataType === 'file'){
            // console.log('GET FIELD EDIT VALUE OF ', field, this.state[field.displayID])
        }
        switch(field.dataType) {
            case 'text': return this.state[field.displayID] ? this.state[field.displayID] : ''
            case 'textarea': return this.state[field.displayID] ? this.state[field.displayID] : ''
            case 'select': return this.getOptionsKeyByName(field.displayID, this.state[field.displayID], 'SELECT')
            case 'radio': return this.getOptionsKeyByName(field.displayID, this.state[field.displayID], 'RADIO')
            case 'checkbox': return this.getOptionsKeyByName(field.displayID, this.state[field.displayID], 'CHECKBOX')
            case 'file': if(this.state['file_id'] && field.displayID === 'file'){
                                arr.push(this.state['file_id'])
                            }
                            if(this.state['file_id2'] && field.displayID === 'file2'){
                                arr.push(this.state['file_id2'])
                            }
                return arr
            case 'date': return this.state[field.displayID] ? this.state[field.displayID] : null
            case 'datetime': return this.state[field.displayID] ? this.state[field.displayID] : null
            default: return this.state[field.displayID] ? this.state[field.displayID] : ''
        }
        return field.value
    }

    getChanges() {
        let arr = [] as any;
        for(let field of this.props.fields){
            if(field.dataType === 'separator' || field.displayID === 'ID'){continue}// || field.displayID === '審査結果'
            if(field.dataType === 'status'){continue} //NEED TO UPDATE FOR STATUS HERE EVENTUALLY
            // if(field.displayID === 'file' && !this.state['file_id']){continue}
            // if(field.displayID === 'file2' && !this.state['file_id2']){continue}
            arr.push({
                "id": field.displayID,
                "value": this.getFieldEditValue(field)
            })
        }
        return arr
    }

    getFieldByFieldID(fid) {
        for(let field of this.props.fields){
            if(fid === field.field){
                return field
            }
        }
    }

    getVisualChanges() {
        let fieldsObject = {}
        let fields = Object.keys(this.props.entry.fields)
        for(let field of fields){
            let foundField = this.getFieldByFieldID(field)
            fieldsObject[field]= this.state[foundField.displayID]
        }
        return fieldsObject
    }

    saveEdit() {
        let url = `applications/${this.props.currentProjectID}/datastores/${this.props.currentDatastoreID}/items/edit/${this.props.currentItemID}`
        let changes = this.getChanges()
        // console.log("CHANGES", changes)
        let payload = {
            "history": {
                "comment": this.state['comment'] ? this.state['comment'] : ''
            },
            "changes": changes,
            "use_display_id": true,
            "is_force_update": true,
            "action_id": this.state.currentAID
        }

        HttpService.PostAsync(url, payload, 'linker-api').subscribe( response => {
           this.props.updateEntry({fieldId: this.props.currentItemID, changes: {fields: this.getVisualChanges()}})
           this.props.setCurrentID({itemId: this.props.currentItemID})
        },
        err => {
                console.log(err)
        },
        () => {
            this.setState({
                edit: false,
                comment: ''
            })

        })

    }

    getStatusNameByID(ID) {
        for(let stat of this.props.itemStatuses.statuses){
            if(stat.data.s_id === ID){
                return stat.data.name
            }
        }
    }

    actionFieldAsync(action) {

        return new Promise(resolve=> {
            let url = `datastores/${this.props.currentDatastoreID}/actions/${action.a_id}/fields`
            let afs;
            // /api/v0/datastores/:datastore-id/actions/:action-id/fields
            HttpService.GetAsync(url, null, 'linker-api').subscribe(
                (res)=> {
                    resolve(res)
                },
                (err)=> {
                    console.log(err)
                })
        })
    }

    getActionFieldSetting(fieldID, type) {
        if(!this.state['action_fields_setting'].action_field_settings[fieldID]){return false}
        if(type === 'show'){
            return this.state['action_fields_setting'].action_field_settings[fieldID].show
        }
        if(type === 'update') {
            return this.state['action_fields_setting'].action_field_settings[fieldID].update
        }
    }


    actionCall = async (action) =>{
        await this.actionFieldAsync(action).then((res: any)=>{
            this.setState({
                action_fields_setting: res.data
            })
        })

        this.setState({
            currentAID: action.a_id
        })
        if(action.operation === '2') {
            this.setState({
                ['ステータス']: this.getStatusNameByID(action.set_status),
                ['SID']: action.set_status
            })
            this.changeEditMode()
        }
        if(action.operation === '3') {
            this.openDeleteModal()
        }
    }

    downloadFile = (fileId: string, type: string): void =>{
        FileHelper.downloadFile(fileId).subscribe((respData) => {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(new Blob([respData.data], {type}));
            const filename = decodeURI(respData.headers.filename);
            a.download = filename || StringUtils.GenUUID();
            a.click();
        })
    }

    render(): JSX.Element {
        return (
            <div>
                {this.consoles()}

                {this.props &&  this.props.entry && this.props.currentItemID !== '' && <Drawer
                    title={this.getFormType() + ": "+ this.props.titlesIds.toString()}
                    width='80vw'
                    visible={this.state.mode !== ItemDetailMode.CLOSE}
                    onClose={(e): void => {this.onClose(e)}}
                    closable={true}>
                    { this.props.loading ?
                        <Spin /> :
                        <div>
                            <Layout className={styles['layout']}>
                                <Row style={{ display: 'flex', marginBottom: 20 }}>
                                    {this.renderStatuses()}
                                </Row>
                                <Row type="flex" justify="space-around">
                                    <Col span={18}>
                                        <Row style={{ overflow: 'scroll', height: this.state.edit ? 'calc(100vh - 220px)' : 'calc(100vh - 160px)' }}>
                                            {this.props && this.getSectionRow(1, '#D7DFF5', '#EDF0F8')}
                                            {this.getSectionRow(2, '#D3F1EF', '#EDF8F7')}
                                            {this.getSectionRow(3,'#D4F1D9' ,'#EDF8EF')}
                                            {this.getSectionRow(4,'#E2E4C1','#EFF0DB')}
                                        </Row>
                                        {this.state.edit &&
                                            <Row type='flex' justify='center' className={styles['drawer-footer-btn']}>
                                                <Button
                                                    type="default"
                                                    style={{marginRight: 20}}
                                                    onClick={()=>this.cancelEdit()}>
                                                    キャンセル
                                                </Button>
                                                <Button
                                                    type="primary"
                                                    className={styles['save-btn']}
                                                    onClick={()=>this.saveEdit()}>
                                                    保存
                                                </Button>
                                            </Row>}
                                    </Col>
                                    <Col span={5}>
                                        {!this.state.deleting ?
                                            <ItemDetailsSide
                                                downloadFile={this.downloadFile}
                                                file2Info={this.state['file2']}
                                                comment={this.state['comment'] ? this.state['comment'] : ''}
                                                onInputChange={this.onInputChange}
                                                isEdit={this.state.edit}
                                                changeEditMode={this.changeEditMode}
                                                deleteItem={this.deleteItem}
                                                onCollapse={this.onCollapse}
                                                actionCall={this.actionCall}
                                                openDeleteModal={this.openDeleteModal} /> :
                                            <Spin />}
                                    </Col>
                                </Row>
                            </Layout>
                        </div>
                    }
                </Drawer>}

                <DeleteConfirm deleteItem={this.deleteItem} showModal={this.openDeleteModal} visible={this.state.visible}/>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState): ItemDetailsStateProps => ({
    loading : ItemDetailsSelectors.getIsLoading(state),
    titlesIds : ItemDetailsSelectors.getItemsTitlesIds(state),
    entry: ItemDetailsSelectors.getEntry(state),
    columns: ItemsSelectors.getItemsColumns(state),
    mode: ItemDetailsSelectors.getDisplayMode(state),
    currentAction : ItemDetailsSelectors.getCurrentAction(state),
    currentItem: ItemDetailsSelectors.getEntry(state),
    fields: ItemsSelectors.getFields(state),
    currentItemID: ItemDetailsSelectors.getEntryId(state),
    currentProjectID: ProjectSelectors.getCurrentProjectId(state),
    currentDatastoreID: DatastoreSelector.getCurrentDatastoreId(state),
    itemStatuses: ItemDetailsSelectors.getStatuses(state),
    workspaceID: WorkspaceSelectors.getCurrentWorkspaceId(state)


});

const mapDispatchToProps = {
    setMode : ItemsDetailsActions.setMode,
    updateItemRequest: ItemsDetailsActions.updateItemRequest,
    newItemRequest: ItemsDetailsActions.newItemRequest,
    deleteItem: ItemsActions.deleteItem,
    deleteItemRequest: ItemsActions.deleteItemRequest,
    closeDetails: ItemsDetailsActions.setMode,
    updateEntry: ItemsActions.updateEntry,
    setCurrentID: ItemsActions.setCurrentItemId
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemDetails));