import { createSelector } from "reselect";

import { RootState } from "../rootReducer";
import { ItemsState } from "./state";
import { ColumnSettings, DatastoreField, ItemEntry, ItemDisplayableEntry, DatastoreColumns, ItemSearch } from "./types";
import StringUtils from "app/utils/stringUtils";

const itemState = (state: RootState): ItemsState => state.items;
const itemsEntries = (state: RootState): Array<ItemEntry> => state.items.entries;
const itemsFields = (state: RootState): Array<DatastoreField> => state.items.fields;

export const getItemsIsLoading = createSelector<RootState, ItemsState, boolean>(
    itemState,
    (itemsState: ItemsState) => itemsState.loading
);

export const getItemsColumns = createSelector<RootState, ItemsState, DatastoreColumns>(
    itemState,
    (itemsState: ItemsState) => itemsState.columns
);

export const getCurrentItemId = createSelector<RootState, ItemsState, string>(
    itemState,
    (itemsState: ItemsState) => itemsState.current_item_id
);

export const getCurrentPage = createSelector<RootState, ItemsState, number>(
    itemState,
    (itemState: ItemsState) => itemState.current_page
)

export const getItemsColumnsSettings = createSelector<RootState, ItemsState, Map<string, ColumnSettings>>(
    itemState,
    (itemsState: ItemsState) =>  (itemsState.columns && itemsState.columns.column_settings) ? new Map(Object.entries(itemsState.columns.column_settings)) : new Map<string, ColumnSettings>()
);

export const getFields = createSelector<RootState, ItemsState, Array<DatastoreField>>(
    itemState,
    (itemsState: ItemsState) => itemsState.fields
);
export const getItems = createSelector<RootState, ItemsState, Array<ItemEntry>>(
    itemState,
    (itemsState: ItemsState) => itemsState.entries
);
export const getCurrentConditions = createSelector<RootState, ItemsState, Array<ItemSearch>>(
    itemState,
    (itemsState: ItemsState) => itemsState.current_conditions
);
export const getIsNotCompletedDelivery = createSelector<RootState, ItemsState, boolean>(
    itemState,
    (itemsState: ItemsState) => itemsState.is_not_completed_delivery
);

export const getDisplayableEntries = createSelector<RootState, ItemsState, Array<ItemEntry>, Array<DatastoreField>, ItemDisplayableEntry>(
    itemState,
    itemsEntries,
    itemsFields,
    (itemsState: ItemsState, entries: Array<ItemEntry>, fields: Array<DatastoreField>) => {
        const dispColumns = fields.map((field: any) => {
            return {
                title: field.displayName,
                dataIndex: field.displayName,
                key: field.displayID,
                id: field.field
            }
        });
        const dispItems = entries.map((item: ItemEntry) => {
            const targetObj: {[k: string]: any} = {};

            dispColumns.forEach((element: any) => {
                targetObj[element.key] = item[element.key]
            });

            targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`;
            targetObj['i_id'] = item.i_id;

            return targetObj;
        });
        return { columns: dispColumns, items: dispItems} as ItemDisplayableEntry;
    }
);

export default [
    getItemsIsLoading,
    getItemsColumns,
    getItemsColumnsSettings,
    getDisplayableEntries
]