import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const itemsSlice = createSlice({
  slice: "items",
  initialState: initialState,
  reducers: {
    setCurrentItemId: reducers.setCurrentItemId,
    setCurrentPage: reducers.setCurrentPage,
    setCurrentConditions: reducers.setCurrentConditions,
    setIsNotCompletedDelivery: reducers.setIsNotCompletedDelivery,
    getItemsListRequest: reducers.getItemsListRequest,
    getItemsListSuccess: reducers.getItemsListSuccess,
    getItemsListFailed: reducers.getItemsListFailed,
    deleteItem: reducers.deleteItem,
    deleteItemRequest: reducers.deleteItemRequest,
    deleteItemSuccess: reducers.deleteItemSuccess,
    deleteItemFailed: reducers.deleteItemFailed,
    updateEntry: reducers.updateEntry,
    addEntry: reducers.addEntry,
    reset: reducers.reset
  }
});

export const {
  setCurrentItemId,
  setCurrentPage,
  setCurrentConditions,
  setIsNotCompletedDelivery,
  getItemsListRequest,
  getItemsListSuccess,
  getItemsListFailed,
  deleteItem,
  deleteItemRequest,
  deleteItemSuccess,
  deleteItemFailed,
  updateEntry,
  addEntry,
  reset
} = itemsSlice.actions;
