import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, Form, Input, Tabs, Spin, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import HttpService from 'app/services/httpService/httpService';
import Logger from 'app/utils/logger';
import { RootState } from 'app/services/store/rootReducer';
import { AlertType } from 'app/utils/antdHelper';
import { UsersActions, UsersSelectors, WorkspaceSelectors } from 'app/services/store';
import { withTranslation, WithTranslation } from 'react-i18next';
import PasswordChanger from 'app/components/password/passwordChanger';

import styles from '../settings.module.css'

interface UserSettingsProps extends FormComponentProps, WithTranslation {
    userId: string;
    username: string;
    email: string;
    workspaceId: string;
    getCurrentUserRequest: Function;
}

const { TabPane } = Tabs;

class UserSettings extends React.Component<UserSettingsProps> {
    state = {
        user: {
            username: '',
            profilepic: '',
        },
        password: {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
        },
        alert: {
            type: AlertType.INFO,
            message: '',
            display: false
        },
        loading: false
    }

    componentDidMount(): void {
        this.setState({
            user: {
                username: this.props.username,
            },
        })
    }

    changeHandler = (category: string, key: string, value: string): void => {
        this.setState({
            [category]: {
                ...this.state[category],
                [key]: value
            }
        });
    }

    // ユーザーアカウント更新
    postUpdateUserProfile = (): void => {
        this.setState({ loading: true });

        HttpService.PutAsync<any, any>(`userinfo`, {
            email: this.props.email, //必須
            username: this.state.user.username,
            user_id: this.props.userId, //必須
            pic: this.state.user.profilepic
        }, HttpService.LinkerAPIBasePath).subscribe((res) => {
            const success = (res.data && !res.data.error);
            if (success) this.props.getCurrentUserRequest();
            this.setState({
                alert: {
                    type: success ? AlertType.SUCCESS : AlertType.ERROR,
                    message: success ? 'ユーザ情報を更新しました' : 'ユーザ情報を更新できませんでした',
                    display: true
                },
                loading: false
            });
            this.hideAlert(5000);
        }, err => {
            Logger.console.log(`ERROR : update_userinfo`, err);
            this.setState({
                alert: {
                    type: AlertType.ERROR,
                    message: 'ユーザ情報を更新できませんでした',
                    display: true
                },
                loading: false
            });
            this.hideAlert(5000);
        })
    }

    // パスワード更新
    postUpdatePassword = (): void => {
        this.setState({ loading: true });
        HttpService.PutAsync<any, any>(`users/password`, {
            confirm_password: this.state.password.newPasswordConfirmation,
            new_password: this.state.password.newPassword,
            old_password: this.state.password.currentPassword,
        }, HttpService.LinkerAPIBasePath).subscribe((res) => {
            let alert = this.state.alert;
            let password = this.state.password;
            if (res.data && !res.data.error) {
                password = {
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                };
                alert = {
                    type: AlertType.SUCCESS,
                    message: this.props.t(`USER.PASSWORD.CHANGE_SUCCESS`),
                    display: true
                }
                this.props.form.resetFields();
            } else if (res.data.error) {
                const msg = res.data.error === 'password invalid' ? 'INVALID' : res.data.error;
                alert = {
                    type: AlertType.ERROR,
                    message: res.data.error === "password invalid" ? this.props.t(`USER.PASSWORD.ERR_MSG.INVALID`) : this.props.t(`USER.PASSWORD.ERR_MSG.${msg}`),
                    display: true
                }
            }
            this.setState({
                password,
                alert,
                loading: false
            });
            this.hideAlert(5000);
        },
        err => {
            Logger.console.log('ERROR : update_users_password', err);
            this.setState({
                alert: {
                    type: AlertType.ERROR,
                    message: this.props.t(`USER.PASSWORD.CHANGE_FAILED`),
                    display: true
                },
                loading: false
            })
            this.hideAlert(5000);
        })
    }

    hideAlert(delay = 0): void {
        setTimeout(() => this.setState({
            alert: {
                type: AlertType.INFO,
                message: '',
                display: false
            }
        }), delay);
    }

    reset(): void {
        this.setState({
            user: {
                username: this.props.username,
            },
            alert: {
                type: AlertType.INFO,
                message: '',
                display: false
            },
            password: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirmation: '',
            }
        })
    }

    render(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        const { user } = this.state;
        return (
            <Row className={styles['user-drawer']}>
                <Row className={styles['user-drawer-title']}>ユーザー設定</Row>
                <Tabs className={styles['tabs']} onChange={(): void => this.reset()}>
                    <TabPane tab='ユーザー情報' key='user' className={styles['tab-inner']}>
                        <Form {...user} layout={'vertical'} hideRequiredMark={true}>
                            <Form.Item label='ユーザー名' className={styles['input-form']}>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: 'ユーザー名を入力してください' }],
                                    initialValue: this.props.username
                                })(<Input
                                    type='text'
                                    placeholder={this.props.username}
                                    onChange={(e): void => this.changeHandler('user', 'username', e.target.value)}
                                    className={styles['input-box']}
                                    suffix='*'
                                />)}
                            </Form.Item>
                            <Form.Item label='メールアドレス' className={styles['input-form']}>
                                <Input
                                    type='text'
                                    placeholder='メールアドレス'
                                    defaultValue={this.props.email}
                                    onChange={(e): void => this.changeHandler('user', 'email', e.target.value)}
                                    className={styles['input-box']}
                                    disabled
                                />
                            </Form.Item>
                            {this.state.alert.display &&
                                <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                            {this.state.loading ? <Spin /> : <Form.Item>
                                <Button
                                    onClick={() => { this.postUpdateUserProfile() }}
                                    className={styles['save-btn']}
                                    disabled={
                                        (!this.props.form.isFieldTouched('username') || this.props.form.getFieldError('username') !== undefined)
                                    }
                                >保存</Button>
                            </Form.Item>
                            }
                        </Form>
                    </TabPane>

                    <TabPane tab='パスワードの変更' key='password' className={styles['tab-inner']}>
                        <Form layout={'horizontal'} colon={false}>
                            <PasswordChanger
                                workspaceId={this.props.workspaceId}
                                askCurrentPassword={true}
                                form={this.props.form}
                                onChange={(key: string, value: string) => this.changeHandler('password', key, value)} />
                            {this.state.loading ? <Spin /> : <Form.Item>
                                {this.state.alert.display &&
                                    <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                                <Button
                                    onClick={() => { this.postUpdatePassword() }}
                                    className={styles['save-btn']}
                                    disabled={(
                                        (!this.props.form.isFieldTouched('currentPassword') || this.props.form.getFieldError('currentPassword') !== undefined) ||
                                        (!this.props.form.isFieldTouched('newPassword') || this.props.form.getFieldError('newPassword') !== undefined) ||
                                        (!this.props.form.isFieldTouched('newPasswordConfirmation') || this.props.form.getFieldError('newPasswordConfirmation') !== undefined)
                                    )}
                                >保存</Button>
                            </Form.Item>
                            }
                        </Form>
                    </TabPane>
                </Tabs>
            </Row>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    userId: UsersSelectors.getUserId(state),
    username: UsersSelectors.getUsername(state),
    email: UsersSelectors.getUserEmail(state),
    workspaceId: WorkspaceSelectors.getCurrentWorkspaceId(state)
})

const mapDispatchToProps = {
    getCurrentUserRequest: UsersActions.getCurrentUserRequest,
}

const createdForm = Form.create()(withTranslation()(UserSettings));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(createdForm);
