import React from "react";
import HttpService from "app/services/httpService/httpService";
import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { withTranslation, WithTranslation } from "react-i18next";
import { PasswordPolicy } from "app/utils/default.t";
import styles from './passwordChanger.module.css'

interface PasswordChangerProps extends FormComponentProps, WithTranslation {
    askCurrentPassword: boolean;
    onChange?: (key: string, value: string) => void;
    workspaceId?: string;
    policy?: PasswordPolicy;
}

interface PasswordChangerState {
    form: {
        currentPassword: string;
        newPassword: string;
        newPasswordConfirmation: string;
    };
    policy: PasswordPolicy;
}

class PasswordChanger extends React.Component<PasswordChangerProps, PasswordChangerState> {
    state = {
        form: {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        },
        policy: {
            expired_day: 0,
            lockout_count: 0,
            lockout_time: 0,
            min_length: 0,
            pattern_check_type: 0,
            same_limit: 0,
            use_expired_day: false,
            use_lockout_count:  false,
            use_lockout_time:  false,
            use_min_length:  false,
            use_pattern_check: false,
            use_same_limit:  false,
            use_language_en: false,
            use_language_ja: false
        }
    };

    newPasswordRules: Array<any> = [{ required: true, message: this.props.t(`USER.PASSWORD.INVALID_NEW_PASSWORD`) }];

    buildNewPasswordRule(data: PasswordPolicy) {
        this.newPasswordRules = [{ required: true, message: this.props.t(`USER.PASSWORD.INVALID_NEW_PASSWORD`) }];
        if(data.use_min_length) {
            this.newPasswordRules.push({
                min: data.min_length, message: `${data.min_length}文字以上のパスワードを設定してください`
            })
        }
        if(data.use_pattern_check) {
            switch(data.pattern_check_type) {
                case 1:
                    this.newPasswordRules.push({
                        type: "regexp", pattern: new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/), message: `パスワードが正規表現と一致しません`
                    })
                    break;
                case 2:
                    this.newPasswordRules.push({
                        type: "regexp", pattern: new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$%\-_=+<>])([a-zA-Z0-9!#$%\-_=+<>]+)$/), message: `パスワードが正規表現と一致しません`
                    })
                    break;
                default:
                    this.newPasswordRules.push({
                        type: "regexp", pattern: new RegExp(/^[/\S/]+$/), message: `パスワードが正規表現と一致しません`
                    })
                    break;
            }
        }
    }


    componentDidMount() {
        if(this.props.policy) {
            this.buildNewPasswordRule(this.props.policy);
        } else {
            this.getPolicy();
        }
    }

    getPolicy() {
        if(!this.props.workspaceId) { return; }
        HttpService.GetAsync<null, PasswordPolicy>(`workspaces/${this.props.workspaceId}/password-policy`, null, HttpService.LinkerAPIBasePath).subscribe(res => {
            this.buildNewPasswordRule(res.data);
        })
    }

    componentDidUpdate(prevProps){
        if(!this.props.policy && prevProps.workspaceId !== this.props.workspaceId){
            return this.getPolicy();
        }

        if(this.props.policy && JSON.stringify(prevProps.policy) !== JSON.stringify(this.props.policy) ){
            this.buildNewPasswordRule(this.props.policy);
        }
    }

    changeFormField = (value: any, name: string): void => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
        if(this.props.onChange) this.props.onChange(name, value);
    }

    checkConfirmationPassword = (rule, value, callback) => {
        try {
            if (value !== this.state.form.newPassword) {
                throw new Error('Field do not match');
            } else {
                callback();
            }
        } catch (err) {
            callback(err);
        }
    }

    render(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {this.props.askCurrentPassword && <Form.Item hasFeedback className={styles['pwchange-form']}>
                    {getFieldDecorator('currentPassword', {
                        rules: [{ required: true, message: this.props.t(`USER.PASSWORD.INVALID_OLD_PASSWORD`) }]
                    })(<Input
                        className={styles['input']}
                        onChange={(e): void => {
                            this.changeFormField(e.target.value, 'currentPassword');
                        }}
                        type='password'
                        placeholder={this.props.t(`USER.PASSWORD.OLD_PASSWORD`)}
                    />)}
                </Form.Item>}
                <Form.Item hasFeedback>
                    {getFieldDecorator('newPassword', {
                        rules: this.newPasswordRules
                    })(<Input
                        className={styles['input']}
                        onChange={(e): void => {
                            this.changeFormField(e.target.value, 'newPassword');
                            setTimeout(() => {this.props.form.validateFields(["newPasswordConfirmation"])}, 0)
                        }}
                        type='password'
                        placeholder={this.props.t(`USER.PASSWORD.NEW_PASSWORD`)}
                    />)}
                </Form.Item>
                <Form.Item hasFeedback>
                    {getFieldDecorator('newPasswordConfirmation', {
                        rules: [...this.newPasswordRules,
                                { validator: this.checkConfirmationPassword, message: 'パスワードが一致しません'}]
                    })(<Input
                        className={styles['input']}
                        onChange={(e): void => this.changeFormField(e.target.value, 'newPasswordConfirmation')}
                        type='password'
                        placeholder={this.props.t(`USER.PASSWORD.CONFIRM_PASSWORD`)}
                    />)}
                </Form.Item>
            </div>
        )
    }
}

export default withTranslation()(PasswordChanger);
