import React from 'react'

import { useSelector } from 'react-redux';

import { Row } from 'antd';
import { Spinner } from "@blueprintjs/core";
import { MainSelectors, WorkspaceSelectors, UsersSelectors } from 'app/services/store';

import './preloader.css';
import { RootState } from 'app/services/store/rootReducer';

function Preloader(): JSX.Element {
    const loadingMain = useSelector((state: RootState) => MainSelectors.getMainLoading(state));
    const loadingWorkspace = useSelector((state: RootState) => WorkspaceSelectors.getWorkspaceIsLoading(state));
    const loadingUser = useSelector((state: RootState) => UsersSelectors.getUserLoading(state));
    return(
        <div className={(loadingMain || loadingWorkspace || loadingUser) ? 'preloader' : 'preloader-hide'}>
            <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
                <Spinner intent='primary' size={150} />
            </Row>
        </div>
    )
}

export default Preloader;