import React from 'react';
import {
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom'

import { useSelector } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { UsersSelectors } from 'app/services/store';



const PrivateRoute = (props: RouteProps): JSX.Element => {
  const authKey = useSelector((state: RootState) => UsersSelectors.getUserToken(state));
  const {component: Component, ...rest}: any = props;
  let url = window.location.pathname
  // console.log('EHHHH',url ,props['match'], props)
  if(url != '/login'){
    sessionStorage.setItem('url', url)
  }
  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        authKey === ""
        ? <Redirect to="/login" />
        : <Component {...props}/>}
    />
  )
}

export default PrivateRoute;