import React from 'react'
import { ItemDetailsField } from 'app/services/store/itemDetails/types';
import styles from './field.module.css';
import { Icon } from 'antd';

export enum FieldMode {
    DISPLAY,
    EDIT,
    HIDDEN,
    DISABLED,
    MANDATORY
}

export interface FieldProps {
    fieldId: string;
    mode: FieldMode;
    field: ItemDetailsField;
    mandatory?: boolean;
    value: any;
    onChange: Function;
}

export interface FieldState {
    mode: FieldMode.DISPLAY;
    renders: {[k: number]: () => JSX.Element};
    value: any;
}

class Field<T extends FieldProps> extends React.Component<T> {
    public state: FieldState = {
        mode : FieldMode.DISPLAY,
        renders : {
            [FieldMode.DISPLAY] : this.default,
            [FieldMode.EDIT] : this.default,
            [FieldMode.HIDDEN] : this.default,
            [FieldMode.DISABLED] : this.default,
            [FieldMode.MANDATORY] : this.default,
        },
        value : null
    }

    componentDidMount(): void {
        return;
    }

    handleOnChange(fieldId: string, value: any): void {
        this.props.onChange(fieldId, value);
    }

    resetValue(): void {
        this.setState({
            value : this.props.value
        });
    }

    enterMode(mode: FieldMode): void {
        switch(mode) {
            case FieldMode.DISPLAY:
                this.resetValue();
            break;
            default:
            break;
        }
        this.setState({
            mode
        });
    }

    componentDidUpdate(prevProps: FieldProps, prevState: FieldState): void {
        if(prevState.mode !== this.props.mode) {
            this.enterMode(this.props.mode)
        }
        if(prevState.value !== this.state.value) {
            this.handleOnChange(this.props.fieldId, this.state.value);
        }
        if(prevProps.value !== this.props.value) {
            this.resetValue();
        }
    }

    default(): JSX.Element {
        return (
            <div>
            </div>
        )
    }

    render(): JSX.Element {
        return (
            <div>
                <div className={styles["field_name"]}>
                    {this.props.field.name}
                </div>
                <div className={styles["field_content"]}>
                    {this.state.renders[this.state.mode].call(this)}
                </div>
            </div>
        )
    }
}

export default Field;