import { ofType } from "redux-observable";
import { map, catchError, switchMap } from 'rxjs/operators';
import { ProjectSelectors, ItemsActions, DatastoreSelector, ItemsDetailsActions, UsersSelectors } from "..";
import HttpService from "app/services/httpService/httpService";
import { of } from "rxjs";
import * as apiModels from "./epic.types";
import ApiHelper from "app/utils/apiHelper";
import { Observable } from "redux";
import { ItemDetailMode } from "../itemDetails/types";
import actions from "app/components/itemDetails/itemDetailsSide/actions/actions";
import { PayloadAction } from "redux-starter-kit";
import AuthHelper from 'app/services/authHelper/authHelper';
import { message } from 'antd';

const getItemsListEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsActions.getItemsListRequest),
    switchMap((action: PayloadAction<{projectId: string; datastoreId: string; conditions?: Array<any>, for_csv_download?: boolean}>) => {
        const datastore_id = action.payload.datastoreId;
        const project_id = action.payload.projectId;
        const conditions = action.payload.conditions

        return HttpService.PostAsync<apiModels.api_aggregated_api_datastore_items_request, apiModels.api_aggregated_api_datastore_items_response>(`aggregated_api_datastore_items`, {
            get_datastore_settings: {
                datastore_id,
                project_id
            },
            get_item_list_personalize: {
                d_id: DatastoreSelector.getCurrentDatastoreId(state$.value)
            },
            get_sf_new_action_settings: {
                datastore_id,
                project_id
            },
            get_datastore_colinfo: {
                datastore_id,
                project_id
            },
            postget_paginate_items_with_search: {
                datastore_id,
                project_id,
                conditions: conditions ? conditions : [],
                sort_field_id: "ID",
                sort_order: "desc",
                use_default_search: conditions ? false : true,
                use_display_id: true,
                include_fields_data: true,
                per_page: 1000
            }
        }, HttpService.HexaLinkBasePath).pipe(
            map((result) => {
                // この４０１がaxios.interceptors.responseで拾えないため追記
                if(result.data['postget_paginate_items_with_search'] && result.data['postget_paginate_items_with_search']['error']) {
                    AuthHelper.unauthorized();
                }
                const response = result.data;
                const entries = ApiHelper.buildEntries(response.postget_paginate_items_with_search.items, response.postget_paginate_items_with_search.fields);

                return ItemsActions.getItemsListSuccess({
                    entries: entries,
                    columns: response.get_datastore_colinfo,
                    fields: response.postget_paginate_items_with_search.fields,
                    new_actions: response.get_sf_new_action_settings,
                    settings: response.get_datastore_settings,
                    personalization: response.get_item_list_personalize
                });
            }),
            catchError((error: string) => {
                return of(ItemsActions.getItemsListFailed({error}));
            })
        )
    })
);

const deleteItemEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsActions.deleteItemRequest),
    switchMap((action: {payload: {actionId: string; itemId: string}})=> {
        const datastore_id = DatastoreSelector.getCurrentDatastoreId(state$.value);
        const user_id = UsersSelectors.getUserId(state$.value);
        return HttpService.PostAsync<apiModels.post_delete_dt_item_request, apiModels.post_delete_dt_item_response>(`post_delete_dt_item`, {
            a_id: action.payload.actionId,
            d_id: datastore_id,
            i_id: action.payload.itemId,
            u_id: user_id
        }).pipe(
            switchMap((result) => {
                return [
                    ItemsActions.deleteItemSuccess({itemId: action.payload.itemId}),
                    ItemsDetailsActions.setMode({mode: ItemDetailMode.CLOSE})
                ]
            }),
            catchError((error: string) => of(ItemsActions.getItemsListFailed({error})))
        )
    })
);

const setCurrentItemIdEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ItemsActions.setCurrentItemId),
    switchMap((action: {payload: {itemId: string} }) => {
        const selectingItemId = (action && action.payload.itemId);
        if(selectingItemId) {
            return [
                ItemsDetailsActions.getItemDetailsRequest({itemId: action.payload.itemId}),
                ItemsDetailsActions.setMode({mode: ItemDetailMode.DISPLAY})
            ]
        } else {
            return [
                ItemsDetailsActions.setMode({mode: ItemDetailMode.CLOSE}),
                ItemsDetailsActions.reset()
            ]
        }
    })
);

export default [
    getItemsListEpic,
    setCurrentItemIdEpic,
    deleteItemEpic
];