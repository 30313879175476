import { ofType } from "redux-observable";
import { catchError, switchMap } from 'rxjs/operators';
import { WorkspaceActions, ProjectActions, DatastoreActions, ItemsDetailsActions, ItemsActions, WorkspaceSelectors } from "..";
import HttpService from "app/services/httpService/httpService";
import { of } from "rxjs";
import * as storeModels from "./types";
import * as apiModels from "./epic.types";
import { Observable } from "redux";

const getAllWorkspacesEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(WorkspaceActions.getWorkspacesRequest),
    switchMap(action =>
        HttpService.GetAsync<null, apiModels.api_get_all_workspace_response>('get_all_workspaces').pipe(
            switchMap(response => {
                const workspaces = response.data ? response.data.workspaces : new Array<storeModels.Workspace>();
                const workspaceId = response.data ? response.data.current_workspace_id : '';
                return [
                    WorkspaceActions.getWorkspacesSuccess({workspaces}),
                    WorkspaceActions.setCurrentWorkspaceIdRequest({workspaceId}),
                ];
            }),
            catchError((error: string) => {
                return of(WorkspaceActions.getWorkspacesFailed({error}));
            })
        )
    )
);

const setCurrentWorkspaceIdEpic = (action$, state$) => action$.pipe(
    ofType(WorkspaceActions.setCurrentWorkspaceIdRequest),
    switchMap((action: {payload: {workspaceId: string}}) => {
        const currentWorkspaceId = WorkspaceSelectors.getCurrentWorkspaceId(state$.value);
        if(currentWorkspaceId === action.payload.workspaceId) {
            return of(WorkspaceActions.setCurrentWorkspaceIdSuccess(action.payload));
        } else {
            return HttpService.PostAsync<apiModels.api_post_set_current_workspace_id_request, null>(`post_set_current_workspace`, {workspace_id : action.payload.workspaceId}).pipe(
                switchMap(result => {
                    return [
                        DatastoreActions.reset(),
                        ProjectActions.reset(),
                        ItemsActions.reset(),
                        ItemsDetailsActions.reset(),
                        WorkspaceActions.setCurrentWorkspaceIdSuccess(action.payload),
                        ProjectActions.getProjectsRequest(action.payload),
                    ];
                }),
                catchError((error: string) => {
                    return of(WorkspaceActions.setCurrentWorkspaceIdFailed({error}));
                })
            )
        }
    })
);

export default [
    getAllWorkspacesEpic,
    setCurrentWorkspaceIdEpic
];