import { ofType } from "redux-observable";
import { switchMap} from 'rxjs/operators';
import { concatMap} from 'rxjs/operators';
import { ProjectSelectors, DatastoreActions, ItemsActions } from "..";
import { EMPTY, of } from "rxjs";
import { Observable, Action } from "redux";
import { PayloadAction } from "redux-starter-kit";
import { ItemSearch } from "../items/types";


const setCurrentDatastoreIdEpic = (action$, state$): Observable<Action<string>> => action$.pipe(
    ofType(DatastoreActions.setCurrentDatastoreId),
    concatMap((action: PayloadAction<{datastoreId: string; conditions: Array<ItemSearch>}>) => {
        const projectId = ProjectSelectors.getCurrentProjectId(state$.value);
        if(action.payload.datastoreId) {
            return of(ItemsActions.getItemsListRequest({
                projectId,
                datastoreId: action.payload.datastoreId,
                conditions: action.payload.conditions ? action.payload.conditions : []
            }));
        } else {
            return EMPTY;
        }
    })
);

export default [
    setCurrentDatastoreIdEpic
];