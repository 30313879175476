import StringUtils from "app/utils/stringUtils";
import { notification } from 'antd';
import i18n from "i18next";

interface Error {
    id?: string;
    message: string;
    time: number;
    origin?: string;
}

export class ErrorHandlerService {
    static errors = new Array<Error>();

    static displayError(errorId: string): void {
        const error = this.errors.find(err => err.id === errorId);
        if(!error) { return; }
        notification['error']({
            message: i18n.t(error.message),
            duration: 4000
        });
    }

    static addError(error: Error, display?: boolean): string {
        if (!error.id) { error.id = StringUtils.GenUUID(); }
        this.errors.unshift(error);

        if (display) { this.displayError(error.id); }

        return error.id;
    }

    static removeError(errorId: string): void {
        this.errors = this.errors.filter(err => err.id !== errorId);
    }
}
