import { createEpicMiddleware, combineEpics } from 'redux-observable';
import UserEpics from './user/epic';
import WorkspacesEpics from './workspace/epic';
import ProjectsEpics from './project/epic';
import ItemsEpics from './items/epic';
import DatastoreEpics from './datastore/epic';
import ItemDetailsEpics from './itemDetails/epic';
import GroupsEpics from './group/epic';

export const rootEpic = combineEpics(
    ...UserEpics,
    ...WorkspacesEpics,
    ...ProjectsEpics,
    ...DatastoreEpics,
    ...ItemsEpics,
    ...ItemDetailsEpics,
    ...GroupsEpics
);

const epicMiddleware = createEpicMiddleware();

export default epicMiddleware;