import { ItemDetails, ItemDetailsLabel, ItemDetailsRelation, ItemDetailsAction, ItemDetailsTitle, ItemDetailMode } from "./types";

export interface ItemDetailsState extends ItemDetails {
    loading: boolean;
    error: string;
}

export const initialState: ItemDetailsState = {
    labels: new Array<ItemDetailsLabel>(),
    post: {
        histories: null,
        unread: 0
    },
    fields: {},
    layout: {},
    entry: {
        access_keys: '',
        created_at: '',
        created_by: '',
        fields: {},
        d_id: '',
        i_id: '',
        p_id: '',
        rev_no: 0,
        title: '',
        unread: '',
        updated_at: '',
        updated_by: '',
        _id: '',
    },
    relations: new Array<ItemDetailsRelation>(),
    actions : new Array<ItemDetailsAction>(),
    statusActions : new Array<ItemDetailsAction>(),
    statuses : {
        statuses: {},
        statusOrderSettings: {}
    },
    titles : new Array<ItemDetailsTitle>(),
    loading: false,
    error: '',
    mode: ItemDetailMode.CLOSE
};
