import { combineReducers } from 'redux-starter-kit'
import { userSlice } from './user/slice'
import { mainSlice } from './main/slice'
import { workspacesSlice } from './workspace/slice'
import { projectsSlice } from './project/slice';
import { datastoresSlice } from './datastore/slice';
import { itemsSlice } from './items/slice';
import { itemDetailsSlice } from './itemDetails/slice';
import { groupsSlice } from './group/slice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  main: mainSlice.reducer,
  workspaces: workspacesSlice.reducer,
  projects: projectsSlice.reducer,
  datastores: datastoresSlice.reducer,
  items: itemsSlice.reducer,
  itemDetails: itemDetailsSlice.reducer,
  groups: groupsSlice.reducer,
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
