import React from 'react'
import { Table, Tag, Button, Icon, Modal, List, Spin } from 'antd';
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { ItemsSelectors, ItemsActions, ItemsDetailsActions, DatastoreSelector, WorkspaceSelectors } from 'app/services/store';
import ItemDetails from 'app/components/itemDetails/itemDetails'
import { ItemDisplayableEntry } from 'app/services/store/items/types';
import { TableEventListeners } from 'antd/lib/table';
import { DatastoreActions, ProjectSelectors, UsersSelectors } from 'app/services/store';
import styles from './itemList.module.css';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import HttpService from 'app/services/httpService/httpService'

interface ItemStateProps {
    itemsLoading: boolean;
    displayableEntries: ItemDisplayableEntry;
    currentProject: any;
    fields: any;
    items: any;
    datastore: any;
    workspaceID: any;
    projectID: any;
    currentPage: number;
    currentProjectID: any;
    currentDatastoreID: any;
    userinfo: any;
}

interface ItemListProps extends ItemStateProps {
    itemsLoading: boolean;
    displayableEntries: ItemDisplayableEntry;
    setCurrentItemId: Function;
    setCurrentPage: Function,
    setMode: Function;
    setCurrentDatastoreId: Function;
    formData: any;
    setURLstate: Function;
    getItemsListRequest: Function;
}

const displayedFields = [
    'ID','発注日時','納品日時','所属会社','所属部門','発注者','種別','調査対象','ステータス', "納品予定日", "住所",  "電話番号","担当者", "反社審査結果", "与信審査結果"
]

class ItemList extends React.Component<ItemListProps> {
    /**
         * @property {Array<{[k: string]: any}>} items     - The list of items to display
         * @property {Array<{
            title: string;
            dataIndex: string;
            key: string;
            id: string;
        }>} columns                                     - The columns related to the items
    */
    state: ItemDisplayableEntry = {
        items:  new Array<{[k: string]: any}>(),
        columns : new Array<{
            title: string;
            dataIndex: string;
            key: string;
            id: string;
        }>(),
        currentPage: this.props.currentPage,
        deleteList: [],
        visible: false,
        deleting: false
    };

    /** @property {boolean} propsUpdate   - flag noting if the props of the component has changed in order to trigger list update */
    propsUpdate = false;

    /**
     * @description Called on when react mounted the component, it will add the items to the state
     * if they were provided as props during construction
     *
     * @returns {void}
     */
    componentDidMount(): void {
        if(this.props.displayableEntries) {
            this.setEntries(this.props.displayableEntries);
        }
    }

    /**
     * @description Will set a flag to know if the props have change in order to update the
     * item list.
     *
     * @returns {boolean} Should the component be updated
     */
    shouldComponentUpdate(nextProps: ItemListProps): boolean {
        this.propsUpdate = (this.props !== nextProps);
        return true;
    }
    /**
     * @description Function called after an update, if the props where changed,
     * request an update on all items/columns set in state
     *
     * @returns {void}
     */
    componentDidUpdate(): void {
        // this.props.setCurrentDatastoreId({datastoreId:this.props.currentProject.datastores[0].d_id})
        if(this.propsUpdate) {
            this.setEntries(this.props.displayableEntries);
        }
    }

    /**
     * @description assign the entries to the variables used to display the item list
     *
     * @returns {void}
     */
    setEntries(entries: ItemDisplayableEntry): void {
        this.setState({
            items: entries.items,
            columns: entries.columns,
        });
    }

       /**
     * @param {{[k: string]: any}} row the row we clicked
     *
     * @description return a function that is called when a row is clicked
     *
     * @returns {TableEventListeners} Event triggered by Antd on Row click
     */


    isDisplayedField(displayID) {
        return displayedFields.includes(displayID)
    }

    getColumns() {
        let columnArr: Array<any> = []
        for ( let column of this.state.columns){
            if(column.title === '反社会性審査結果') {
                column.title = '反社審査結果';
                column.key = '反社審査結果';
            }
            if(!this.isDisplayedField(column.key)){continue}
                let data = {
                    title: column.title,
                    dataIndex: column.dataIndex,
                    width: this.getColumnWidth(column.title),
                    key: column.key,
                    id: column.id,
                    render: (text, record) => {
                        return <div className={styles['text']}>{this.getRender(text, record, column)}</div>
                      },
                    //   style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            }
            columnArr.push(data)
        }

        return this.sortColumns(columnArr)
    }

    getColumnWidth(title) {
        if(title === '所属会社') {
            return '12%'
        } else if (title === '所属部門') {
            return '15%'
        } else if (title === '調査対象' || title === '住所') {
            return '12%'
        } else {
            return 'auto'
        }
    }

    sortColumns(columnArr) {
        let sortedArr = [] as any;
        for(let orderItem of displayedFields){
            for( let data of columnArr){
                if(orderItem === data.title){
                    sortedArr.push(data)
                }
            }
        }
        return sortedArr
    }

    getRender(text, record, column) {
        if(column.title === 'ステータス') {
        return (
            <Tag className={styles['itemlist-table-tag']} color={this.getStatusColor(text)} key={column.id}>
                {text}
            </Tag>
        )
        } else if(column.title === '納品予定日') {
            return text ? moment(text).format('YYYY/MM/DD') : ''
        } else {
            return text
        }
    }

    hasAdminRole(): boolean {
        return this.props.userinfo.user_roles && this.props.userinfo.user_roles.some(r => r.role_name === 'admin' || r.role_name === 'リン・トラスト');
    }

    hasText(itemText, formText) {
        let regex = new RegExp(formText,'gi')
        return itemText.match(regex) !== null
    }

    getItems() {
        let items: Array<any> = [];
        for(let item of this.state.items){
            if(!this.itemFilteredOut(item)){
                items.push(item)
            }
        }
        return items
    }

    textSearchFields = ['所属会社','所属部門','住所', '担当者', '調査対象']

    itemFilteredOut(item) {
        if(!this.props.formData){return}
        let formKeys = Object.keys(this.props.formData)
        let filteredOut = false;
        for(let formInput of formKeys){
            if(this.props.formData[formInput] !== "" && this.textSearchFields.includes(formInput)){
                //NEED REGEX HERE
                if(!this.hasText(item[formInput], this.props.formData[formInput])){
                    filteredOut = true;
                }
                  continue
            }

            if(this.props.formData[formInput] !== "" && this.props.formData[formInput] !== item[formInput]){
                filteredOut = true
            }
        }
        return filteredOut
    }

    getStatuses() {
        for(let field of this.props.fields) {
            if(field.dataType === 'status') {
                return field.statuses
            }
        }
    }

    getStatusColor(value) {
        let statuses = this.getStatuses()
        for(let status of statuses) {
            if(status.name === value) {
                if(status.color === 'mossgreen' ) {
                    return 'darkgreen'
                } else if  (status.color !== '' && status.color !== 'default' && status.color) {
                    return status.color
                } else {
                    return 'lightgray'
                }
            }
        }
    }

    UNSAFE_componentWillUpdate(nextProps: ItemListProps): void {
    }

    onRowClick = (row: {[k: string]: any}): TableEventListeners => {
        return {
            onClick: (e: React.MouseEvent<Element, MouseEvent>): void => this.onItemClick(e, row),
        }
    }

    onItemClick = (e: React.MouseEvent<Element, MouseEvent>, itemDetails: {[k: string]: any}): void => {
        this.props.setCurrentItemId({itemId: itemDetails.i_id});
        // this.props.setURLstate(`/${this.props.workspaceID}/${this.props.projectID}/${this.props.datastore}${itemDetails.i_id}`)
    }

    onPageChanged = (page: any): void => {
        this.setState({ currentPage: page.current});
        window.scroll({top: 0, behavior: 'smooth'});
    }

    onSelectChange = deleteList => {
        // console.log('deleteList changed: ', deleteList);
        this.setState({ deleteList });
    };

    clearSelectedItems() {
        this.setState({deleteList: []});
    }

    openDeleteModal=()=> {
        this.setState({
            visible: !this.state.visible
        })
    }

    getDeleteItemList(type) {
        return this.state.deleteList.map(i => {
            let target = this.state.items.find(itm => itm.key === i);
            if(target && target.i_id) { return type === 'id' ? target.i_id : target; } 
        }).filter(Boolean);
    }

    deleteItem = () => {
        this.setState({deleting: true});
        const deleteIdList = this.getDeleteItemList('id');
        const taskList: Observable<string>[] = [];
        for(let id of deleteIdList) {
            taskList.push(this.deleteItemRequest(id));
        }
        forkJoin(taskList).subscribe(resultList => {
            // console.log(resultList);
            this.setState({
                deleting: false,
                visible: false,
                deleteList: []
            });
            this.props.getItemsListRequest({projectId: this.props.currentProjectID, datastoreId: this.props.currentDatastoreID, conditions: []});
        });
    }

    deleteItemRequest(id: string): Observable<any> {
        let url = `applications/${this.props.currentProjectID}/datastores/${this.props.currentDatastoreID}/items/delete/${id}`
        return HttpService.DeleteAsync(url, {}, 'linker-api');
    }
    
    render(): JSX.Element {
        const { deleteList } = this.state;
        const rowSelection = {
            deleteList,
            onChange: this.onSelectChange,
        };
        const showAdminItem = this.hasAdminRole() && {rowSelection: rowSelection};
        return (
            <div>
                {this.state.columns && this.props.datastore &&
                    <div className={styles['itemlist-table']}>
                        <Table
                            {...showAdminItem}
                            loading={this.props.itemsLoading}
                            dataSource={this.getItems()}
                            columns={this.getColumns()}
                            onRow={(row: {[k: string]: any}): TableEventListeners => this.onRowClick(row) }
                            pagination={{ pageSize: 25, current: this.state.currentPage}}
                            onChange={this.onPageChanged}
                            size='small'
                        />
                        {this.hasAdminRole() && <Button
                            className={styles["delete-btn"]}
                            disabled={this.state.deleteList.length < 1}
                            onClick={()=>this.setState({visible: true})}
                        >
                            <Icon type='close-circle' />選択した案件を削除
                        </Button>}
                    </div>}
                <ItemDetails setURLstate={this.props.setURLstate} isAdmin={this.hasAdminRole()}/>
                <Modal
                    visible={this.state.visible}
                    footer={[
                        <Button
                            key='delButtonCancel'
                            onClick={()=>this.openDeleteModal()}
                            disabled={this.state.deleting}>
                            キャンセル
                        </Button>
                        ,
                        <Button
                            key='delButtonDelete'
                            onClick={()=>this.deleteItem()}
                            disabled={this.state.deleting}
                            type="primary">
                            {this.state.deleting ? <Spin /> : '削除する'}
                        </Button>
                    ]}
                    onOk={this.deleteItem}
                    onCancel={this.openDeleteModal}
                    >
                        <span>選択した案件を削除します。よろしいですか？</span>
                        <List
                            split={false}
                            header={<div>削除する案件：</div>}
                            dataSource={this.getDeleteItemList('item')}
                            renderItem={item => (
                                <List.Item>
                                    <span className={styles["dellist-id"]}>{item.ID}</span>
                                    <span className={styles["dellist-type"]}>{item['種別']} / {item['ステータス']} / </span>
                                    {item['対象先'] && <span>{item['対象先']}</span>}
                                    {/* <span className={styles["dellist-status"]}></span> */}
                                </List.Item>
                            )}
                            className={styles["dellist"]}
                        />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState): ItemStateProps => ({
    itemsLoading: ItemsSelectors.getItemsIsLoading(state),
    displayableEntries: ItemsSelectors.getDisplayableEntries(state),
    currentProject: ProjectSelectors.getCurrentProject(state),
    fields: ItemsSelectors.getFields(state),
    items: ItemsSelectors.getItems(state),
    datastore: DatastoreSelector.getCurrentDatastoreId(state),
    workspaceID: WorkspaceSelectors.getCurrentWorkspaceId(state),
    projectID: ProjectSelectors.getCurrentProjectId(state),
    currentPage: ItemsSelectors.getCurrentPage(state),
    currentProjectID: ProjectSelectors.getCurrentProjectId(state),
    currentDatastoreID: DatastoreSelector.getCurrentDatastoreId(state),
    userinfo: UsersSelectors.getUserInfo(state)
})

const mapDispatchToProps = {
    setCurrentItemId: ItemsActions.setCurrentItemId,
    setMode: ItemsDetailsActions.setMode,
    setCurrentDatastoreId: DatastoreActions.setCurrentDatastoreId,
    setCurrentPage: ItemsActions.setCurrentPage,
    getItemsListRequest: ItemsActions.getItemsListRequest
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemList);