import { User } from "./types";

export interface UserState extends User {
    loading: boolean;
    error: string;
}

export const initialState: UserState = {
    auth_key: '',
    current_workspace_id: '',
    email: '',
    profile_pic: '',
    u_id: '',
    username: '',
    loading: false,
    error: '',
    ws_admin: false,
};