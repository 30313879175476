import { ofType } from "redux-observable";
import { map, catchError, switchMap } from 'rxjs/operators';
import { ProjectActions, ProjectSelectors, DatastoreActions, ItemsActions, ItemsDetailsActions } from "..";
import HttpService from "app/services/httpService/httpService";
import { of } from "rxjs";
import * as apiModels from "./epic.types";
import { Observable } from "redux";

const getProjectsEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ProjectActions.getProjectsRequest),
    switchMap(action =>
        HttpService.GetAsync<null, apiModels.api_get_applications_list_response>(`get_applications_list`).pipe(
            map(projects => {
                return ProjectActions.getProjectsSuccess({projects : projects.data || []});
            }),
            catchError((error: string) => {
                return of(ProjectActions.getProjectsFailed({error}));
            })
        )
    )
);

const setCurrentProjectIdEpic = (action$, state$): Observable<any> => action$.pipe(
    ofType(ProjectActions.setCurrentProjectId),
    switchMap(action => {
        const project = ProjectSelectors.getCurrentProject(state$.value);
        return of(
            DatastoreActions.reset(),
            ItemsActions.reset(),
            ItemsDetailsActions.reset(),
            DatastoreActions.getDatastoresSuccess({datastores : (project ? project.datastores : [])})
        )
    })
);

export default [
    getProjectsEpic,
    setCurrentProjectIdEpic
];