import React from 'react'
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './surveyForm.module.css';
import { Radio, Checkbox, Upload, Button, Icon, Input, DatePicker, Spin, Select, message, Row, Col } from 'antd';
import HttpService  from 'app/services/httpService/httpService'
import { ProjectSelectors, DatastoreSelector, UsersSelectors, WorkspaceSelectors, ItemsSelectors , ItemsActions, ItemDetailsSelectors} from 'app/services/store';
import AuthHelper from 'app/services/authHelper/authHelper';
import { UploadFile, RcFile, UploadChangeParam  } from 'antd/lib/upload/interface';
import  FieldsHelper  from 'app/utils/fieldsHelper';
import FileField from 'app/components/fields/fileField'
import SubmitPopup from './submitPopup/submitPopup';
import { uploadLimit } from 'app/constants';

const { TextArea } = Input;

const { Option } = Select;

interface surveyFormProps extends WithTranslation {
    formType: string; //COMPANY, INDIVIDUAL, ALL
    datastore: any;
    project: any;
    workspace: any;
    user: any;
    fields: any;
    setActiveTab: Function;
    username: any;
    itemDetails: any;
    items: any;
    addEntry: Function;
    userEmail: string;
}

class surveyForm extends React.Component<surveyFormProps> {
    state = {
        renders : {
            [FieldsHelper.fields.file.value] : FileField,},
        value: new Array<string>(),
        files: new Array<UploadFile>(),
        loading: false,
        itemID: '',
        showPopup: false,
        sending: false
    }
    initialSate;
    constructor(props){
        super(props)
        this.initialSate = this.state
    }

    payload = {}

    idsToSkip = ['fileSelected','files', 'loading', 'value','itemID','renders','showPopup','sending', '電話番号1', '電話番号2', '電話番号3', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3']

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.formType != this.props.formType){
            this.clearState()
        }
    }

    clearState = ()=> {
        const stateKeys = Object.keys(this.state)
        for(const key of stateKeys){
            this.state[key] = (''||null)
        }

        this.state = this.initialSate
        this.setState({
            renders : {
                [FieldsHelper.fields.file.value] : FileField,},
            value: new Array<string>(),
            files: new Array<UploadFile>(),
            loading: false,
            itemID: '',
            showPopup: false,
            sending: false
        })
    }

    async beforeUploading(file, fileList) {
        if(file.size < uploadLimit) {
            if(!this.state['fileSelected']){
                await this.getNewItemID().then((res)=>{
                    this.setState({
                        itemID: res,
                        fileSelected: true
                    })
                })
            }
        } else {
            message.error('ファイルサイズが大きすぎます。10MB以下にしてください。')
        }
    }

    numOnly(e, id) {
        const { value } = e.target;
        if (!isNaN(value)|| value === '' || value === '-') {
            this.setState({
                [id]: value
            })
        }
    }

    handleUploadChange(info) {
        let files = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        files = files.slice(-1);

        // 2. Read from response and show file link
        files = files.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });

        this.setState({ files });
      }

      getSelectOptions(displayID){
        for(const field of this.props.fields){
            if(field.displayID === displayID){
                 return field.options.filter(opt => opt.enabled);
            }
        }}

    getInput(labelName, required, placeholder, type: string, inputType, id1?, id2?, id3?) {

        if(type === 'ALL' || type === this.props.formType) {
        return (
            <Row type='flex' className={styles["input-container"]}>
                <Col span={5} style={{ textAlign: 'end' }}>
                    <label className={styles["label"]}>{labelName}</label>
                    <span className={styles["asterisk"]}>{required && "*"}</span>
                </Col>
                <Col offset={1} span={18}>
                    {inputType === 'select' &&
                        <div style={{'width': '100%'}}>
                            <Select value={this.state[id1]} onChange={(e)=>{this.setState({[id1]: e})}}>
                                        {this.getSelectOptions(id1) && this.getSelectOptions(id1).map(opt => <Option title={id1} value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                            </Select>
                        </div>}
                    {inputType === 'katakana' &&
                        <div style={{'width': '100%'}}>
                            <Input placeholder={placeholder} value={this.state[id1]} className={this.getInputClass(id1, 'null')} onChange={(e)=>this.onInputChange(e, id1)}/>
                            {this.katakanaValidtion(this.state[id1]) && <span style={{'color': 'red'}}>全角カタカナを入力してください</span>}
                        </div>}
                    {inputType === 'phone' &&
                        <div className={styles["phone-inputs"]}>
                            <Input maxLength={4} placeholder={'03'} value={this.state[id1]} className={this.getInputClass(id1, 'null')} onChange={(e)=>this.numOnly(e, id1)}/>
                            <span style={{'marginLeft': '5px', 'marginRight': '5px', 'fontSize': '18px', 'fontWeight': 'bold'}}>-</span>
                            <Input maxLength={4} placeholder={'1234'} value={this.state[id2]} className={this.getInputClass(id2, 'null')} onChange={(e)=>this.numOnly(e, id2)}/>
                            <span style={{'marginLeft': '5px', 'marginRight': '5px', 'fontSize': '18px', 'fontWeight': 'bold'}}>-</span>
                            <Input maxLength={4} placeholder={'5678'} value={this.state[id3]} className={this.getInputClass(id3, 'null')} onChange={(e)=>this.numOnly(e, id3)}/>
                        </div>}
                    {inputType === 'email' &&
                        <div style={{'width': '100%'}}>
                            <Input placeholder={placeholder} value={this.state[id1]} className={this.getInputClass(id1, 'null')} onChange={(e)=>this.onInputChange(e, id1)}/>
                            {this.emailValidation(this.state[id1]) === null && <span style={{'color': 'red'}}>有効なメールアドレスを入力してください</span>}
                        </div>}
                    {inputType === 'text' &&
                        <Input placeholder={placeholder} value={this.state[id1]} className={this.getInputClass(id1, 'null')} onChange={(e)=>this.onInputChange(e, id1)}/>}
                    {inputType === 'textarea' && <TextArea placeholder={placeholder} className={this.getInputClass(id1, 'null')} autosize={{ minRows: 4, maxRows: 10 }} value={this.state[id1]} onChange={(e)=>this.onInputChange(e, id1)} />}
                    {inputType === 'textarea_s' && <TextArea placeholder={placeholder} className={[this.getInputClass(id1, 'null'), 'textarea_s'].join('')} autosize={{ minRows: 4, maxRows: 10 }} value={this.state[id1]} onChange={(e)=>this.onInputChange(e, id1)} />}
                    {inputType === 'date' && <DatePicker className={this.getInputClass(id1, 'null')}  value={this.state[id1]} onChange={(moment, momentString)=>this.onInputChange(momentString, id1, moment)}/>}
                    {inputType === 'file' && this.showUploadButton() &&
                        <Upload
                            // disabled={this.state.files.length === 1}
                            multiple={false}
                            listType="text"
                            fileList={this.state.files}
                            action={HttpService.getBaseUrl(`new_item_file_attachment`)}
                            onRemove={(file: UploadFile): void => this.removeFile(file.uid)}
                            headers={{"Authorization": `Bearer ${AuthHelper.getAuthKey()}`}}
                            beforeUpload={(file, fileList)=>this.beforeUploading(file, fileList)}
                            onChange={(info: UploadChangeParam<UploadFile>): void => {this.uploadingStateChange(info)}}
                            data={(file: RcFile) => this.getUploadParams(file, this.state.files.length)}>
                            <Button className={styles["file-input"]} >
                                ファイルを選択<Icon type="paper-clip" />
                            </Button>
                        </Upload>
                    }
                </Col>

            </Row>
        )
        } else {return}
    }

    showUploadButton() {
        const id = '審査対象関連資料'
        if(this.state[id] !== '特になし') {
            return true
        } else {
            return false
        }
    }

    getInputClass(idText, defaultClass) {
        const requiredFieldsALL = [
            '住所', '氏名', '所属会社', '発注者上長氏名','上長メールアドレス', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3','調査対象','フリガナ', '代表者名'
       ]
       const stateKeys = Object.keys(this.state)
       if((!stateKeys.includes(idText) && requiredFieldsALL.includes(idText)) || !this.state[idText] && requiredFieldsALL.includes(idText)){ //add for key value '' too
            return styles['input-validation']
       } else {
           return styles[defaultClass]
       }
    }

    katakanaValidtion(stateVal){
        if(!stateVal){return}
        const regex = /^([ァ-ン]|ー)+$/
        return !stateVal.match(regex)
    }

    emailValidation(stateVal) {
        if(!stateVal){return false}
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return stateVal.match(regex)
    }

    validation() {
        const stateKeys = Object.keys(this.state)
        const required重要契約Fields = [
            // '承諾事項', 
            // this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU'),
            '事前承認事項',
            '請求先部門コード',
            '請求先',
            '調査対象','住所', '氏名', '所属会社', '発注者上長氏名','上長メールアドレス', '発注者メールアドレス', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3','代表者名']

        const requiredIndividualFields =[ '住所',
            '請求先部門コード',
            '事前承認事項',
            '請求先',
            '氏名', '所属会社', '発注者上長氏名', '上長メールアドレス', '発注者メールアドレス', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3','調査対象'];

        const requiredCompanyFields = [ '住所', 
            '請求先部門コード',
            '事前承認事項',
            '請求先',
            '氏名', '所属会社', '発注者上長氏名', '上長メールアドレス', '発注者メールアドレス', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3','代表者名', '承諾事項(法人)' ];

        var tovalid = [
            this.props.t('COMPANY.FORM.CHOSA_HIYO'),
            this.props.t('COMPANY.FORM.HATCHU'),
            this.props.t('COMPANY.FORM.JOKI'),
        ];

        // 重要契約
        var tovalidcompany = [
            this.props.t('COMPANY.FORM.KEIYAKU_SHIKI'),
            this.props.t('COMPANY.FORM.HATCHU'),
            this.props.t('COMPANY.FORM.JOKI'),
            this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')
        ];

        if(this.props.formType === '重要契約'){
            tovalid.push(this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU'))
            for(const id of required重要契約Fields){
                if(!this.state[id] || !stateKeys.includes(id)){
                    return true
                }

                if((id === '事前承認事項' &&
                    tovalidcompany.every(x => this.state['事前承認事項'].includes(x)) === false) ||
                    this.state[id].length === 0){
                    return true
                }

                if(id === '請求先部門コード' && this.state[id] && this.state[id].length === 0){
                    return true
                }
                if(id === '審査対象関連資料' && this.state[id] !== '特になし' && this.state.value.length === 0){
                    return true
                }
                if(id === 'フリガナ' && this.katakanaValidtion(this.state[id]) === null){
                    return this.katakanaValidtion(this.state[id])
                }
                if((id === '上長メールアドレス' || id === '発注者メールアドレス') && this.emailValidation(this.state[id]) === null){
                    return true
                }  else {
                   continue
                }
            }
            return false
        } else if(this.props.formType === 'INDIVIDUAL'){
            for(const id of requiredIndividualFields){

                if(!this.state[id] || !stateKeys.includes(id)){
                    return true
                }

                if((id === '事前承認事項' &&
                    tovalid.every(x => this.state['事前承認事項'].includes(x)) === false) ||
                    this.state[id].length === 0){
                    return true
                }
                if(id === '請求先部門コード' && this.state[id] && this.state[id].length === 0){
                    return true
                }                

                if(id === '審査対象関連資料' && this.state[id] !== '特になし' && this.state.value.length === 0){
                    return true
                }
                if(id === 'フリガナ'){
                    return this.katakanaValidtion(this.state[id])
                }
                if((id === '上長メールアドレス' || id === '発注者メールアドレス') && this.emailValidation(this.state[id]) === null){
                    return true
                } else {
                   continue
                }
            }
            return false
        } else if(this.props.formType === 'COMPANY'){
            for(const id of requiredCompanyFields){
                if(id === '承諾事項(法人)'){
                    return this.companyCheckboxValidation()
                }

                if(!this.state[id] || !stateKeys.includes(id)){
                    return true
                }

                if((id === '事前承認事項' &&
                    tovalid.every(x => this.state['事前承認事項'].includes(x)) === false) ||
                    this.state[id].length === 0){
                    return true
                }

                if(id === '請求先部門コード' && this.state[id] && this.state[id].length === 0){
                    return true
                }

                if(id === '審査対象関連資料' && this.state[id] !== '特になし' && this.state.value.length === 0){
                    return true
                }
                if(id === 'フリガナ' && this.katakanaValidtion(this.state[id]) === null){
                    return this.katakanaValidtion(this.state[id])
                }
                if((id === '上長メールアドレス' || id === '発注者メールアドレス') && this.emailValidation(this.state[id]) === null){
                    return true
                } else {
                   continue
                }
            }
            return false
        }
        return true
    }

    companyCheckboxValidation() {
        if(!this.state['事前承認事項'] || (this.state['事前承認事項'] && this.state['事前承認事項'].length === 0)){return false}
        // if(!this.state['承諾事項(法人)'] || (this.state['承諾事項(法人)'] && this.state['承諾事項(法人)'].length < 1)){return true}
        return false
    }

    getUploadParams(file: RcFile, nbFiles: number) {
        const fileFieldID = this.getFileFieldID()
        return {
            filename: file.name,
            filepath: `${this.props.datastore}/${this.state.itemID}/${fileFieldID}/${file.name}`,
            field_id: fileFieldID,
            item_id: this.state.itemID || '',
            d_id: this.props.datastore || '',
            p_id: this.props.project || '',
            display_order: nbFiles
        }
    }

    uploadingStateChange(e: UploadChangeParam<UploadFile>): void {
        let fileList = e.fileList
        fileList = fileList.slice(-1);

        let values = this.state.value;
        if(e.file.status === 'done') {
            values = [...values, ...[e.file.response.file_id]];
        }
        this.setState({
            files: fileList,
            value : values
        });
    }

    async getNewItemID() {
        let data;
        let newID ;
        await this.getNewID().then((res)=>{
            data = res
        })
        newID = data.data.item_id
        return newID
    }

    removeFile(fileId: string): void {
        this.setState({
            files: [],
            value: []
        });
    }

    onRadioChange(e) {
        this.setState({
            審査対象関連資料: e.target.value
        })
        if(e.target.value === '特になし'){
            this.setState({
                value: [],
                files: [],
            })
        }
    }

    onCheckboxChange(e, checkboxGroup) {
        this.setState({
            [checkboxGroup]: e
        })
    }

    getNewID(){
        return new Promise(resolve=> {
            HttpService.GetAsync('get_new_item_id')
             .subscribe(
                (data: any) => {
                    resolve(data)
             })
        })
    }

    getFieldbyFieldDisplayID(display_id) {
        for(const field of this.props.fields){
            if(display_id === field.displayID){
                return field
            }
        }
    }

    getStateFieldValue(field) {
        const itemState = Object.keys(this.state)
        for (const item of itemState){
            if(item === field.displayID){
                if(field.dataType === 'text' || field.dataType === 'date' || field.dataType === 'textarea'){
                    return this.state[item]
                } else if (field.dataType === 'radio'){
                    return this.getOptionId(field.options, this.state[item])
                } else if (field.dataType === 'checkbox'){
                    return this.pairOptions(field.options, this.state[item] || [])
                } else if (field.dataType === 'select'){
                    return this.selectVal(field, this.state[item])
                } else if (field.dataType === 'file'){
                    return this.state[item]
                }

            }
        }
    }

    selectVal(field, item){
        for(const option of field.options){
            if(option.value === item){
                return option.o_id
            }
        }
    }

    pairOptions(options, optionsValArr) {
        const arr: any = [];
        const optionsKeys = Object.keys(options)
        for(const option of optionsKeys){
            for(const selectedOption of optionsValArr){
                if(selectedOption === options[option].value){
                    arr.push(options[option].o_id)
                }
            }
        }
        return arr
    }

    getFieldType(field) {
        if(!field)return
        const itemState = Object.keys(this.state)
        for (const item of itemState){
            if(item === field.displayID){
                return field.dataType
            }
        }
    }

    setFormType(){
        this.setState({
            種別: (this.props.formType === 'COMPANY') ?  '法人' : this.props.formType === 'INDIVIDUAL' ? '個人' : this.props.formType === '重要契約' ? '重要契約' : 'null'
        },)

    }

    onInputChange(e,id, moment?) {
        if (moment){
            this.setState({
                [id]: moment
            })
        } else {
            this.setState({
                [id]: e.target ? e.target.value : null
            })
        }
    }

    async submitForm() {
        await this.setFormType()
        const fileFieldID = this.getFileFieldID()
        let data;
        let newID;
        if(this.state.itemID === '') {
            await this.getNewID().then((res)=>{
                data = res
                newID = data.data.item_id
            })
        } else {
            newID = this.state.itemID
        }


        const newItem: {
            [x: number]: string[] | string ;d_id: string; p_id: string;} = {
            d_id: '',
            p_id: '',
            [fileFieldID]: this.state.value,
            [this.getFieldbyFieldDisplayID('登録者メールアドレス').field]: this.props.userEmail,
            [this.getFieldbyFieldDisplayID('発注者').field]: this.props.username
        }

        const itemsKeys = Object.keys(this.state)
        for (const item of itemsKeys){
            if(this.idsToSkip.includes(item)){continue}
            if(item === '種別'){
                const field = this.getFieldbyFieldDisplayID(item)
                newItem[field.field] = this.getFormTypeOptionID()
                continue
            }
            const field = this.getFieldbyFieldDisplayID(item)
            // TODO
            if(field && field.file != undefined){ continue} //newItem['file'] = this.state.file;
            if(field) newItem[field.field] = this.getStateFieldValue(field)
        }

        const 発注者電話番号id = this.getFieldbyFieldDisplayID('発注者電話番号').field
        newItem[発注者電話番号id] = this.state['発注者電話番号1'] +'-'+ this.state['発注者電話番号2'] +'-'+ this.state['発注者電話番号3']
        newItem.d_id= this.props.datastore;
        newItem.p_id = this.props.project;
        this.setState({
            発注者電話番号: this.state['発注者電話番号1'] === undefined ? '' : this.state['発注者電話番号1'] +'-'+ this.state['発注者電話番号2'] +'-'+ this.state['発注者電話番号3'],

        })

        if(this.state['電話番号1'] != undefined && this.state['電話番号2'] != undefined &&  this.state['電話番号3'] != undefined && this.state['電話番号1'] != '' && this.state['電話番号2'] != '' && this.state['電話番号3'] != ''){
            const 電話番号id = this.getFieldbyFieldDisplayID('電話番号').field
            newItem[電話番号id] = this.state['電話番号1'] +'-'+ this.state['電話番号2'] +'-'+ this.state['電話番号3']
            this.setState({
                電話番号: this.state['発注者電話番号1'] === undefined || this.state['発注者電話番号2'] === undefined  || this.state['発注者電話番号3'] === undefined  ? '' : this.state['電話番号1'] +'-'+ this.state['電話番号2'] +'-'+ this.state['電話番号3']
            })
        }

        newItem[this.getFieldbyFieldDisplayID("所属部門").field] = this.state['所属部門'];

        const payload = {
            item: newItem,
            d_id: this.props.datastore,
            p_id: this.props.project,
            w_id: this.props.workspace,
            user_id: this.props.user,
            item_id: newID

        }
        this.payload = payload
        this.setState({
            showPopup: true
        })
    }

    addItem(result, payload){
        const fields = {};
        const itemsKeys = Object.keys(this.state)
        for (const item of itemsKeys){
            if(this.idsToSkip.includes(item)){continue}

            const field = this.getFieldbyFieldDisplayID(item)
            fields[field.field] = this.state[item]
        }

        const entry = {
                    access_keys: '',
                    created_at: Date.now().toString(),
                    created_by: this.props.username,
                    fields: fields,
                    d_id: payload.d_id,
                    i_id: result.data.id,
                    p_id: payload.p_id,
                    rev_no: 0,
                    title: '',
                    unread: "0",
                    updated_at: Date.now().toString(),
                    updated_by: this.props.username,
                    _id: result.data.id
                }

            this.props.addEntry({
                entry: entry
            })
    }

    getFormTypeOptionID(){
        for(const field of this.props.fields){
            if(field.displayName === "種別") {
                const formTranslate = this.props.formType === 'COMPANY' ? '法人' : this.props.formType === 'INDIVIDUAL' ? '個人' : '重要契約'
                return this.getOptionId(field.options, formTranslate)
            }
        }
    }

    getFileFieldID() {
        for(const field of this.props.fields) {
            if(field.displayID === 'file'){
                return field.field
            }
        }
    }

    getOptionId(options, optionVal) {
        const optionsKeys = Object.keys(options)
        for( const option of optionsKeys){
            if(optionVal === options[option].value){
                return options[option].o_id
            }
        }
    }

    // FC = 重要契約

    render(): JSX.Element {
        return (
            <div className={styles["mainWrapper"]}>
                {this.props && <h1 className={styles["title"]}>
                    {this.props.t('COMPANY.FORM.'+this.props.formType+'_FORM')}
                </h1> }
                {!this.state.showPopup && <form className={styles["form-container"]}>
                    {/* 1st Section */}
                    <Row className={styles['form-step-container']}>
                        <Col className={styles['left-col-1']}>
                            <div className={styles['left-col-num']}>01</div>
                            <div className={styles['left-col-text']}>{this.props.t('COMPANY.FORM.SIDE_1_' + this.props.formType)}</div>
                        </Col>
                        <Col className={styles['right-col-1']}>
                            <div className={styles["form-spacing"]}>
                            {/* getInput(labelName, required, placeholder, type: string,  inputType, id?) { */}
                                {/* INDIVIDUAL */}
                                {this.getInput('氏名', true, '入力してください', 'INDIVIDUAL', 'text', '調査対象')}
                                {this.getInput('フリガナ', false, '入力してください', 'INDIVIDUAL', 'katakana', 'フリガナ')}
                                {this.getInput('生年月日', false, '入力してください', 'INDIVIDUAL', 'date', '生年月日')}
                                {this.getInput('現住所', true, '入力してください', 'INDIVIDUAL', 'text', '住所')}
                                {this.getInput('屋号', false, '入力してください', 'INDIVIDUAL', 'text', '屋号')}
                                {this.getInput('　　　その他関連者情報※複数記入可', false, '入力してください', 'INDIVIDUAL', 'textarea', 'その他')}
                                {this.getInput('電話番号', false, '1234', 'INDIVIDUAL', "phone", '電話番号1', '電話番号2', '電話番号3')}

                                {/* COMPANY */}
                                {this.getInput('法人名', true, '社名前後の株式会社など、組織形態もご記入ください', 'COMPANY', 'text', '調査対象')}
                                {this.getInput('所在地', true, '入力してください', 'COMPANY', "text", '住所')}
                                {this.getInput('代表者名', true, '代表者もしくは審査対象者氏名', 'COMPANY', "text", '代表者名')}
                                {this.getInput('会社HP', false, 'ホームページURLを入力してください', 'COMPANY', "text", '会社HP')}
                                {/* {this.getInput('　　　その他関連者情報※複数記入可', false, '入力してください', 'COMPANY', 'textarea', 'その他')} */}
                                {/* {this.getInput('請求先部門コード ', true, '「請求先の[部門コード]を入力してください」', 'ALL', "text", '請求先部門コード ')} */}
                                {this.getInput('電話番号', false, '1234', 'COMPANY', "phone", '電話番号1', '電話番号2', '電話番号3')}

                                {/* 重要契約 */}
                                {this.getInput('対象名', true, '社名前後の株式会社など、組織形態もご記入ください', '重要契約', 'text', '調査対象')}
                                {this.getInput('所在地', true, '入力してください', '重要契約', "text", '住所')}
                                {this.getInput('代表者名', true, '代表者もしくは審査対象者氏名', '重要契約', "text", '代表者名')}
                                {this.getInput('会社HP', false, 'ホームページURLを入力してください', '重要契約', "text", '会社HP')}
                                {this.getInput('　　　その他関連者情報※複数記入可', false, '入力してください', '重要契約', 'textarea', 'その他')}
                                {this.getInput('電話番号', false, '1234', '重要契約', "phone", '電話番号1', '電話番号2', '電話番号3')}

                            </div>
                        </Col>
                    </Row>
                    {/* Second section */}
                    <Row className={styles['form-step-container']}>
                        <Col className={styles['left-col-2']}>
                            <div className={styles['left-col-num']}>02</div>
                            <div className={styles['left-col-text']}>{this.props.t('COMPANY.FORM.SIDE_2')}</div>
                        </Col>
                        <Col className={styles['right-col-2']}>
                            <div className={styles["form-spacing"]}>
                                {this.getInput('氏名', true, '入力してください', 'ALL', 'text', '氏名')}
                                {this.getInput('発注者メールアドレス', true, '発注者のメールアドレスを入力してください', 'ALL', 'email', '発注者メールアドレス')}
                                {/* {this.getInput('所属会社・所属部門', true, '入力してください', 'ALL', 'text', '所属会社・所属部門')} */}

                                {this.getInput('所属会社', true, '', 'ALL', 'select', '所属会社')}
                                {this.getInput('所属部門', false, '入力してください', 'ALL', 'text', '所属部門')}

                                {this.getInput('発注者上長氏名', true, '入力してください', 'ALL', 'text', '発注者上長氏名')}
                                {this.getInput('上長メールアドレス', true, '入力してください', 'ALL', 'email', '上長メールアドレス')}
                                {this.getInput('電話番号', true, '1234', 'ALL', 'phone', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3')}
                            </div>
                        </Col>
                    </Row>
                    {/* Third section */}
                    <Row className={styles['form-step-container']}>
                        <Col className={styles['left-col-3']}>
                            <div className={styles['left-col-num']}>03</div>
                            <div className={styles['left-col-text']}>{this.props.t('COMPANY.FORM.SIDE_3')}</div>
                        </Col>
                        <Col className={styles['right-col-3']}>
                            <div className={styles["form-spacing"]}>
                                {this.getInput('取引内容', false, '取引に関する詳細。取引に至った経緯や取引にあたって気になる点など', 'ALL', 'textarea_s', '取引内容')}
                                {this.getInput('請求先', true, ' [社名][部門名][宛先氏名]の３つを入力してください', 'ALL', "text", '請求先')}
                                {this.getInput('請求先部門コード', true, '請求先の[部門コード]を入力してください', 'ALL', "text", '請求先部門コード')}
                                {this.getInput('審査対象関連資料', false, 'ファイルを選択', 'ALL', 'file', 'file')}
                                <Row>
                                    <Col offset={6} span={18}>
                                        <Checkbox.Group onChange={(e)=>{this.onCheckboxChange(e, '審査対象関連資料')}}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <Checkbox  className={styles['checkbox']} value={this.props.t('会社登記簿')}>{this.props.t('COMPANY.FORM.REGISTRY')}</Checkbox>
                                                <Checkbox  className={styles['checkbox']} value={this.props.t('会社案内')}>{this.props.t('COMPANY.FORM.PROFILE')}</Checkbox>
                                                <Checkbox  className={styles['checkbox']} value={this.props.t('名刺')}>{this.props.t('COMPANY.FORM.CARD')}</Checkbox>
                                                <Checkbox  className={styles['checkbox']} value={this.props.t('その他')}>{this.props.t('COMPANY.FORM.OTHER')}</Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Col>
                                    <Col offset={6} span={18} style={{ fontSize: '11px'}}>
                                        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                            ※審査対象に関わる情報があれば添付してください
                                        </p>
                                        <p style={{ marginLeft: 10 }}>
                                            資料が複数ある場合はZIPファイルなどにより一つのファイルにまとめて送信してください
                                        </p>
                                    </Col>
                                </Row>
                                {/* <Radio.Group onChange={(e)=>this.onRadioChange(e)} size="large" className={this.getInputClass('審査対象関連資料', 'test')} defaultValue="会社登記簿">
                                    <Radio className={styles['radio-input']} name="test1" value={"会社登記簿"}>{this.props.t('COMPANY.FORM.REGISTRY')} </Radio>
                                    <Radio className={styles['radio-input']} name="test2"  value={"会社案内"}>{this.props.t('COMPANY.FORM.PROFILE')} </Radio>
                                    <Radio className={styles['radio-input']}  value={"名刺"}>{this.props.t('COMPANY.FORM.CARD')}</Radio>
                                    <Radio className={styles['radio-input']} value={"その他"}>{this.props.t('COMPANY.FORM.OTHER')}</Radio>
                                    <Radio className={styles['radio-input']} value={"特になし"}>{this.props.t('COMPANY.FORM.NOTHING')}</Radio>
                                </Radio.Group> */}

                                {(this.props.formType === 'COMPANY' || this.props.formType === 'INDIVIDUAL') &&
                                    <Row style={{ margin: '30px 60px 0' }}>
                                        <Row style={{ fontWeight: 'bold', fontSize: 11 }}>
                                            ※{this.props.t('COMPANY.FORM.OBTAIN_MATERIALS')}
                                        </Row>
                                        <Row>
                                            <Checkbox.Group 
                                                className={styles['verticalCheckbox']}
                                                onChange={(e) => { this.onCheckboxChange(e, '事前承認事項') }}>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.CHOSA_HIYO')}>{this.props.t('COMPANY.FORM.CHOSA_HIYO')}</Checkbox>
                                                    </Row>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.HATCHU')}>{this.props.t('COMPANY.FORM.HATCHU')}</Checkbox>
                                                    </Row>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.JOKI')}>{this.props.t('COMPANY.FORM.JOKI')}</Checkbox>
                                                    </Row>
                                                    {/* {this.props.formType === 'COMPANY' &&
                                                        <Row>
                                                            <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}>
                                                                {this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}
                                                            </Checkbox>
                                                        </Row>
                                                    } */}
                                            </Checkbox.Group>
                                        </Row>
                                    </Row>
                                }

                                {(this.props.formType === '重要契約') &&
                                    <Row style={{ margin: '30px 60px 0' }}>
                                        <Row style={{ fontWeight: 'bold', fontSize: 11 }}>
                                            ※{this.props.t('COMPANY.FORM.OBTAIN_MATERIALS')}
                                        </Row>
                                        <Row>
                                            <Checkbox.Group 
                                                className={styles['verticalCheckbox']}
                                                onChange={(e) => { this.onCheckboxChange(e, '事前承認事項') }}>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.KEIYAKU_SHIKI')}>{this.props.t('COMPANY.FORM.KEIYAKU_SHIKI')}</Checkbox>
                                                    </Row>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.HATCHU')}>{this.props.t('COMPANY.FORM.HATCHU')}</Checkbox>
                                                    </Row>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.JOKI')}>{this.props.t('COMPANY.FORM.JOKI')}</Checkbox>
                                                    </Row>
                                                    <Row>
                                                        <Checkbox className={styles['checkbox']} value={this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}>{this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')} </Checkbox>
                                                    </Row>
                                            </Checkbox.Group>
                                        </Row>
                                    </Row>
                                }

                                {/* {this.state['事前承認事項'] && this.state['事前承認事項'].length > 0 &&
                                    <Row style={{ fontWeight: 'bold', margin: '0 50px 30px' }}>
                                        <Checkbox.Group onChange={(e) => { this.onCheckboxChange(e, '承諾事項(法人)') }}>
                                            <Checkbox value={'※資料取得については別途料金が発生することを了承しました'}>
                                                ※資料取得については別途料金が発生することを了承しました
                                                <span className={styles["asterisk"]}>*</span>
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </Row>} */}
                                {/* <div>
                                    <div>
                                        <span style={{display:'flex', fontWeight: 'bold', margin: '30px 60px 10px', fontSize: '11px'}}>※複数ファイル添付不可
                                        <br></br>
                                        資料が複数ある場合はZIPファイルなどにより一つのファイルにまとめて送信してください</span>
                                    </div>
                                    {this.state['事前承認事項'] && this.state['事前承認事項'].length > 0 && <div>
                                        <Checkbox.Group onChange={(e)=>{this.onCheckboxChange(e, '承諾事項(法人)')}}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <span className={styles["asterisk"]}>*</span>
                                                <Checkbox value={'※資料取得については別途料金が発生することを了承しました'}>※資料取得については別途料金が発生することを了承しました</Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </div>}
                                </div> */}
                                {/* {this.props.formType === '重要契約' && <div style={{ marginTop: '25px' }}>
                                    <Row style={{ fontWeight: 'bold', margin: '20px 50px' }}>
                                        <Checkbox.Group onChange={(e) => { this.onCheckboxChange(e, '承諾事項') }}>
                                            <Checkbox value={"※重要契約審査については別途審査料金が発生することを了承しました"}>
                                                重要契約審査については別途審査料金が発生することを了承しました
                                                <span className={styles["asterisk"]}>*</span>
                                            </Checkbox>
                                        </Checkbox.Group>

                                        <Checkbox.Group onChange={(e) => { this.onCheckboxChange(e, this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')) }}>
                                            <Checkbox style={{marginLeft: '0px'}} value={this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}>
                                                {this.props.t('COMPANY.FORM.TORIHIKI_KINGAKU')}
                                                <span className={styles["asterisk"]}>*</span>
                                            </Checkbox>                                            
                                        </Checkbox.Group>
                                    </Row>
                                </div>} */}
                            </div>
                        </Col>
                    </Row>
                    {/* SUBMIT */}
                    <div  className={styles["center-item"]}>
                        {!this.state.sending && <Button disabled={this.validation()} className={styles["submitButton"]} onClick={()=>{this.submitForm()}}>{this.props.t('COMPANY.FORM.CONFIRM')}</Button>}
                        { this.state.sending && <Spin />}
                    </div>
                    <div>
                        ※キャンセルについて※ 　<br />
                        審査発注後、対象先の審査ステイタスが『受注確認済』に変わるまでは、発注のキャンセルが可能です。 　<br />
                        ステイタスが『審査中』に変わった時点でキャンセルは不可となります。 　<br />
                        キャンセル方法については、ログイン後の案件一覧画面上の個別案件詳細画面からキャンセルボタンで 　実行する事が可能です。 <br />
                    </div>

                </form> }
                { this.state.showPopup && <SubmitPopup
                                            payload={this.payload}
                                            formType={this.props.formType}
                                            setActiveTab={this.props.setActiveTab}
                                            clearState={this.clearState}
                                            state={this.state}
                                            closePopup={() => this.clearState() } />}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    project: ProjectSelectors.getCurrentProjectId(state),
    datastore: DatastoreSelector.getCurrentDatastoreId(state),
    user: UsersSelectors.getUserId(state),
    username: UsersSelectors.getUsername(state),
    workspace: WorkspaceSelectors.getCurrentWorkspaceId(state),
    fields: ItemsSelectors.getFields(state),
    itemDetails: ItemDetailsSelectors.getEntry(state),
    items: ItemsSelectors.getItems(state),
    userEmail: UsersSelectors.getUserEmail(state)
})

const mapDispatchToProps = {
    addEntry: ItemsActions.addEntry
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(surveyForm));