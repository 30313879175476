import { persistReducer, createTransform, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import rootReducer from './rootReducer'
import { configureStore } from 'redux-starter-kit';
import epicMiddleware, { rootEpic } from './rootEpic';

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: any) => {
      // convert mySet to an Array.
      return { ...inboundState, mySet: [...inboundState.mySet] };
    },
    // transform state being rehydrated
    (outboundState: any) => {
      // convert mySet back to a Set.
      return { ...outboundState, mySet: new Set(outboundState.mySet) };
    },
);

// https://github.com/rt2zz/redux-persist
const persitsConfig = {
    key: 'root',
    storage: storageSession,
    transform: [SetTransform],
    stateReconciler: autoMergeLevel2,
    whitelist: ['user', 'main', 'projects', 'workspaces', 'datastores', 'items'],
    writeFailHandler: (): void => console.warn('store is full')
}
const persistedReducer = persistReducer(persitsConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [epicMiddleware]
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch

const rootStore = {store, persistor: persistStore(store)}
export default rootStore;