import React from 'react'
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { Row, Col, Select, Form, Input, Button, Checkbox, DatePicker, Icon } from 'antd';
import styles from './search.module.css'
import { ItemsSelectors, ItemsActions, ProjectSelectors, DatastoreSelector } from 'app/services/store';
import { FormComponentProps } from 'antd/lib/form/Form';
import FieldsHelper from 'app/utils/fieldsHelper';
import { withTranslation, WithTranslation } from 'react-i18next';
import Notice from '../notice/notice';
import moment from 'moment';

interface SearchFormStateProps {
    fields: any;
    datastoreId: any;
    projectId: any;
    conditions: any;
    isNotCompletedDelivery: boolean;
}

interface SearchFormProps extends SearchFormStateProps, WithTranslation {
    handleChange: any;
    getItemsListRequest: Function,
    ref?: React.RefObject<SearchForm>
    currentProject: any;
    setCurrentConditions: Function;
    setIsNotCompletedDelivery: Function;
}

const { Option } = Select;
const { Search } = Input;

class SearchForm extends React.Component<SearchFormProps> {
    state = {
        反社会性審査結果: '',
        与信審査結果: '',
        ステータス: '',
        住所: '',
        担当者: '',
        種別: '',
        調査対象: '',
        所属会社: '',
        所属部門: '',
        納品済対象外: false,
        notice: true,
        ID: '',
        納品予定日: ''
    }

    formData = {
        反社会性審査結果: '',
        与信審査結果: '',
        ステータス: '',
        住所: '',
        担当者: '',
        種別: '',
        調査対象: '',
        所属会社: '',
        所属部門: '',
        納品済対象外: false,
        ID: '',
        納品予定日: ''
    }

    componentDidMount() {
        this.setSearchConditions()
    }

    setSearchConditions = () => {
        for (let condition of this.props.conditions) {
            for(let searchField of Object.keys(this.formData)) {
                let field = this.getFieldByFieldDisplayID(searchField);
                if (field != undefined) {
                    if (field.field != condition.id) {
                        continue
                    }
                    let value = ""
                    if (condition.data_type == 'select') {
                        for (let opt of field.options) {
                            if (opt.o_id == condition.search_value) {
                                value = opt.value
                                this.setState({
                                    [field.displayID]: opt.value
                                })
                                this.formData[field.displayID]= opt.value
                            }
                        }
                    } else if (condition.data_type == 'datetime') {
                        let searchValueArr = String(condition.search_value).split(',')
                        let searchValue = searchValueArr.length > 1 ? searchValueArr[0] : condition.search_value
                        let searchDate = new Date(searchValue)
                        let year = searchDate.getFullYear()
                        let month = searchDate.getMonth()
                        let day = searchDate.getDate()
                        let newDate = new Date(year, month, day, 0, 0, 0)
                        let newDateMoment = moment(newDate)
                        let dateValue = newDateMoment.format('x')
                        this.setState({
                            [field.display_id]: Number(dateValue)
                        })
                        this.formData[field.displayID]= Number(dateValue)
                    } else if (condition.data_type == 'status') {
                        for (let status of field.statuses) {
                            if (status.id == condition.search_value) {
                                this.setState({
                                    [field.display_id]: status.name
                                })
                                this.formData[field.displayID]= status.name
                            }
                        }
                    } else {
                        this.setState({
                            [field.display_id]: condition.search_value
                        })
                        this.formData[field.displayID]= condition.search_value
                    }
                }
            }
        }
        this.setState({
            納品済対象外: this.props.isNotCompletedDelivery
        })
        this.formData.納品済対象外 = this.props.isNotCompletedDelivery
    }

    getLabel(test) {
        return(
            <div className={styles['label']}>
                {test}
            </div>
        )
    }

    selectChange = (val, option) => {
        this.setState({
            [option.props.title]: val
        })
        this.formData[option.props.title]= val
    }

    textChange = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
        this.setState({
            [name]: e.target.value
        })
        this.formData[name]= e.target.value
    }

    checkChange = (e, name: string) : void => {
        this.setState({
            [name]: e.target.checked
        })
        this.formData[name]= e.target.checked;
    }

    dateChange = (val: any, key: any) => {
        this.setState({
            [key]: val
        })
        this.formData[key] = val;
    }

    getFieldByFieldDisplayID(displayID) {
        for(let field of this.props.fields) {
            if(field.displayID === displayID) {
                return field
            }
        }
    }

    searchFieldTypes = {
        反社会性審査結果: 'select',
        与信審査結果: 'select',
        ステータス: 'stati',
        住所: 'text',
        担当者: 'text',
        種別: 'select',
        調査対象: 'text',
        所属会社: 'select',
        所属部門: 'text',
        納品済対象外: false
    }

    filterSelectFields(field, val) {
        let fields = [] as any;
        if(!field.options && !field.statuses || val === null){return}
        if(field.dataType==='select'){
            let opts = field.options
            for(let opt of opts) {
                if(opt.value === val) {
                    fields.push(opt.o_id)
                }
            }
        }
        if(field.dataType==='status'){
            let statuses = field.statuses
            for(let stat of statuses) {
                if(stat.name.includes(val)) {
                    fields.push(stat.s_id)
                }
            }
        }
        return fields
    }

    reloadNotice = () => {
        this.setState({
            notice: !this.state.notice
        })
    };

    search(isReload?) {
        if(isReload) { this.reloadNotice(); }
        let conditions = [] as any;
        let formDataKeys = Object.keys(this.formData)
        for(let searchField of formDataKeys) {
            var isExactMatch = false;
            let field = this.getFieldByFieldDisplayID(searchField);
            let value = this.formData[searchField]
            if(field == undefined) {
                let statuValue = this.state["ステータス"];
                if (statuValue || value === false) {continue;}
                field = this.getFieldByFieldDisplayID("ステータス");
                if (value) {
                    value = [];
                    for (let id in field.statuses) {
                        var state = field.statuses[id];
                        if (state.name == "納品済") {continue}
                        value.push(state.id)
                    }
                }

            } else if(field.dataType === 'select' && value !== '') {
                value = this.filterSelectFields(field, value)
            } else if (field.dataType === 'datetime' && value !== '') {
                let values = [] as any;
                if (typeof value == 'number') {
                    values.push(moment(value))
                } else {
                    values.push(value)
                }
                values.push(moment(value).endOf('day'))
                value = values
            } else if (field.dataType === 'status' && value !== '') {
                let filter = this.filterSelectFields(field, value)
                value = filter.length > 0 ? filter : [value]
            }else if (value !== ''){
                if (typeof value == 'object') {
                    value = value
                } else {
                    value = [value]
                }
            }
            if(!field || !value){continue}
            let dataType = field.dataType
            conditions.push({
                id: field.field,
                data_type: dataType ? dataType : 'err',
                search_value: value ? value : [],
                exact_match: isExactMatch,
                include_null: false
            })
        }
        this.props.setCurrentConditions({conditions: conditions})
        this.props.setIsNotCompletedDelivery({ isNotCompletedDelivery: this.state.納品済対象外 })
        this.setState({ conditions: conditions })
        this.props.getItemsListRequest({projectId: this.props.projectId, datastoreId: this.props.datastoreId, conditions: conditions})
    }

    reSearch = () => {
        // console.log('called from parent!');
    }


    getFieldOptions(displayID) {
        for(let field of this.props.fields){
            if(field.displayID === displayID){
                if(field.dataType === FieldsHelper.fields.select.value){
                    var options = field.options;
                    options.sort((a, b) => {
                        if (a.sort_id < b.sort_id) return -1;
                        if (a.sort_id > b.sort_id) return 1;
                        return 0;
                    });
                    return options
                } else if (field.dataType === FieldsHelper.fields.status.value){
                    return field.statuses
                }
            }
        }
    }

    clearSearchForm = () => {
        this.setState({
            反社会性審査結果: '',
            与信審査結果: '',
            ステータス: '',
            住所: '',
            担当者: '',
            種別: '',
            調査対象: '',
            所属会社: '',
            所属部門: '',
            納品済対象外: false,
            ID: '',
            納品予定日: ''
        })
        this.formData = {
            反社会性審査結果: '',
            与信審査結果: '',
            ステータス: '',
            住所: '',
            担当者: '',
            種別: '',
            調査対象: '',
            所属会社: '',
            所属部門: '',
            納品済対象外: false,
            ID: '',
            納品予定日: ''
        }
        this.props.setCurrentConditions({conditions: []})
        this.props.setIsNotCompletedDelivery({isNotCompletedDeliver: false})
        this.search()
      }


    render(): JSX.Element {
        return (
            <div>
                {this.props.currentProject != null && <Notice currentProject={this.props.currentProject} reload={this.state.notice} />}
                <Row>
                    <Col lg={12} md={10}>
                        <h1 className={styles['main-container-title']}>
                            {this.props.t('COMPANY.OPTIONS.REQUESTED_LIST')}
                        </h1>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        <Button onClick={() => this.search(true)}>最新情報更新</Button>
                    </Col>
                </Row>
                <div style={{ textAlign: 'left' }}>
                    <Icon type="search" style={{ fontSize: 12, marginRight: 5 }} />
                    <span style={{ fontSize: 12 }}>{this.props.t('COMPANY.OPTIONS.FILTER_DISPLAY')}</span>
                </div>

                <div className={styles['search-form-container']}>
                    {this.props.fields && <Form id="search-form-id" layout='vertical'>
                        <Row type="flex" justify="space-between" gutter={8}>
                            <Col span={4}>
                                <Form.Item label="反社会性審査結果" className={styles['form-label']}>
                                    <Select size="small" key="反社会性審査結果" value={this.state.反社会性審査結果} onChange={this.selectChange}>
                                        {this.getFieldOptions('反社会性審査結果') && this.getFieldOptions('反社会性審査結果').map(opt => <Option title="反社会性審査結果" value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="与信審査結果" className={styles['form-label']}>
                                    <Select size="small" value={this.state.与信審査結果} onChange={this.selectChange}>
                                        {this.getFieldOptions('与信審査結果') && this.getFieldOptions('与信審査結果').map(opt => <Option title="与信審査結果" value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="ステータス" className={styles['form-label']}>
                                    <Select size="small" value={this.state.ステータス} onChange={this.selectChange}>
                                        {this.getFieldOptions('ステータス') && this.getFieldOptions('ステータス').map(opt => <Option title="ステータス" value={opt.s_id} key={opt.s_id}>{opt.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="種別" className={styles['form-label']}>
                                    <Select size="small" value={this.state.種別} onChange={this.selectChange}>
                                        {this.getFieldOptions('種別') && this.getFieldOptions('種別').map(opt => <Option title="種別" value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="所属会社" className={styles['form-label']}>
                                    <Select size="small" value={this.state.所属会社} onChange={this.selectChange}>
                                        {this.getFieldOptions('所属会社') && this.getFieldOptions('所属会社').map(opt => <Option title="所属会社" value={opt.value} key={opt.o_id}>{opt.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="所属部門" className={styles['form-label']}>
                                    <Input size="small" value={this.state.所属部門} onChange={(e): void => this.textChange(e, '所属部門')} placeholder="入力してください *" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-between" align='bottom' gutter={8}>
                            <Col span={4}>
                                <Form.Item label="ID" className={styles['form-label']}>
                                    <Input size="small" value={this.state.ID}  onChange={(e): void => this.textChange(e, 'ID')} placeholder="入力してください *" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="住所" className={styles['form-label']}>
                                    <Input size="small" value={this.state.住所} onChange={(e): void => this.textChange(e, '住所')} placeholder="入力してください *" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="担当者" className={styles['form-label']}>
                                    <Input size="small" value={this.state.担当者} onChange={(e): void => this.textChange(e, '担当者')} placeholder="入力してください *" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="調査対象" className={styles['form-label']}>
                                    <Input size="small" value={this.state.調査対象}  onChange={(e): void => this.textChange(e, '調査対象')} placeholder="入力してください *" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="納品予定日" className={styles['form-label']}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder='Select date'
                                        value={this.state.納品予定日 == '' ? undefined : moment(this.state.納品予定日)}
                                        onChange={(moment, momentString)=>this.dateChange(moment ? moment.utcOffset(9).set({hour:0,minute:0,second:0,millisecond:0}) : '', '納品予定日')} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="ステータス" className={styles['form-label']}>
                                    <Checkbox checked={this.state.納品済対象外} onChange={(e): void => this.checkChange(e, '納品済対象外')}>納品済対象外</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" gutter={8} style={{paddingTop: '10px'}}>
                            <Col span={2}>
                                <Button className={styles['form-button']} type="primary" size='small' onClick={()=>this.search()}>{this.props.t('BUTTON.SEARCH')}</Button>
                            </Col>
                            <Col span={2}>
                                <Form.Item label="" className={styles['clear-search']}>
                                    <Button type='link' className={styles['text']} onClick={this.clearSearchForm}>{this.props.t('COMPANY.OPTIONS.CLEAR_CONDITIONS')}</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form> }
                </div>                
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    fields: ItemsSelectors.getFields(state),
    projectId: ProjectSelectors.getCurrentProjectId(state),
    datastoreId: DatastoreSelector.getCurrentDatastoreId(state),
    conditions: ItemsSelectors.getCurrentConditions(state),
    isNotCompletedDelivery: ItemsSelectors.getIsNotCompletedDelivery(state)
})

const mapDispatchToProps = {
    getItemsListRequest: ItemsActions.getItemsListRequest,
    setCurrentConditions: ItemsActions.setCurrentConditions,
    setIsNotCompletedDelivery: ItemsActions.setIsNotCompletedDelivery
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm));
