import { UserState, initialState } from "./state";
import { PayloadAction } from "redux-starter-kit";
import { User } from "./types";

export const setUserAuth = (state: UserState, action: PayloadAction<{authKey: string}>): UserState => {
  return {
    ...state,
    auth_key: action.payload.authKey
  }
}

export const setUserLoading = (state: UserState, action: PayloadAction<{loading: boolean}>): UserState => {
  return {
    ...state,
    loading: action.payload.loading
  }
}

export const loginRequest = (state: UserState, action: PayloadAction<{email: string; password: string}>): UserState => {
  return {
    ...state,
    loading: true
  }
}

export const loginSuccess = (state: UserState): UserState => {
  return {
    ...state,
    loading: false,
    error: ''
  }
}

export const loginFailed = (state: UserState, action: PayloadAction<{error: string}>): UserState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error
  }
}

export const getCurrentUserRequest = (state: UserState): UserState => {
  return {
    ...state,
    loading: true
  }
}

export const getCurrentUserSuccess = (state: UserState, action: PayloadAction<{user: Partial<User>}>): UserState => {
  return {
    ...state,
    ...action.payload,
    loading: false
  }
}

export const getCurrentUserFailed = (state: UserState, action: PayloadAction<{errorMsg: string}>): UserState => {
  return {
    ...state,
    loading: false,
    error: action.payload.errorMsg
  }
}

export const reset = (state: UserState): UserState => {
  return {
    ...initialState
  }
}

export const logout = (state: UserState): UserState => {
  return {
    ...state,
    auth_key: ''
  }
}
