import React from 'react';
import { connect } from 'react-redux';
import { Menu, Button, Row, Col, Input, Popover, Modal, Alert } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { RootState } from 'app/services/store/rootReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GroupActions, GroupSelectors, WorkspaceSelectors } from 'app/services/store';
import { Group } from 'app/services/store/group/types';
import styles from '../settings.module.css';
import HttpService  from 'app/services/httpService/httpService'
import Logger from 'app/utils/logger';
import { AlertType } from 'app/utils/antdHelper';

interface LeftMenuStateProps extends WithTranslation {
    grouptree: Array<Group>;
    currentWorkspaceId: string;
    currentGroupId: string;
    currentGroup: any;
    setGroupId: Function;
    getGroupsRequest: Function;
}

const modal = [
    {
        type: 'new',
        title: '組織の新規作成',
        popoverTitle: '新規作成',
        successMSG: '組織を新規作成しました',
        errorMSG: '組織を新規作成できませんでした',
    }, {
        type: 'edit',
        title: '組織名の変更',
        popoverTitle: '変更',
        successMSG: '組織名を変更しました',
        errorMSG: '組織名を変更できませんでした',
    }, {
        type: 'delete',
        title: '組織の削除',
        popoverTitle: '削除',
        successMSG: '組織を削除しました',
        errorMSG: '組織を削除できませんでした',
    },
]

const modalVisible = {
    pattern_1: [ 'new', 'edit' ],
    pattern_2: [ 'new', 'edit', 'delete' ],
    pattern_3: [ 'edit', 'delete' ],
}

class LeftMenu extends React.Component<LeftMenuStateProps> {

    state = {
        groupName: '',
        modal: {
            type: 'new',
            title: '組織の作成',
            popoverTitle: '作成',
            successMSG: '組織を作成しました',
            errorMSG: '組織を作成できませんでした',
            visible: false
        },
        alert: {
            type: AlertType.INFO,
            message: '',
            visible: false
        },
        loading: false
    };

    setCurrentGroupId(value): void {
        this.props.setGroupId({
            id: value.key
        })
        this.setState({
            groupName: this.props.currentGroup.name
        })
    }

    getSubMenu(parent): JSX.Element {
        return (
            <SubMenu
                key={parent['id']}
                className={styles['leftmenu-item']}
                title={
                    <Row type='flex' justify='space-between'>
                        <Col span={22} className={styles['leftmenu-popover-ellipsis']}>{parent['name']}</Col>
                        {parent['id'] === this.props.currentGroupId && <Col span={2}>{this.getPopover(modalVisible.pattern_2)}</Col>}
                    </Row>
                }
                onTitleClick={(value): void => this.setCurrentGroupId(value)}
            >
                {parent['childGroups'].map(group => {
                    return (
                        <Menu.Item
                            key={group['id']}
                            onClick={(value): void => this.setCurrentGroupId(value)}
                            className={styles['leftmenu-item']}
                            >
                            <Row type='flex' justify='space-between'>
                                <Col span={22} className={styles['leftmenu-popover-ellipsis']}>{group['name']}</Col>
                                {group['id'] === this.props.currentGroupId && <Col span={2}>{this.getPopover(modalVisible.pattern_3)}</Col>}
                            </Row>
                        </Menu.Item>
                    )
                })}
            </SubMenu>
        )
    }

    getPopover(visiblePattern): JSX.Element {
        const filterZModal = [...new Set(modal)].filter(value => visiblePattern.includes(value.type));
        return(
            <Popover
                placement="rightTop"
                className={styles['leftmenu-popover']}
                content={
                    <span>
                        {filterZModal.map(element => {
                            return (<div key={element.type}>
                                <Button
                                    className={styles['leftmenu-popover-button']}
                                    type='link'
                                    onClick={() => { this.showModal(element) }}>
                                    {element.popoverTitle}
                                </Button>
                            </div>)
                        })}
                    </span>
                }
            >
                <Button type='link' icon='more' />
            </Popover>
        )
    }

    showModal(modal): void {
        this.setState({
            modal: {
                ...modal,
                visible: true,
            }
        })
    }

    confirm(): void {
        let async = '';
        let params = {};
        let url = '';
        const randomDisplayId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        if (this.state.modal.type === 'new') {
            async = 'PostAsync';
            url = `groups/${this.props.currentGroupId}`
            params = {
                name: this.state.groupName,
                display_id : randomDisplayId
            };
        } else if (this.state.modal.type === 'edit') {
            async = 'PutAsync';
            url = `workspaces/${this.props.currentWorkspaceId}/groups/${this.props.currentGroupId}`
            params = {
                name: this.state.groupName,
                display_id: this.props.currentGroup.display_id
            };
        } else if (this.state.modal.type === 'delete') {
            async = 'DeleteAsync';
            url = `workspaces/${this.props.currentWorkspaceId}/groups/${this.props.currentGroupId}`
        }

        HttpService[async]<any, any>(url, params, HttpService.LinkerAPIBasePath).subscribe((res) => {
            this.props.getGroupsRequest();

            this.setState({
                alert: {
                    ...this.state.alert,
                    type: AlertType.SUCCESS,
                    message: this.state.modal.successMSG,
                    visible: true
                },
                loading: false
            })
            this.reset(2000);

        }, err => {
            Logger.console.log('ERROR : delete_user', err);
            this.setState({
                alert: {
                    ...this.state.alert,
                    type: AlertType.ERROR,
                    message: this.state.modal.errorMSG,
                    visible: true
                },
                loading: false
            })
            this.reset(2000);
        })
    }

    settingModal(): JSX.Element {
        const defaultValue = this.state.modal.type === 'edit' ? this.props.currentGroup.name : '';
        return(
            <Modal
                visible={this.state.modal.visible}
                zIndex={10000}
                className={styles['setting-modal']}
                footer={null}
                onCancel={(): void => this.reset()}
                >
                <Row className={styles['setting-modal-header']}>{this.state.modal.title}</Row>
                <Row className={styles['setting-modal-content']}>
                    {(this.state.modal.type === 'new' || this.state.modal.type === 'edit') &&
                        <span>
                            組織名
                            <Input
                                onChange={(e): void => this.setState({ groupName: e.target.value })}
                                className={styles['input-box']}
                                value={this.state.groupName} />
                        </span>}
                    {(this.state.modal.type === 'delete' && !this.state.alert.visible) &&
                        <div style={{ textAlign: 'center' }}>
                            「<span style={{ fontWeight: 'bold', fontSize: 16 }}>{this.props.currentGroup.name}</span>」
                            <div style={{ marginTop: 10 }}>を削除してよろしいですか？</div>
                    </div>}
                    <div style={{ height: 50, paddingTop: 20 }}>
                        {this.state.alert.visible &&
                            <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />}
                    </div>
                </Row>
                <Row className={styles['setting-modal-footer']}>
                    <Button
                        type='link'
                        className={styles['setting-modal-cancel']}
                        onClick={() => this.reset()}
                        >
                        キャンセル
                        </Button>
                    <Button
                        className={styles['members-content-button']}
                        onClick={(): void => this.confirm()}
                        >
                        {this.state.modal.popoverTitle}
                    </Button>
                </Row>
            </Modal>
        )
    }

    reset(delay = 0): void {
        setTimeout(() => {
            this.setState({
                modal: {
                    ...this.state.modal,
                    visible: false
                },
                alert: {
                    ...this.state.alert,
                    visible: false
                },
                groupName: ''
            })
        }, delay);
    }

    render(): JSX.Element {
        return (
            <div>
                {this.props.grouptree.map(groups => {
                    return (
                        <Menu
                            mode='inline'
                            className={styles['leftmenu']}
                            key={groups.id}
                            inlineIndent={12}
                            >
                            <Menu.Item
                                className={styles['leftmenu-item']}
                                style={{ marginTop: 0 }}
                                key={groups.id}
                                onClick={(value): void => this.setCurrentGroupId(value)}
                                >
                                <Row type='flex' justify='space-between'>
                                    <Col className={styles['leftmenu-popover-ellipsis']}>{groups.name}</Col>
                                    {groups.id === this.props.currentGroupId && <Col span={2}>{this.getPopover(modalVisible.pattern_1)}</Col>}
                                </Row>
                            </Menu.Item>
                            {groups.childGroups && groups.childGroups.map(value => {
                                    return this.getSubMenu(value);
                                })
                            }
                        </Menu>
                    )
                })}
                {this.settingModal()}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    grouptree: GroupSelectors.getGrouptree(state),
    currentWorkspaceId: WorkspaceSelectors.getCurrentWorkspaceId(state),
    currentGroupId: GroupSelectors.getCurrentGroupId(state),
    currentGroup: GroupSelectors.getCurrentGroup(state),
});

const mapDispatchToProps = {
    setGroupId: GroupActions.setCurrentGroupId,
    getGroupsRequest: GroupActions.getGroupsRequest
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeftMenu));