import rootStore from '../store/rootStore';
import { UsersActions, ProjectActions, DatastoreActions } from '../store';
import { message } from 'antd';

let sessionTimeout = true;

class AuthHelper {
    public static getAuthKey = (): string => {
        const state = rootStore.store.getState();
        return state.user.auth_key;
    }

    public static unauthorized = (): void => {
        if(sessionTimeout) {
            sessionTimeout = false;//同時複数実行を避けるためログアウトするまで許可しない
            message.info('ログインセッションが時間切れになりました。再ログインが必要です。');
            setTimeout(() => {
                rootStore.store.dispatch(DatastoreActions.setCurrentDatastoreId({datastoreId: '', conditions: []}));
                rootStore.store.dispatch(ProjectActions.setCurrentProjectId({projectId: ''}));
                localStorage.clear();
                sessionStorage.clear();
                rootStore.store.dispatch(UsersActions.logout());
            }, 3000);
        }
    }
}

export default AuthHelper;