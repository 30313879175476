import React from 'react';
import Logger from 'app/utils/logger';

class ErrorBoundary extends React.Component {
    state = { hasError: false}

    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any): { hasError: boolean } {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any): void {
      // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
        Logger.console.log(error)
        Logger.console.log(errorInfo)
    }

    render(): JSX.Element | React.ReactNode {
      if (this.state.hasError) {
        return <h1>Something went wrong.</h1>;
      }
      return this.props.children;
    }
}

export default ErrorBoundary;