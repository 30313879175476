import { ItemsState, initialState } from "./state";
import { PayloadAction } from "redux-starter-kit";
import { Items, ItemEntry, ItemSearch } from "./types";

export const setCurrentItemId = (state: ItemsState, action: PayloadAction<{itemId: string}>): ItemsState => {
  return {
    ...state,
    current_item_id: action.payload.itemId
  }
}

export const setCurrentPage = (state: ItemsState, action: PayloadAction<{currentPage: number}>): ItemsState => {
    return {
        ...state,
        current_page: action.payload.currentPage
    }
}

export const setCurrentConditions = (state: ItemsState, action: PayloadAction<{conditions: Array<ItemSearch>}>): ItemsState => {
    return {
      ...state,
      current_conditions: action.payload.conditions
    }
}

export const setIsNotCompletedDelivery = (state: ItemsState, action: PayloadAction<{isNotCompletedDelivery: boolean}>): ItemsState => {
  return {
    ...state,
    is_not_completed_delivery: action.payload.isNotCompletedDelivery
  }
}

export const getItemsListRequest = (state: ItemsState, action: PayloadAction<{projectId: string; datastoreId: string, page?: number; conditions?: Array<ItemSearch>}>): ItemsState => {
  return {
    ...state,
    loading: true
  }
}

export const getItemsListSuccess = (state: ItemsState, action: PayloadAction<Items>): ItemsState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: ''
  }
}

export const getItemsListFailed = (state: ItemsState, action: PayloadAction<{error: string}>): ItemsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error
  }
}


export const deleteItemRequest = (state: ItemsState, action: PayloadAction<{itemId: string; actionId: string}>): ItemsState => {
  return {
    ...state,
  }
}

//////my own
export const deleteItem = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  const index = state.entries.findIndex(entry => entry.i_id === action.payload.itemId);
  if (index !== -1) {
    state.entries.splice(index, 1);
  }
  return state;
} 


export const deleteItemSuccess = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  const index = state.entries.findIndex(entry => entry.i_id === action.payload.itemId);
  if (index !== -1) {
    state.entries.splice(index, 1);
  }
  return state;
}

export const deleteItemFailed = (state: ItemsState, action: PayloadAction<{error: string}>): ItemsState => {
  return {
    ...state,
  }
}

export const addEntry = (state: ItemsState, action: PayloadAction<{entry: ItemEntry}>): ItemsState => {
  state.entries.unshift(action.payload.entry);
  return state;
}

export const updateEntry = (state: ItemsState, action: PayloadAction<{fieldId: string; changes: Partial<ItemEntry>}>): ItemsState => {
  const entry = state.entries.find(entry => entry.i_id === action.payload.fieldId);
  const entryIndex = state.entries.findIndex(entry => entry.i_id === action.payload.fieldId);

  if (entry) {
    const updatedEntry = {
      ...entry,
      ...action.payload.changes
    }
    state.entries[entryIndex] = updatedEntry;
  }
  return state;
}

export const reset = (state: ItemsState): ItemsState => {
  return {
    ...initialState
  }
}