import React, { Component } from 'react';
import { PickerType } from '../home/home';
import WorkspacePicker from 'app/components/workspacePicker/workspacePicker';
import ProjectPicker from 'app/components/projectPicker/projectPicker';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/services/store/rootReducer';
import { WorkspaceSelectors, ProjectSelectors, ProjectActions, WorkspaceActions, DatastoreActions, ItemsDetailsActions, ItemsActions, DatastoreSelector } from 'app/services/store';
import { useSelector, useDispatch } from 'react-redux';

interface WorkspaceAndProjectPickerProps {
    children: never[];
    pickerType: PickerType;
    currentWorkspaceId: string;
    displaySelectorModalFunction: Function;
}
const WorkspaceAndProjectPicker = (props: WorkspaceAndProjectPickerProps): JSX.Element => {
    const dispatch = useDispatch();
    const projects = useSelector((state: RootState) => ProjectSelectors.getProjectList(state));
    const workspaces = useSelector((state: RootState) => WorkspaceSelectors.getWorkspaceList(state));
    const selectedPJ = useSelector((state: RootState) => ProjectSelectors.getCurrentProject(state));
    const projID = useSelector((state: RootState) => ProjectSelectors.getCurrentProjectId(state))
    
    const datastore = useSelector((state: RootState) => DatastoreSelector.getCurrentDatastoreId(state))

    function autoPickPJ() {
        // console.log('AUTOPJ',props)
        if(sessionStorage.getItem('url') && sessionStorage.getItem('url')!== '/') {
            // props.displaySelectorModalFunction()
           
            
            let fromURL = sessionStorage.getItem('url')
            let spliArr = fromURL!.split('/')
            spliArr.shift()
            if(spliArr[1] && !projID && workspaces){dispatch(ProjectActions.setCurrentProjectId({projectId: spliArr[1]}));}
            
            // console.log('----------called pj auto', projID)
        } 
        else if
        ( projects.length===1 ) {
            let projectId = projects[0].application_id
            dispatch(ProjectActions.setCurrentProjectId({projectId}));
        }
    }
    
    function autoLogin() {
        props.displaySelectorModalFunction()
    }

    const { t } = useTranslation();
    return (
        <div >
            {`${t('WORKSPACE.WORKSPACES')} :`}
            <WorkspacePicker pickerType={props.pickerType} />
            { !selectedPJ && projects && autoPickPJ()} 
            { workspaces.length === 1 && projects.length=== 1 && !sessionStorage.getItem('url') && projID && autoLogin()}
            {`${t('PROJECT.PROJECTS')} :`}
            { props.currentWorkspaceId && <ProjectPicker pickerType={props.pickerType}/> }
        </div>
    )
}

export default WorkspaceAndProjectPicker;