import * as iconv from 'iconv-lite';

export default class StringUtils {
    public static GenUUID(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    // Supported encodings: https://github.com/ashtuchkin/iconv-lite#supported-encodings
    public static ConvertStringToUtf8(str: string, encoding: string) {
        const encoded = iconv.encode(str, encoding).toString('binary');
        return iconv.decode(new Buffer(encoded, 'binary'), 'utf8');
    }
}