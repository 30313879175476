import { createSelector } from "reselect";
import { RootState } from "../rootReducer";
import { Group } from "./types";

const grouptree = (state: RootState): Array<Group> => state.groups.grouptree;
const groupCurrentId = (state: RootState): string => state.groups.current_group_id;

export const getGrouptree = createSelector<RootState, Array<Group>, Array<Group>>(
    grouptree,
    (grouptree: Array<Group>) => grouptree
);

export const getCurrentGroupId = createSelector<RootState, string, string>(
    groupCurrentId,
    (id: string) => id
);

export const getCurrentGroup = createSelector(
    grouptree,
    groupCurrentId,
    (grouptree: Array<Group>, id: string) => {
        let group = {}
        grouptree.find(res_1 => {
            if (res_1.id === id) {
                group = res_1
            } else if (res_1.childGroups) {
                res_1.childGroups.find(res_2 => {
                    if (res_2.id === id) {
                        group = res_2
                    } else if (res_2.childGroups) {
                        res_2.childGroups.find(res_3 => {
                            if (res_3.id === id) {
                                group = res_3
                            }
                        })
                    }
                })
            }
        });
        return group
    }
)
