import React from 'react'
import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './submitPopup.module.css'
import {Table, Modal} from 'antd';
import HttpService  from 'app/services/httpService/httpService'
import moment from 'moment-timezone'
import { UsersSelectors, ItemsSelectors , ItemsActions, DatastoreSelector, ProjectSelectors} from 'app/services/store';

interface SubmitPopupProps extends WithTranslation {
    state: any;
    clearState: Function;
    setActiveTab: Function;
    closePopup: Function;
    formType: any;
    payload: any;
    username: any;
    fields: any,
    addEntry: Function;
    currentDatastoreID: any;
    projectID: any;
}

class SubmitPopup extends React.Component<SubmitPopupProps> {
    state = { 
        isSubmiting: false
    }

    idsToSkip = ['isSubmiting','files', 'loading', 'fileSelected', 'value','itemID','renders','showPopup','sending', '電話番号1', '電話番号2', '電話番号3', '発注者電話番号1', '発注者電話番号2', '発注者電話番号3']

    nameKeyArr = [
        {
            key: "調査対象",
            name: (this.props.formType === 'COMPANY') ?  '法人' : this.props.formType === 'INDIVIDUAL' ? '個人' : this.props.formType === '重要契約' ? '対象名' : 'null'
        },
        {
            key: "フリガナ",
            name: "フリガナ"
        },
        {
            key: "生年月日",
            name: "生年月日"
        },
        {
            key: "住所",
            name: "現住所"
        },
        {
            key: "屋号",
            name: "屋号"
        },
        {
            key: "その他　関連者情報　※複数記入可",
            name: "その他関連者者情報※複数記入可"
        },
        {
            key: "会社HP",
            name: "会社HP"
        },
        {
            key: "電話番号",
            name: "電話番号"
        },
        {
            key: "氏名",
            name: "氏名"
        },

        {
            key: "所属会社",
            name: "所属会社"
        },
        {
            key: "所属部門",
            name: "所属部門"
        },
        {
            key: "発注者上長氏名",
            name: "発注者上長氏名"
        },
        {
            key: "上長メールアドレス",
            name: "上長メールアドレス"
        },
        {
            key: "発注者電話番号",
            name: "発注者電話番号"
        },

        {
            key: "審査対象関連資料",
            name: "審査対象関連資料"
        },
        {
            key: "事前承認事項",
            name: "事前承認事項"
        },
        {
            key: "取引内容",
            name: "取引内容"
        },
        {
            key: "請求先",
            name: "請求先"
        },
        {
            key: "承諾事項",
            name: "承諾事項"
        },
    ]

    getFieldName(field){
        for(let obj of this.nameKeyArr){
            if(field === obj.key){
                return obj.name
            }
        }
    }

    getFieldbyFieldDisplayID(display_id) {
        for(let field of this.props.fields){
            if(display_id === field.displayID){
                return field
            }
        }
    }
    mounted = true;
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    addItem(result, payload){
        let fields = {};
        let itemsKeys = Object.keys(this.state)
        for (let item of itemsKeys){
            if(this.idsToSkip.includes(item)){continue}

            let field = this.getFieldbyFieldDisplayID(item)
            fields[field.field] = this.state[item]
        }

        let entry = {
                    access_keys: '',
                    created_at: Date.now().toString(),
                    created_by: this.props.username,
                    fields: fields,
                    d_id: payload.d_id,
                    i_id: result.data.id,
                    p_id: payload.p_id,
                    rev_no: 0,
                    title: '',
                    unread: "0",
                    updated_at: Date.now().toString(),
                    updated_by: this.props.username,
                    _id: result.data.id
                }
    
            this.props.addEntry({
                entry: entry
            })
    }

    getAIDs(){
        const params = { datastore_id: this.props.currentDatastoreID }
        return new Promise(resolve=> {
            HttpService.GetAsync('get_sf_new_action_settings', params)
             .subscribe(
                (data:any) => {
                    resolve(data)
             })
        })
    }

    getDefaultAddAID(aids) {
        for(let aid of aids.data) {
            if(aid.isOwnedBySystem) {
                return aid.a_id
            }
        }
    }

    async confirm(){
        let aids = await this.getAIDs()
        let aid = this.getDefaultAddAID(aids)
        this.setState({
            isSubmiting: true
        })
        let updatedPayload = this.props.payload
        updatedPayload['a_id'] = aid;
        updatedPayload['item']['a_id'] = aid;
        let currentTimeID = this.getFieldbyFieldDisplayID("発注日時").field
        let currentTimeUTC = moment()
        let currentTime = currentTimeUTC.tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
        updatedPayload.item[currentTimeID] = currentTime
        let url = `applications/${this.props.projectID}/datastores/${this.props.currentDatastoreID}/items/new`
        HttpService.PostAsync(url, this.props.payload, "linker-api").subscribe( response => {
                    this.addItem(response, updatedPayload)
               },
               err => {
                    this.props.clearState()
                    this.props.setActiveTab('1')
               },
               () => {
                   this.setState({
                       showPopup: false
                   })
                    this.props.clearState()
                    this.props.setActiveTab('1')
               })
    }

    getDataSource(){
        let dataArr = [] as any;
        let stateKeys = Object.keys(this.props.state)

        for( let item of stateKeys) {
            let data = {};
            if(this.idsToSkip.includes(item)){continue}
            if(item === '生年月日'){
                data['key'] = item;
                data['field'] = this.getFieldName(item)//item// make array to match real name of field
                data['value'] = this.props.state[item].format("MM-DD-YYYY")
                dataArr.push(data)
                continue
            }

            if(item === '事前承認事項')
            {
                // console.log(this.props.state[item])
                data['key'] = item;
                data['field'] = this.getFieldName(item)//item// make array to match real name of field
                data['value'] = this.props.state[item].join('\n');
                dataArr.push(data)
                continue
            }

            data['key'] = item;
            data['field'] = this.getFieldName(item) ? this.getFieldName(item) : item//item// make array to match real name of field
            data['value'] = this.props.state[item] !== undefined ? this.props.state[item] : ''
            dataArr.push(data)
        }
        let data = {};
        if(this.props.state.files.length != 0){
            data['key'] = 'ファイル';
            data['field'] = 'ファイル'
            data['value'] = this.props.state.files[0].name
            dataArr.push(data)
        }
        return dataArr
    }

    columns = [
        {
            title: 'Field',
            dataIndex: 'field',
            key: 'field',
        },
        {
            title: 'Inputed value',
            dataIndex: 'value',
            key: 'value',
            render: (val, row, index) => {
                if(row.key === '事前承認事項')
                {
                    return {
                        children: <div style={{whiteSpace: 'pre'}}>{val}</div>,
                        props: {}
                    }
                };

                return {
                    children: val,
                    props: {}
                }
            }
        },
        // {
        //     title: '事前承認事項',
        //     dataIndex: '事前承認事項',
        //     key: '事前承認事項',
        //     render: function(value) { return `<div>${value}</div>` }
        // }
    ];

    getOkProps() {
        return {
            disabled: false
        }
    }

    render (): JSX.Element {
        return (
            <div>
                <Modal
                    title="依頼内容"
                    visible={this.props.state.showPopup}
                    onOk={() => this.confirm()}
                    okButtonProps={{disabled: this.state.isSubmiting, loading: this.state.isSubmiting}}
                    onCancel={() => this.props.closePopup()}
                    width={700}
                >
                    <Table
                        className={styles['table-css']}
                        dataSource={this.getDataSource()}
                        columns={this.columns}
                        pagination={false}
                        showHeader={false}
                        size='small'
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    fields: ItemsSelectors.getFields(state),
    username: UsersSelectors.getUsername(state),
    currentDatastoreID: DatastoreSelector.getCurrentDatastoreId(state),
    projectID: ProjectSelectors.getCurrentProjectId(state)
})

const mapDispatchToProps = {
    addEntry: ItemsActions.addEntry
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitPopup));