import React from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Button, Drawer } from 'antd';
import 'antd/dist/antd.css';
import { RootState } from 'app/services/store/rootReducer';
import { UsersActions, ProjectActions, DatastoreActions, UsersSelectors } from 'app/services/store';
import ProjectPicker from 'app/components/projectPicker/projectPicker';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PickerType } from '../home/home';
import styles from './commonHeader.module.css'
import UserSettings from '../settings/userSettings/userSettings';


const { Header } = Layout;

interface CommonHeaderProps extends WithTranslation {
	username: string;
	pickerType: PickerType;
	logout: Function;
	setDSid: Function;
	setPJid: Function;
}

class CommonHeader extends React.Component<CommonHeaderProps> {

	state = {
		visibleDrawer: false,
	}

	logout = (): void => {
		this.props.setDSid({datastoreId: ''})
		this.props.setPJid({projectId: ''})
		localStorage.clear();
		sessionStorage.clear();
		this.props.logout();
	}

	render(): JSX.Element {
		return (
		<div>
			<Header className={styles['small-header']}>
				<Row type='flex' justify='space-between'>
					<Col>
						<span>{ this.props.t('COMPANY.SYSTEM') }</span>
					</Col>
					<Col>
						<Button
							style={{ color: '#fff' }}
							type='link'
							onClick={(): void => { this.setState({ visibleDrawer: true }) }}
						>
							{this.props.username}<span style={{ fontSize: 10 }}>  さん</span>
						</Button>
						<Button type='link' shape='circle' onClick={(): void => this.logout()} icon='logout' style={{ color: '#fff' }} />
					</Col>
				</Row>
			</Header>
			<Row type='flex' justify='center' align='middle'>
				<Col span={4}></Col>
				<Col span={20}>
					{this.props.pickerType === PickerType.HEADER && <ProjectPicker  pickerType={this.props.pickerType}/>}
				</Col>
			</Row>

			<Drawer
				placement='right'
				width={400}
				onClose={(): void => { this.setState({ visibleDrawer: false }) }}
				visible={this.state.visibleDrawer}
			>
				{this.state.visibleDrawer && <UserSettings />}
			</Drawer>
		</div>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	username: UsersSelectors.getUsername(state),
})

const mapDispatchToProps = {
	logout: UsersActions.logout,
	setPJid: ProjectActions.setCurrentProjectId,
	setDSid: DatastoreActions.setCurrentDatastoreId,
};

export default withTranslation()(connect(
	mapStateToProps,
	mapDispatchToProps
)(CommonHeader));
